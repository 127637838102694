import { createApp, nextTick } from "vue"
import App from "./App.vue"
import store from "./store/store"
import router from "./router"
import initErrorReporting from "@/helpers/initErrorReporting"

import AnalyticsPlugin, {
  AnalyticsService,
  MixpanelTracker,
} from "@soenergy/frontend-library/src/plugins/analytics"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"
import StoryblokHelperPlugin from "@soenergy/frontend-library/src/plugins/storyblokHelper"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { ObserveVisibility } from "vue-observe-visibility"
import PersistentStoreService from "@soenergy/frontend-library/src/services/PersistentStoreService"
import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"

import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import { initDatadogPlugin } from "@soenergy/frontend-library/src/services/datadog"
import initDatadogIdentity from "@/helpers/initDatadogIdentity"

const app = createApp(App)
app.use(initDatadogPlugin())
app.use(store)
app.use(router)

initErrorReporting(app)
app.use(StoryblokHelperPlugin)
app.use(AnalyticsPlugin)
app.directive("observe-visibility", ObserveVisibility)

if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true") {
  CookieConsentPlugin.init()
}
AuthenticationService.init({
  store,
  onSessionExpired: () => {
    store.dispatch("logout")
  },
})

PersistentStoreService.init({
  store,
  reducer: (state) => ({
    accounts: state.accounts,
    junifer: state.junifer,
    appointment: state.appointment,
  }),
})

runOnceCookieIsAllowed("performance", () => {
  MixpanelService.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    projectName: PROJECTS.Smbp,
  })
  AnalyticsService.init({
    trackers: [MixpanelTracker],
    vueRouter: router,
    projectName: PROJECTS.Smbp,
    setGlobalLinkTracking: true,
    nextTick,
  })
  initDatadogIdentity()
})

app.mount("#app")
