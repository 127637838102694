<template>
  <div
    class="feedback-dialog "
    :class="feedbackClass"
    aria-live="assertive"
    role="alert"
  >
    <div
      class="feedback-dialog__message-container"
      :class="{ 'feedback-dialog__message-container--with-header': header }"
    >
      <i class="icon feedback-dialog__icon" :class="iconClass"></i>
      <span
        v-if="header"
        :class="
          `feedback-dialog__header feedback-dialog__header--${headerTextSize}`
        "
      >
        {{ header }}
      </span>
      <span v-if="message" class="feedback-dialog__text">
        {{ message }}
      </span>
      <span v-else class="feedback-dialog__text">
        <slot />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      validator: val =>
        ["positive", "negative", "warning", "info"].includes(val),
      required: true
    },
    message: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    header: {
      type: String,
      default: null
    },
    headerTextSize: {
      type: String,
      validator: val => ["medium"].includes(val),
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass() {
      if (this.icon) {
        return { [this.icon]: true }
      }
      return {
        "icon-alert": this.variant === "negative" || this.variant === "warning",
        "icon-selected": this.variant === "positive",
        "icon-info": this.variant === "info"
      }
    },
    feedbackClass() {
      return {
        "feedback-dialog__full-width": this.fullWidth,
        [`feedback-dialog--${this.variant}`]: this.variant
      }
    }
  },
  methods: {
    triggerVote() {
      this.$emit("energyVote", this.voteKey)
    },
    handleClick() {
      this.flipCard = this.flipCard === "click" ? "default" : "click"
    }
  }
}
</script>

<style lang="scss" scoped>
$info-icon-color: #006ab0;
$info-icon-bg-color: #eaf5fb;

.feedback-dialog {
  font-size: $size-7;
  line-height: $line-height-2;
  border: 2px solid $day;
  border-radius: 4px;
  margin-top: $spacing-2;
  position: relative;
  background: $error;

  @include sm {
    width: 408px;
    max-width: 100%;

    &__full-width {
      width: 100%;
    }
  }

  &--positive {
    background-color: $green-100;
    border-color: $green-300;

    .icon {
      color: $green-800;
    }
  }

  &--negative {
    color: $fire-dark;
    background-color: $day;
    border-color: $fire-dark;
  }

  &--warning {
    background-color: $warning;
    border-color: $yellow-400;

    .icon {
      color: $yellow-400;
    }
  }

  &--info {
    background-color: $info-icon-bg-color;
    border-color: $blue-500;
    color: $info-icon-color;
    .icon {
      color: $info-icon-color;
    }
  }

  &__message-container {
    padding: $section-horizontal-padding $spacing-2;
    display: flex;

    @include xs {
      padding: $section-horizontal-padding;
    }

    &--with-header {
      display: block;

      & > .feedback-dialog__text {
        display: block;
        margin-top: $space-4;
      }

      & > .feedback-dialog__icon {
        vertical-align: top;
      }
    }
  }

  &__icon {
    font-size: $size-7;
    vertical-align: bottom;
    font-weight: $weight-bold;
    flex: 0 0 auto;
    margin-right: $spacing-2;
    margin-top: 5px;

    @include xs {
      margin-top: 0;
      font-size: $size-6;
      vertical-align: middle;
    }
  }
  &__header {
    line-height: $line-height-4;
    font-weight: $weight-medium;

    &--medium {
      font-size: $font-size-3;
    }
  }

  &__text {
    flex: 1 1 auto;
    line-height: $line-height-4;

    @include xs {
      vertical-align: middle;
    }
  }
}
</style>
