const isStoryblokComponent = block => {
  return (
    block &&
    typeof block === "object" &&
    block.hasOwnProperty("_uid") &&
    block.hasOwnProperty("component")
  )
}

const squeezeArrayToObject = arr => {
  return new Proxy(arr, {
    get(array, prop) {
      if (arr[0].hasOwnProperty(prop)) return arr[0][prop]

      return array[prop]
    }
  })
}

function parseObject(block) {
  for (const property in block) {
    if (Array.isArray(block[property])) {
      if (
        block[property].length === 1 &&
        isStoryblokComponent(block[property][0])
      ) {
        block[property] = squeezeArrayToObject(block[property])
        parseObject(block[property][0])
      } else {
        parseObject(block[property])
      }
    } else if (isStoryblokComponent(block[property])) {
      parseObject(block[property])
    }
  }
}

module.exports = function(storyblokResponse) {
  const { content } = storyblokResponse

  if (isStoryblokComponent(content)) {
    parseObject(content)
  }

  return { ...storyblokResponse, content }
}
