import {
  setCookie,
  getCookie,
  getDomain,
  deleteCookie
} from "../../src/services/Cookies"
import { sessionStore } from "./StorageFactory"
import { isServer } from "../helpers/isServer"
import { isObject } from "../helpers/objectHelper"

const STORAGE_KEY = "app_storage_cache"
const STORAGE_LAST_UPDATED_TIME_KEY = "app_storage_cache_last_updated"
const IGNORE_CACHE_COOKIE_NAME = "ignore-app-storage-cache"

const serialize = (obj, serializers) =>
  JSON.stringify(obj, function(key, stringifiedValue) {
    const originalValue = this[key]
    if (!originalValue || typeof originalValue !== "object")
      return stringifiedValue

    // Serialize complex objects using custom serializers
    const serializer = serializers.find(serializer =>
      serializer.isMatchingSerializer(originalValue)
    )
    if (!serializer) return stringifiedValue

    return {
      __type: serializer.typeTag,
      __value: serializer.toJSON(originalValue, value =>
        serialize(value, serializers)
      )
    }
  })

const deserialize = (jsonStr, deserializers) =>
  JSON.parse(jsonStr, (_, value) => {
    if (!value || typeof value !== "object" || !value.__type) return value

    const deserializer = deserializers.find(
      serializer => serializer.typeTag === value.__type
    )
    if (!deserializer) return value
    return deserializer.fromJSON(value.__value, value =>
      deserialize(value, deserializers)
    )
  })

export const ClassSerializerFactory = (classFn, typeTag) => ({
  typeTag,
  isMatchingSerializer: obj => obj instanceof classFn,
  toJSON: (obj, serializeFn) => serializeFn({ ...obj }),
  fromJSON: (value, deserializeFn) =>
    Object.assign(Object.create(classFn.prototype), deserializeFn(value))
})

export default {
  disableMutationListener: null,
  /**
   * Initialises the plugin with passed config
   * @param {Object} config
   * @param {Serializer[]} config.serializers Serializers used to persist complex object, e.g. DayJS or Date
   *
   * @typedef {Object} Serializer
   * @property {string} typeTag String used to tag instances of custom object. Needs to be unique across serializers
   * @property {function} isMatchingSerializer Function that needs to check if an given object should be processed by this serializer
   * @property {function} toJSON Function that needs to convert the object into valid JSON string. The return value will be used to reverse this operation in 'fromJSON'
   * @property {function} fromJSON Function that needs to convert JSON string, back into the custom object
   */
  init({
    store,
    modules = [],
    expirationTimeInSeconds,
    reducer = state => state,
    serializers = []
  }) {
    if (isServer()) return
    this.serializers = serializers

    const ignoreCache = getCookie(IGNORE_CACHE_COOKIE_NAME)
    if (ignoreCache) {
      deleteCookie(IGNORE_CACHE_COOKIE_NAME)
    }

    if (
      !ignoreCache &&
      (!expirationTimeInSeconds ||
        !this.isCacheOutOfDate(expirationTimeInSeconds))
    ) {
      this.restoreState(store)
    }

    this.disableMutationListener = store.subscribe((mutation, state) => {
      if (modules.length) {
        const module = modules.find(module => mutation.type.startsWith(module))
        if (module) this.storeState(state, module)
      } else {
        this.storeState(reducer(state))
      }
    })
  },
  restoreState(store) {
    const cachedState = this.retrieveState()
    if (!cachedState) return

    const combinedState = store.state

    // Restore cache for each module, while preserving non-cached fields (this is needed, as merging entire store with cache object naively, caused deletion of nested fields not present in cache)
    Object.keys(store.state).forEach(storeKey => {
      const cachedValue = cachedState[storeKey]
      if (!cachedValue) return
      if (isObject(cachedValue)) {
        Object.assign(combinedState[storeKey], cachedValue)
      } else {
        combinedState[storeKey] = cachedValue
      }
    })

    store.replaceState(combinedState)
  },
  storeState(data, module) {
    let state = {}

    if (module) {
      let cachedState = this.retrieveState() || {}
      cachedState[module] = data[module]
      state = cachedState
    } else {
      state = data
    }

    sessionStore.setItem(STORAGE_KEY, serialize(state, this.serializers))
    sessionStore.setItem(STORAGE_LAST_UPDATED_TIME_KEY, Date.now())
  },
  retrieveState() {
    return deserialize(sessionStore.getItem(STORAGE_KEY), this.serializers)
  },
  isCacheOutOfDate(expirationTimeInSeconds) {
    const lastUpdatedTime = sessionStore.getItem(STORAGE_LAST_UPDATED_TIME_KEY)
    return (Date.now() - lastUpdatedTime) / 1000 > expirationTimeInSeconds
  },
  clearStoredState() {
    return sessionStore.removeItem(STORAGE_KEY)
  },
  ignoreCache() {
    setCookie({
      name: IGNORE_CACHE_COOKIE_NAME,
      domain: getDomain(),
      value: "true"
    })
  },
  disablePersistence() {
    this.disableMutationListener()
  }
}
