export default {
  methods: {
    setupEventLinkListener() {
      this.detachEventLinkListeners()
      this.attachEventLinkListeners()
    },
    attachEventLinkListeners() {
      const eventLinks = this.getEventLinkElements()
      if (!eventLinks) return

      this.linkClickEventDetachFns = eventLinks.map(eventLink => {
        const eventName = eventLink.getAttribute("data-event")
        eventLink.addEventListener("click", () => {
          this.$emit(eventName)
        })
        return () =>
          eventLink.removeEventListener("click", () => {
            this.$emit(eventName)
          })
      })
    },
    detachEventLinkListeners() {
      if (this.linkClickEventDetachFns) {
        this.linkClickEventDetachFns.forEach(detachListener => detachListener())
        this.linkClickEventDetachFns = null
      }
    },
    getEventLinkElements() {
      if (!this.$el) return
      return Array.from(this.$el.querySelectorAll(`a[data-event]`))
    }
  }
}
