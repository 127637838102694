<template>
  <div class="mt-4">
    <Spinner
      v-if="registerInterestSubmitStatus.isPending"
      spinner-text="Loading..."
    />
    <FeedbackDialog
      v-else-if="currentDialog"
      ref="feedback-dialog"
      :header="currentDialog.header"
      :variant="currentDialog.variant"
      :full-width="currentDialog.fullWidth"
      class="mb-4"
    >
      {{ currentDialog.message }}
    </FeedbackDialog>

    <InputField
      v-if="!hasNoSlots && !registerInterestSubmitStatus.isPending"
      ref="appointment-preferences"
      v-model="appointmentPreferences"
      :disabled="registerInterestSubmitStatus.isSuccess"
      label="What times would work for you? (optional)"
      name="appointmentPreferences"
      force-label-up
      multiline
      autofocus
      full-width
    />

    <div class="register-interest__buttons">
      <NavButton
        ref="accountLink"
        :target-project="PROJECTS.AccountApp"
        :disabled="registerInterestSubmitStatus.isPending"
        variant="invertedPrimary"
        to="/"
        class="register-interest__button"
      >
        Back to account
      </NavButton>
      <AppButton
        v-if="!hasNoSlots"
        ref="submit-button"
        :loading="registerInterestSubmitStatus.isPending"
        :disabled="
          registerInterestSubmitStatus.isPending ||
          registerInterestSubmitStatus.isSuccess
        "
        variant="primary"
        class="register-interest__button"
        @click="submitAppointmentPreferences"
      >
        Submit
      </AppButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import NavButton from "@soenergy/frontend-library/src/components/NavButton.vue"
import Spinner from "@soenergy/frontend-library/src/components/Spinner.vue"

export const DIALOG_CONFIGS = {
  noSlotsSuccess: {
    header: "No Slots Available",
    variant: "info",
    fullWidth: true,
    message:
      "We currently don't have any available slots for your smart meter installation. We're adding new slots every week and have added you to our waiting list. We will be in touch with you when there are new appointment slots available, or you can check back again in a few days.",
  },
  noSlotsWarning: {
    header: "No Slots Available",
    variant: "warning",
    fullWidth: true,
    message:
      "We currently don't have any available slots for your smart meter installation. We are adding new slots each week, so please check back in a few days.",
  },
  submitSuccess: {
    header: "Your appointment preferences have been registered!",
    variant: "positive",
    fullWidth: true,
    message:
      "Thank you for registering your appointment preferences. We will be in touch with you when there are new appointment slots available.",
  },
  submitError: {
    header:
      "We were unable to register your preference for alternative appointment times due to a technical issue.",
    variant: "negative",
    fullWidth: true,
    message:
      "We're adding new appointment slots every week. Please check back later to find a suitable appointment time.",
  },
  defaultInfo: {
    header: "If none of these slots work for you",
    variant: "info",
    fullWidth: true,
    message:
      "We're adding new slots every week and have added you to our waiting list to get an appointment at a time that works for you. We will be in touch with you when there are new appointment slots available, or you can check back again in a few days.",
  },
}

export default {
  components: {
    InputField,
    AppButton,
    FeedbackDialog,
    NavButton,
    Spinner,
  },
  props: {
    isReschedule: {
      type: Boolean,
      default: false,
    },
    hasNoSlots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: true,
      PROJECTS,
      appointmentPreferences: "",
    }
  },
  computed: {
    ...mapGetters(["registerInterestSubmitStatus"]),
    currentDialog() {
      let dialogKey

      if (this.hasNoSlots) {
        dialogKey = this.registerInterestSubmitStatus.isSuccess
          ? "noSlotsSuccess"
          : "noSlotsWarning"
      } else {
        if (this.registerInterestSubmitStatus.isSuccess)
          dialogKey = "submitSuccess"
        else if (this.registerInterestSubmitStatus.isError)
          dialogKey = "submitError"
        else dialogKey = "defaultInfo"
      }

      return DIALOG_CONFIGS[dialogKey] || null
    },
  },
  mounted() {
    if (this.hasNoSlots && !this.registerInterestSubmitStatus.isSuccess) {
      this.submitAppointmentPreferences()
    }
  },
  methods: {
    submitAppointmentPreferences() {
      this.$store.dispatch("registerInterest", {
        customerComment: this.appointmentPreferences,
        isReschedule: this.isReschedule,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.register-interest {
  &__buttons {
    display: flex;
    gap: $space-4;
    margin-top: $space-6;
  }

  &__button {
    flex: 1;
  }
}

:deep(.form-group__input) {
  height: 6em;
}
</style>
