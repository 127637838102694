import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import {
  untestedMutations,
  untestedGetters,
} from "@/store/untested/modules/appointment"

dayjs.extend(utc)

export const state = {
  selectedAppointmentSlot: null,
  comment: null,
}

export const mutations = {
  SET_COMMENT(state, comment) {
    state.comment = comment
  },
  ...untestedMutations,
  // Tested mutations below
}

export const getters = {
  ...untestedGetters,
  // Tested getters below
}

export default {
  state,
  mutations,
  getters,
}
