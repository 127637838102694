<template>
  <LoadingOverlay :is-active="bookingApiStatus === 'LOADING'" />
  <GenericImageLayout
    v-if="pageContent && bookingApiStatus === 'SUCCESS'"
    :image-src="pageContent.heroImage.filename"
    :background-color="pageContent.backgroundColour"
    class="appointment-booked"
    mobile-image
  >
    <div class="booking-confirmed">
      <h1 class="booking-confirmed__heading">Booking Confirmed!</h1>
      <Article
        :text-content="pageContent.text"
        :variable-set="variableData"
        class="booking-confirmed__text"
        no-margin
      >
      </Article>
      <dl class="booking-confirmed__list">
        <dt>Booking reference</dt>
        <dd ref="booking-reference">{{ booking.jobNumber }}</dd>
        <dt>Address</dt>
        <dd ref="address">{{ booking.fullAddress }}</dd>
        <dt>Date</dt>
        <dd ref="appointment-date">{{ booking.appointmentDateFormatted }}</dd>
        <dt>Time</dt>
        <dd ref="appointment-time">{{ booking.appointmentTimeSlot }}</dd>
      </dl>
      <template v-if="booking.startDateTime">
        <h4 class="booking-confirmed__add-to-calendar-title">
          {{ pageContent.addToCalendarTitle }}
        </h4>
        <!-- Keep below props as CamelCase otherwise it breaks the component -->
        <!-- eslint-disable vue/attribute-hyphenation -->
        <add-to-calendar-button
          :name="pageContent.calendarEventName || 'Smart Meter Installation'"
          :description="pageContent.calendarEventDescription"
          options="'Apple', 'Google', 'Outlook.com'"
          :startDate="booking.startDateTime.format('YYYY-MM-DD')"
          :startTime="booking.appointmentStartTime"
          :endTime="booking.appointmentEndTime"
          timeZone="Europe/London"
          buttonStyle="text"
          styleLight="--btn-underline: transparent; --btn-background: transparent; --btn-text: #0070A4"
          hideIconButton
          buttonsList
        />
        <!-- eslint-enable vue/attribute-hyphenation -->
      </template>
      <NavButton
        :target-project="PROJECTS.AccountApp"
        class="booking-confirmed__button"
        variant="secondary"
        to="/"
      >
        <span class="booking-confirmed__icon icon icon-ctrl-left"></span>
        <span>{{ pageContent.accountButton }}</span>
      </NavButton>
    </div>
  </GenericImageLayout>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import "add-to-calendar-button"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import GenericImageLayout from "@soenergy/frontend-library/src/components/GenericImageLayout"
import NavButton from "@soenergy/frontend-library/src/components/NavButton"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import { JOB_CANCELLATION_DATE } from "@/variable_keys/smbp_variables.js"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import bookingConfirmedContent from "soenergy-cms-loader!?path=smbp/booking-confirmed"

export default {
  components: {
    GenericImageLayout,
    NavButton,
    Article,
    LoadingOverlay,
  },
  mixins: [cmsPreviewMixin({ story: bookingConfirmedContent })],
  data() {
    return {
      PROJECTS,
    }
  },
  computed: {
    ...mapState({
      bookingApiStatus: (state) => state.bookingDetails.bookingApiStatus,
    }),
    ...mapGetters({
      booking: "bookingDetails/latestBooking",
    }),
    variableData() {
      return {
        [JOB_CANCELLATION_DATE]: this.booking.cancellationDate,
      }
    },
  },
  mounted() {
    this.$store.dispatch("bookingDetails/fetchBookingData")
  },
}
</script>

<style lang="scss" scoped>
.booking-confirmed {
  padding: $space-8 $space-4;

  @include lg {
    padding: $space-13 $space-13 $space-13 $space-0;
  }

  @include xl {
    padding: $space-13 $space-16 $space-13 $space-0;
  }

  &__heading {
    @include lg {
      font-size: $font-size-7;
    }
  }

  &__text {
    margin-bottom: $space-10;
    font-size: $font-size-3;

    @include lg {
      font-size: $font-size-4;
    }
  }

  &__list {
    margin-bottom: $space-10;
    font-size: $font-size-3;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: $space-3;
    grid-column-gap: $space-6;

    dt {
      grid-column-start: 1;
      font-weight: $weight-medium;
    }

    dd {
      grid-column-start: 2;
    }
  }

  &__icon {
    font-weight: $weight-bold;
  }

  &__button {
    margin-top: $space-8;
  }

  &__add-to-calendar-title {
    margin-bottom: $space-2;
  }
}
</style>
