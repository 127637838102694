const ConvertBlockArraysToObjects = require("./ConvertBlockArraysToObjects")
const FilterOutHiddenMapper = require("./FilterOutHiddenMapper")

module.exports = {
  applyMappers(data, version) {
    FilterOutHiddenMapper(data)
    if (version === "2") {
      ConvertBlockArraysToObjects(data)
    }
    if (data) {
      data.cmsVersion = data.cmsVersion || version
    }
    return data
  }
}
