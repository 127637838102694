<template>
  <div v-if="showDates">
    <Datepicker
      :class="calendarClass"
      class="date-picker"
      inline
      auto-apply
      no-today
      :enable-time-picker="false"
      :allowed-dates="availableDates"
      :start-date="availableDates[0]"
      :min-date="minDate"
      :max-date="maxDate"
      :model-value="selectedDate"
      prevent-min-max-navigation
      :year-range="yearRange"
      @update:model-value="handleDate"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker"
import dayjs from "dayjs"

export default {
  components: { Datepicker },
  props: {
    showDates: {
      type: Boolean,
      default: false,
    },
    availableDates: {
      type: Array,
      default: () => [],
    },
    storedDate: {
      type: Date,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  emits: ["selected-date"],
  data() {
    return {
      selectedDate: "",
    }
  },
  computed: {
    sortedDates() {
      const dates = [...this.availableDates]
      return dates.sort((a, b) => b - a)
    },
    minDate() {
      return this.sortedDates.slice(-1)[0]
    },
    maxDate() {
      return this.sortedDates[0]
    },
    yearRange() {
      return this.minDate && this.maxDate
        ? [this.minDate.getFullYear(), this.maxDate.getFullYear()]
        : [this.currentYear, this.currentYear]
    },
    currentYear() {
      return dayjs().year()
    },
    calendarClass() {
      return {
        "date-picker--primary": this.variant === "primary",
      }
    },
  },
  watch: {
    storedDate: {
      handler(date) {
        this.handleDate(date)
      },
      immediate: true,
    },
  },
  methods: {
    handleDate(modelData) {
      this.selectedDate = modelData
      this.$emit("selected-date", modelData)
    },
  },
}
</script>

<style lang="scss">
@import "../../node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.date-picker {
  .dp__overlay {
    display: none;
  }
  .dp__month_year_select {
    &:hover {
      background: transparent;
      cursor: default;
    }
  }

  .dp__calendar_wrap,
  .dp__menu,
  .dp__main {
    font-family: $main-font;
  }

  .dp__cell_inner {
    width: 100%;
    height: 50px;

    &::before {
      content: " ";
      z-index: -1;
      width: 28px;
      height: 28px;
      background-color: transparent;
      border-radius: 14px;
      position: absolute;
    }
  }
  .dp__active_date {
    background: transparent;
    color: $black;
    &::before {
      background-color: $water-light;
    }
  }
  .dp__date_hover:hover {
    background: transparent;
    color: $black;
    &::before {
      background-color: $water-light;
    }
  }

  .dp__calendar_item {
    z-index: 1;
  }

  .dp__calendar_header_item,
  .dp__calendar_item {
    width: 14%;
  }

  .dp__calendar_header,
  .dp__calendar,
  .dp__menu {
    width: 100%;
  }

  &--primary {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);

    .dp__pointer {
      background: transparent;
      &::before {
        background-color: #eaf5fb;
      }
    }

    .dp__date_hover:hover {
      background: transparent;
      color: $black;
      &::before {
        background-color: $green-200;
      }
    }

    .dp__active_date {
      background: transparent;
      color: $black;
      &::before {
        background-color: $green-400;
      }
    }
  }
}
</style>
