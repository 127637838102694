import {
  untestedMutations,
  untestedGetters,
  untestedActions,
} from "@/store/untested/modules/bookingDetails"

export const namespaced = true

export const state = {
  bookings: [],
  bookingApiStatus: null,
}

export const actions = {
  ...untestedActions,
  // Tested actions below
}

export const mutations = {
  ...untestedMutations,
  // Tested mutations below
}

export const getters = {
  ...untestedGetters,
  // Tested getters below
}
