import { sessionStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import Ark from "../../../services/Ark"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

export const untestedActions = {
  getContacts({ commit }, accountNumber) {
    Ark.getContacts(accountNumber)
      .then((contacts) => commit("SET_CONTACTS", contacts.data))
      .catch((err) => {
        ErrorReporter.report(err)
      })
  },
  setSelectedContact({ commit, getters }, contactId) {
    const selectedContact = getters.formattedContacts.find(
      (contact) => contact.id == contactId
    )
    const { firstName, lastName, telephone, email, confirmEmail, age } =
      selectedContact || {}
    commit("SET_FIRST_NAME", firstName || "")
    commit("SET_LAST_NAME", lastName || "")
    commit("SET_TELEPHONE", telephone || "")
    commit("SET_EMAIL", email || "")
    commit("SET_CONFIRM_EMAIL", confirmEmail || "")
    commit("SET_AGE", age || false)
    return
  },
  updateCurrentContact({ state, commit }, { contactId, form }) {
    const contactToUpdate = state.contacts.find(
      (contact) => contact.id == contactId
    )
    if (contactToUpdate) {
      const contacts = state.contacts.map((contact) => {
        if (contact.id == contactToUpdate.id) {
          contact.full_name = form.name
          contact.first_name = form.firstName
          contact.last_name = form.lastName
          contact.phone_1 = form.telephone
          contact.email = form.email
          contact.confirmEmail = form.confirmEmail
          contact.age = form.age || true
        }
        return contact
      })
      commit("SET_CONTACTS", contacts)
      return
    }

    const newContact = {
      full_name: form.name,
      first_name: form.firstName,
      last_name: form.lastName,
      phone_1: form.telephone,
      email: form.email,
      confirmEmail: form.confirmEmail,
      age: form.age,
      id: contactId,
    }

    commit("SET_CONTACTS", [...state.contacts, newContact])
  },
  checkContactDetails({ state, dispatch, getters, commit }) {
    if (!state.contacts.length && getters.accountNumber) {
      dispatch("getContacts", getters.accountNumber)
    }

    if (state.contactAtHome == "new-contact" && getters.noAddedContact) {
      commit("SET_CONTACT_AT_HOME", "")
    }
  },
}

export const untestedMutations = {
  SET_FIRST_NAME(state, firstName) {
    state.form.firstName = firstName
    sessionStore.setItem("firstName", firstName)
  },
  SET_LAST_NAME(state, lastName) {
    state.form.lastName = lastName
    sessionStore.setItem("lastName", lastName)
  },
  SET_TELEPHONE(state, telephone) {
    state.form.telephone = telephone
    sessionStore.setItem("telephone", telephone)
  },
  SET_EMAIL(state, email) {
    state.form.email = email
    sessionStore.setItem("email", email)
  },
  SET_CONFIRM_EMAIL(state, confirmEmail) {
    state.form.confirmEmail = confirmEmail
    sessionStore.setItem("confirmEmail", confirmEmail)
  },
  SET_AGE(state, age) {
    state.form.age = age
    sessionStore.setItem("age", age)
  },
  SET_PERSON_AT_HOME(state, person) {
    state.personAtHome = person
    sessionStore.setItem("personAtHome", person)
  },
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts
    sessionStore.setItem("contacts", JSON.stringify(contacts))
  },
  SET_CONTACT_AT_HOME(state, contact) {
    state.contactAtHome = contact
    sessionStore.setItem("contactAtHome", contact)
  },
  SET_MEDICAL_EQUIPMENT(state, medicalEquipment) {
    state.medicalEquipment = medicalEquipment
    sessionStore.setItem("medicalEquipment", medicalEquipment)
  },
}

export const untestedGetters = {
  completeContactInfo(state) {
    return Object.values(state.form).every((value) => !!value)
  },
  formattedContacts(state) {
    return state.contacts
      .sort(
        (a, b) =>
          (b.junifer_primary_contact ? 1 : 0) -
          (a.junifer_primary_contact ? 1 : 0)
      )
      .map((contact) => {
        return {
          firstName: contact.first_name,
          lastName: contact.last_name,
          telephone: contact.phone_1,
          email: contact.email,
          confirmEmail: contact.confirmEmail || contact.email,
          age: contact.age || !!contact.junifer_contact_id,
          id: contact.id,
        }
      })
  },
  noAddedContact(state) {
    return (
      state.contacts[state.contacts.length - 1]?.junifer_contact_id ||
      !state.contacts.length
    )
  },
  contactPhone(state) {
    return state.form.telephone
  },
  contactEmail(state) {
    return state.form.email
  },
  hasMedicalEquipment(state) {
    return JSON.parse(state.medicalEquipment)
  },
}
