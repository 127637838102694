export default {
  methods: {
    setupEmitLinkClickedListener() {
      this.detachEmitLinkClickedListeners()
      this.attachEmitLinkClickedListeners()
    },
    attachEmitLinkClickedListeners() {
      const eventLinks = this.getEmitLinkClickedElements()
      if (!eventLinks) return

      this.linkClickEventDetachFns = eventLinks.map(eventLink => {
        eventLink.addEventListener("click", () => {
          this.$emit("link-clicked")
        })
        return () =>
          eventLink.removeEventListener("click", () => {
            this.$emit("link-clicked")
          })
      })
    },
    detachEmitLinkClickedListeners() {
      if (this.linkClickEventDetachFns) {
        this.linkClickEventDetachFns.forEach(detachListener => detachListener())
        this.linkClickEventDetachFns = null
      }
    },
    getEmitLinkClickedElements() {
      if (!this.$el) return
      return Array.from(this.$el.querySelectorAll("a"))
    }
  }
}
