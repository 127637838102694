import dayjs from "dayjs"

class Booking {
  constructor(rawBooking) {
    this.startDateTime =
      rawBooking["slot-start-datetime"] &&
      dayjs(rawBooking["slot-start-datetime"])
    this.endDateTime =
      rawBooking["slot-end-datetime"] && dayjs(rawBooking["slot-end-datetime"])
    this.jobNumber = rawBooking["aes-job-number"]
    this.address = rawBooking.address
    this.postcode = rawBooking.postcode
    this.customerName = rawBooking["customer-name"]
    this.customerEmail = rawBooking["customer-email"]
    this.customerPhone = rawBooking["customer-phone"]
    this.cancellationDateTime =
      rawBooking["cancellation-date-time"] &&
      dayjs(rawBooking["cancellation-date-time"])
    this.createdDate =
      rawBooking["created-at"] && dayjs(rawBooking["created-at"])
  }

  get fullAddress() {
    return [this.address, this.postcode].join(", ")
  }

  get appointmentDateFormatted() {
    if (!this.startDateTime) return null
    return this.startDateTime.format("D MMM YYYY")
  }

  get appointmentStartTime() {
    if (!this.startDateTime) return null
    return this.startDateTime.format("HH:mm")
  }

  get appointmentEndTime() {
    if (!this.endDateTime) return null
    return this.endDateTime.format("HH:mm")
  }

  get appointmentTimeSlot() {
    if (!this.appointmentStartTime || !this.appointmentEndTime) return null
    return `${this.appointmentStartTime} - ${this.appointmentEndTime}`
  }

  get cancellationDate() {
    if (!this.cancellationDateTime) return null
    return this.cancellationDateTime.format("HH:mm D MMM YYYY")
  }

  get isEligibleForCancellation() {
    return (
      this.startDateTime &&
      dayjs().isBefore(this.startDateTime.subtract(24, "hour"))
    )
  }
}

export default Booking
