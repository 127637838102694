import AnalyticsService from "./AnalyticsService"
import trackDirective from "./trackDirective"
import trackClickGroupDirective from "./trackClickGroupDirective"

import GtmTracker from "./eventTrackers/GtmTracker"
import MixpanelTracker from "./eventTrackers/MixpanelTracker"
import isVue3 from "../../helpers/isVue3"

export default {
  install(
    Vue,
    { injectDirective = true, initialiseService, ...serviceParams } = {}
  ) {
    if (initialiseService) {
      AnalyticsService.init(serviceParams)
    }
    if (isVue3()) {
      Vue.config.globalProperties.$analytics = AnalyticsService
    } else {
      Vue.prototype.$analytics = AnalyticsService
    }

    if (injectDirective) {
      Vue.directive("track", trackDirective)
      Vue.directive("track-click-group", trackClickGroupDirective)
    }
  }
}

export { AnalyticsService, GtmTracker, MixpanelTracker }
