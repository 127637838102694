export const namespaced = true

export const state = () => ({
  accessToken: null,
  accessTokenExpiryDate: null,
  email: null
})

export const mutations = {
  SET_AUTHENTICATION_DATA(state, tokenData) {
    state.accessToken = tokenData.token
    state.accessTokenExpiryDate = tokenData.expiryDate
    state.email = tokenData.email
  },
  CLEAR_AUTHENTICATION_DATA(state) {
    state.accessToken = null
    state.accessTokenExpiryDate = null
    state.email = null
  }
}

export default { namespaced, state, mutations }
