class StorageFactory {
  constructor(getStorage) {
    this.inMemoryStorage = {}
    this.getStorage = getStorage
  }

  isSupported() {
    try {
      const testKey = "storage_test_key"
      this.getStorage().setItem(testKey, testKey)
      if (this.getStorage().getItem(testKey) !== testKey) {
        this.getStorage().removeItem(testKey)
        return false
      }
      this.getStorage().removeItem(testKey)
      return true
    } catch (error) {
      return false
    }
  }
  clear() {
    if (this.isSupported()) {
      this.getStorage().clear()
    } else {
      this.inMemoryStorage = {}
    }
  }
  getItem(name) {
    if (this.isSupported()) {
      return this.getStorage().getItem(name)
    }
    if (this.inMemoryStorage.hasOwnProperty(name)) {
      return this.inMemoryStorage[name]
    }
    return null
  }
  key(index) {
    if (this.isSupported()) {
      return this.getStorage().key(index)
    } else {
      return Object.keys(this.inMemoryStorage)[index] || null
    }
  }
  removeItem(name) {
    if (this.isSupported()) {
      this.getStorage().removeItem(name)
    } else {
      delete this.inMemoryStorage[name]
    }
  }
  setItem(name, value) {
    if (this.isSupported()) {
      this.getStorage().setItem(name, value)
    } else {
      this.inMemoryStorage[name] = String(value)
    }
  }
  get length() {
    if (this.isSupported()) {
      return this.getStorage().length
    } else {
      return Object.keys(this.inMemoryStorage).length
    }
  }
}

const localStore = new StorageFactory(() => localStorage)
const sessionStore = new StorageFactory(() => sessionStorage)
export { localStore, sessionStore }
