import { isServer } from "../helpers/isServer"

const getUid = () =>
  `uid-${Math.random()
    .toString(16)
    .slice(2)}`

export const uidMixin = {
  props: {
    inputId: { type: String, default: undefined }
  },
  data() {
    return { uidMixin_isMounted: false }
  },
  mounted() {
    if (isServer()) return
    this.uidMixin_isMounted = true
  },
  computed: {
    inputIdLocal() {
      if (this.inputId) return this.inputId
      if (this.uidMixin_isMounted) return getUid()
    }
  }
}
