import { getCookie, setCookie } from "../../services/Cookies"
import GlobalClickManager from "./globalClickManager"
import { isExternalLink } from "./utils"
import { removeTrailingSlash } from "../../helpers/urlHelper"
const prependSlash = str => (str.startsWith("/") ? "" : "/") + str
const normaliseUrl = str => {
  str = removeTrailingSlash(str)
  str = prependSlash(str)
  return str
}

const toPascalCase = text =>
  text.replace(/(^\w|-\w)/g, text => text.replace(/-/, " ").toUpperCase())

const removeEmptyValues = obj =>
  Object.entries(obj).reduceRight(
    (newObj, [k, v]) => (v || v === null ? ((newObj[k] = v), newObj) : newObj),
    {}
  )

const setUserTypeCookie = value => {
  const USER_TYPE_COOKIE_AGE_DAYS = 365
  const expiryDate = new Date()
  expiryDate.setTime(
    expiryDate.getTime() + USER_TYPE_COOKIE_AGE_DAYS * 24 * 60 * 60 * 1000
  )

  setCookie({ name: "visitorType", value, expiryDate })
}

export default {
  trackers: null,
  router: null,
  routeEventDefaults: {},
  currentPath: null,
  pageNames: null,
  projectName: null,
  currentPageName: null,
  init({
    trackers,
    vueRouter,
    pageNames,
    clickContainers,
    projectName,
    setGlobalLinkTracking,
    nextTick
  }) {
    this.trackers = trackers
    this.router = vueRouter
    this.projectName = projectName

    if (vueRouter) {
      this.currentPath = vueRouter.currentRoute.path
      this.attachPageTracking(vueRouter, nextTick)
    }
    this.initUserType()
    if (pageNames && pageNames.length !== 0) {
      this.pageNames = pageNames.reduceRight(
        (config, { name, value }) => ((config[value] = name), config),
        { "/": "Home" }
      )
    }
    if (setGlobalLinkTracking) {
      GlobalClickManager.attachListener({
        pageNames: this.pageNames,
        router: vueRouter,
        clickContainers,
        clickEventHandler: (eventData, element) => {
          this.sendEvent(eventData, {
            element
          })
        }
      })
    }
    this.attachBeforeUnloadListener()
  },
  attachBeforeUnloadListener() {
    this.trackers.map(Tracker => {
      const tracker = new Tracker({
        projectName: this.projectName
      })
      window.addEventListener("beforeunload", event => {
        if (tracker.sendExitPageEvent) tracker.sendExitPageEvent()
      })
    })
  },
  setRouteEventsDefaults(slug, eventDefaults) {
    const url = slug === "home" ? "/" : normaliseUrl(slug)
    this.routeEventDefaults[url] = Array.isArray(eventDefaults)
      ? eventDefaults[0]
      : eventDefaults
  },
  getRouteDefaults(url) {
    const normalisedUrl = normaliseUrl(url)
    const routeDefaults =
      this.routeEventDefaults && this.routeEventDefaults[normalisedUrl]
    const pageName = this.pageNames && this.pageNames[normalisedUrl]
    const defaultArea = routeDefaults ? routeDefaults.area : pageName

    return {
      ...routeDefaults,
      area: defaultArea || undefined
    }
  },
  attachPageTracking(router, nextTick) {
    router.afterEach(async route => {
      nextTick ? await nextTick() : await router.app.$nextTick()

      setTimeout(() => {
        const { path, name } = route
        this.currentPath = path
        const routeDefaults = this.getRouteDefaults(path)
        const pageName =
          (routeDefaults && (routeDefaults.section || routeDefaults.area)) ||
          (name && toPascalCase(name))
        this.sendPageViewEvent(pageName)
      }, 250) // https://github.com/nuxt-community/gtm-module/blob/master/lib/plugin.js#L76
    })
  },
  sendEventWithName(name, clickData, componentName) {
    return this.sendEvent({ name }, clickData, componentName)
  },
  sendEvent(eventData, { element, nonInteractionEvent, componentName } = {}) {
    const event = Array.isArray(eventData) ? eventData[0] : eventData
    if (!event || !this.trackers) {
      return
    }
    const currentPath = this.currentPath || location.pathname
    const defaults = this.getRouteDefaults(currentPath)
    const {
      captureLinkText,
      captureLinkUrl,
      captureCurrentPage,
      ...eventProperties
    } = {
      ...removeEmptyValues(defaults),
      ...removeEmptyValues(event)
    }
    return this.trackers.map(Tracker => {
      const tracker = new Tracker({
        projectName: this.projectName,
        componentName: componentName,
        ...eventProperties
      })
      let externalLink = false

      if (element) {
        externalLink = isExternalLink(element)

        if (element.href && captureLinkUrl) {
          tracker.setLinkUrl(element.href)
        }

        if (element.text && captureLinkText) {
          tracker.setLinkText(element.text)
        }
      }

      if (currentPath) {
        tracker.setCurrentPage(currentPath)
      }

      tracker.sendEvent({
        isExternalLink: externalLink,
        element,
        nonInteractionEvent
      })
    })
  },
  sendPageViewEvent(pageName) {
    if (!this.trackers) {
      return
    }
    return this.trackers.map(Tracker => {
      const tracker = new Tracker({
        projectName: this.projectName
      })
      if (tracker.sendExitPageEvent && this.currentPageName) {
        tracker.sendExitPageEvent()
      }
      if (tracker.sendPageViewEvent) {
        this.currentPageName = pageName
        tracker.setCurrentPage(pageName)
        tracker.sendPageViewEvent()
      }
    })
  },
  initUserType() {
    const isUserCustomer = getCookie("visitorType") === "customer"
    if (!isUserCustomer) {
      setUserTypeCookie("prospect")

      this.trackers.map(Tracker => {
        const tracker = new Tracker({
          projectName: this.projectName
        })
        if (tracker.setUserType) {
          tracker.setUserType("prospect")
        }
      })
    }
  },
  markUserTypeAsCustomer() {
    setUserTypeCookie("customer")
    this.trackers.map(Tracker => {
      const tracker = new Tracker({
        projectName: this.projectName
      })
      if (tracker.setUserType) {
        tracker.setUserType("customer")
      }
    })
  }
}
