import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL,
  requestInterceptors: [authenticate],
})

export default {
  getAccountId() {
    return apiClient.get("api/v1/user.json")
  },
  fetchJunifer() {
    return apiClient.get("api/v1/junifer.json")
  },
}
