<template>
  <AppLink
    v-bind="$attrs"
    :link="resolveLink({ link, linkType, linkData, targetProject })"
    :target="browsingContext({ link, linkType })"
    :routing-component="routingComponent"
    v-on="$listeners"
  >
    <slot />
  </AppLink>
</template>

<script>
import projectUrlResolverMixin from "../mixins/projectUrlResolverMixin"
import AppLink from "./AppLink"

export default {
  emits: ["click"],
  components: {
    AppLink
  },
  mixins: [projectUrlResolverMixin],
  inheritAttrs: false,
  props: {
    link: {
      type: String,
      default: null
    },
    linkType: {
      type: String,
      default: null
    },
    linkData: {
      type: Object,
      default: () => ({})
    },
    routingComponent: {
      type: Object,
      default: null
    },
    targetProject: {
      type: String,
      default: null
    }
  }
}
</script>
