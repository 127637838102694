import Mixpanel from "../../../services/Mixpanel"

class MixpanelTracker {
  constructor({
    name,
    area,
    section,
    projectName,
    mixpanelEventName = name,
    mixpanelEventProperties = {},
    componentName
  } = {}) {
    this.name = mixpanelEventName
    this.metadata = mixpanelEventProperties
    this.metadata.area = area
    this.metadata.section = section
    this.metadata.project = projectName
    this.metadata.component = componentName
  }

  setLinkText(text) {
    this.metadata.linkText = text
  }

  setLinkUrl(url) {
    this.metadata.linkUrl = url
  }

  setCurrentPage(page) {
    this.metadata.Page = page
  }

  sendEvent(eventParameters) {
    return Mixpanel.sendEvent(this.name, this.metadata, eventParameters)
  }

  async sendExitPageEvent() {
    Mixpanel.sendEvent("Page Exit", {}, { isExitPageEvent: true })
    Mixpanel.time_event("Page Exit")
  }

  async sendPageViewEvent() {
    await Mixpanel.register({
      Page: this.metadata.Page,
      "Page Path": window.location.pathname
    })
    return Mixpanel.sendEvent("Page View", this.metadata)
  }

  setUserType(type) {
    return Mixpanel.register({
      "Visitor Type": type
    })
  }
}

export default MixpanelTracker
