import dayjs from "dayjs"

export default {
  methods: {
    getSMBPBooking(bookingList) {
      return bookingList.filter((booking) => !booking.imbp_booking)[0]
    },
    getActiveBookings(bookingList) {
      return bookingList.filter(
        (booking) => booking.booked && !booking.cancelled
      )
    },
    hasActiveBookings(bookingList) {
      return bookingList.find((booking) => booking.booked && !booking.cancelled)
    },
    appointmentDate(booking) {
      return dayjs(booking.serialized_current_slot.date).format("D MMM YYYY")
    },
    jobCancellationDate(booking) {
      return dayjs(booking.serialized_current_slot.date)
        .subtract(1, "days")
        .format("D MMM YYYY")
    },
    formatSlot(booking) {
      let slot = booking.serialized_current_slot
      return slot.start_time
        ? `${slot.start_time.slice(0, 5)} - ${slot.end_time.slice(0, 5)}`
        : ""
    },
    jobCancellationDateTime(booking) {
      let slot = booking.serialized_current_slot
      let timeSlot = `${slot.date} ${slot.start_time}`
      return dayjs(timeSlot).subtract(24, "hour").format("YYYY,MM-DD HH:mm:ss")
    },
    eligibleToCancel(booking) {
      return dayjs(this.jobCancellationDateTime(booking)).isAfter(
        dayjs(),
        "minute"
      )
    },
  },
}
