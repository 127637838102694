import getDomain from "../helpers/getDomain"
import * as CookieConsentPlugin from "./CookieConsentPlugin"

export { getDomain }

export const setCookie = ({
  name,
  value,
  domain = getDomain(),
  maxAge,
  expiryDate
}) => {
  const ifExpiryDateSet = expiryDate
    ? "; expires=" + expiryDate.toUTCString()
    : ""
  const maxAgeSegment = maxAge || maxAge === 0 ? `;max-age=${maxAge}` : ""
  document.cookie =
    name +
    "=" +
    value +
    ";path=/;domain=" +
    domain +
    maxAgeSegment +
    ifExpiryDateSet
}

export const getCookie = cookieName => {
  let name = cookieName + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let splitCookie = decodedCookie.split(";")
  for (let i = 0; i < splitCookie.length; i++) {
    let cookie = splitCookie[i]
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ""
}

export const deleteCookie = cookieName => {
  if (getCookie(cookieName)) {
    let name = cookieName
    let value = ""

    setCookie({
      name,
      value,
      domain: getDomain(),
      maxAge: 0
    })
  }
}

export const checkCookieIsAllowed = cookieType => {
  if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true") {
    return CookieConsentPlugin.getAcceptedCategories()[cookieType]
  }
  const fetchCookie = getCookie("cookie-preferences")
  const cookiePreferences = fetchCookie ? JSON.parse(fetchCookie) : []

  let cookiePrefByType = cookiePreferences.find(type => type.id === cookieType)
  return cookiePrefByType ? cookiePrefByType.value : false
}
