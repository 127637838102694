import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import store from "@/store/store"

export default (app) =>
  ErrorReporter.init({
    app,
    apiKey: process.env.VUE_APP_BUGSNAG_TOKEN,
    onError: function (event) {
      try {
        const id = store.getters.accountNumber
        const email = store.getters.primaryEmail
        event.setUser(id, email)
      } catch (err) {} // eslint-disable-line no-empty
    },
  })
