<template>
  <div>
    <CookieBanner
      v-if="isBannerDisplayed"
      :banner-text="bannerText"
      :accept-all-button="acceptAllButton"
      :manage-cookies-button="manageCookiesButton"
      :has-manage-cookies="hasManageCookies"
      @acceptAllCookies="handleAcceptAllCookies"
      @openManageCookiesModal="handleOpenManageCookiesModal"
    />
    <CookieModal
      :selected-cookies="cookiePreference"
      :is-modal-open="isModalOpen"
      :modal-title="modalTitle"
      :modal-sub-title="modalSubTitle"
      :accept-button="acceptButton"
      :save-button="saveButton"
      :modal-description="modalDescription"
      :necessary-description="necessaryDescription"
      :performance-description="performanceDescription"
      :functional-description="functionalDescription"
      :advertising-description="advertisingDescription"
      @saveCookieChoice="handleSelectorValues"
      @closeModal="handleCloseManageCookiesModal"
    />
  </div>
</template>

<script>
import CookieBanner from "./CookieBanner.vue"
import CookieModal from "./CookieModal.vue"
import { getDomain, setCookie, getCookie } from "../../../src/services/Cookies"
import * as CookiePreferences from "../../../src/services/CookiePreferences"
import GoogleTagManager from "../../../src/services/GoogleTagManager"

export default {
  name: `CookieManager`,
  components: {
    CookieBanner,
    CookieModal
  },
  props: {
    hasManageCookies: {
      type: Boolean,
      default: false
    },
    bannerText: {
      type: Object,
      default: () => {}
    },
    acceptAllButton: {
      type: Object,
      default: () => {}
    },
    manageCookiesButton: {
      type: Object,
      default: () => {}
    },
    modalTitle: {
      type: String,
      default: "Cookie preferences"
    },
    modalSubTitle: {
      type: String,
      default: "How do we categorise cookies?"
    },
    acceptButton: {
      type: String,
      default: "Accept All"
    },
    saveButton: {
      type: String,
      default: "Save My Preferences"
    },
    modalDescription: {
      type: Object,
      default: () => ({})
    },
    necessaryDescription: {
      type: Object,
      default: () => ({})
    },
    performanceDescription: {
      type: Object,
      default: () => ({})
    },
    functionalDescription: {
      type: Object,
      default: () => ({})
    },
    advertisingDescription: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isBannerDisplayed: false,
      cookiePreferenceName: "cookie-preferences",
      isModalOpen: false,
      defaultSettings: [
        {
          id: "necessary",
          value: true
        },
        {
          id: "performance",
          value: false
        },
        {
          id: "functional",
          value: false
        },
        {
          id: "advertising",
          value: false
        }
      ],
      cookiePreference: []
    }
  },
  created() {
    if (typeof process !== "undefined" && process.server) return

    const userCookiePreferences = this.getAllAcceptedCookie()
    if (!userCookiePreferences) {
      this.isBannerDisplayed = true
      this.cookiePreference = this.defaultSettings
    } else {
      this.cookiePreference = userCookiePreferences
    }
  },
  methods: {
    showModal() {
      this.isModalOpen = true
      const userCookiePreferences = this.getAllAcceptedCookie()
      if (userCookiePreferences) {
        this.cookiePreference = userCookiePreferences
      }
    },
    handleAcceptAllCookies() {
      let setAcceptAll = this.defaultSettings.map(item => {
        item.value = true
        return item
      })
      this.setCookiePreference(setAcceptAll)
      this.sendDataToGTM(setAcceptAll)
      this.isBannerDisplayed = false
    },
    getAllAcceptedCookie() {
      const fetchCookie = getCookie(this.cookiePreferenceName)
      return fetchCookie ? JSON.parse(fetchCookie) : ""
    },
    handleOpenManageCookiesModal() {
      this.isModalOpen = true
    },
    handleCloseManageCookiesModal() {
      this.isModalOpen = false
    },
    handleSelectorValues(selectorValues) {
      const userCookiePreferences = this.getAllAcceptedCookie()

      this.isModalOpen = false
      this.isBannerDisplayed = false
      this.cookiePreference = selectorValues
      this.setCookiePreference(selectorValues)
      this.sendDataToGTM(selectorValues, userCookiePreferences)
      // hard refresh here if any picked values are false and had cookies before
      if (selectorValues.find(item => !item.value) && userCookiePreferences) {
        window.location.reload()
      }
    },
    setCookiePreference(selectorValues) {
      let days = 182
      let date = new Date()
      let expires

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = date

      setCookie({
        name: this.cookiePreferenceName,
        value: JSON.stringify(selectorValues),
        domain: getDomain(),
        expiryDate: expires
      })
      // emit event to the parent
      this.$emit("updateCookiePreferences")
      CookiePreferences.setCookiePreference(selectorValues)
    },
    preferencesToGtmConsent(preferences) {
      let gtmVariables = {
        security_storage: "granted"
      }
      preferences.forEach(item => {
        if (item.id === "functional") {
          gtmVariables["functionality_storage"] = item.value
            ? "granted"
            : "denied"
        }
        if (item.id === "advertising") {
          gtmVariables["ad_storage"] = item.value ? "granted" : "denied"
          gtmVariables["personalization_storage"] = item.value
            ? "granted"
            : "denied"
        }
        if (item.id === "performance") {
          gtmVariables["analytics_storage"] = item.value ? "granted" : "denied"
        }
      })
      return gtmVariables
    },
    sendDataToGTM(data, previousPreferences) {
      if (typeof gtag === "undefined") {
        return
      }
      const gtmConsent = this.preferencesToGtmConsent(data)
      gtag("consent", "update", gtmConsent)

      const getAcceptedGtmConsents = consents =>
        Object.keys(consents).filter(consent => consents[consent] === "granted")

      let newConsentsArray = getAcceptedGtmConsents(gtmConsent)
      if (previousPreferences) {
        const previousGtmConsent = this.preferencesToGtmConsent(
          previousPreferences
        )
        const previousConsentArray = getAcceptedGtmConsents(previousGtmConsent)
        newConsentsArray = newConsentsArray.filter(
          consent => !previousConsentArray.includes(consent)
        )
      }
      if (newConsentsArray) {
        GoogleTagManager.sendCustomEvent({
          event: "consentsAdded",
          newConsents: newConsentsArray.join(",")
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
