export default {
  sendEvent(
    {
      action: gaAction,
      category: gaCategory,
      label: gaLabel,
      value: gaValue,
      nonInteractionEvent,
      gtmEventData
    },
    { isExternalLink }
  ) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "genericEvent",
      gaAction,
      gaCategory,
      gaLabel,
      gaValue,
      isExternalLink,
      nonInteractionEvent,
      gtmEventData
    })
  },
  sendCustomEvent(eventData) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  }
}
