<template>
  <ErrorLayout variant="notFoundError"></ErrorLayout>
</template>

<script>
import ErrorLayout from "@soenergy/frontend-library/src/components/ErrorLayout"

export default {
  components: {
    ErrorLayout,
  },
}
</script>

<style scoped lang="scss">
.error {
  height: 100vh;
}
</style>
