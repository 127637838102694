import AnalyticsService from "./AnalyticsService"

export default {
  [process.env.VUE_APP_VERSION === "3" ? "beforeMount" : "bind"](
    element,
    { arg: eventType = "click", value: eventData },
    vnode
  ) {
    let trackingData = null
    const validTrackingData = eventData =>
      eventData &&
      eventData.length &&
      eventData[0] &&
      eventData[0].component &&
      eventData[0].name

    if (!eventData) {
      return
    } else if (validTrackingData(eventData)) {
      trackingData = eventData[0]
    } else if (validTrackingData(eventData.tracking)) {
      trackingData = eventData.tracking[0]
    } else if (
      eventData &&
      eventData.label &&
      typeof eventData.label === "string"
    ) {
      trackingData = eventData.label
    }

    if (!trackingData || element._analyticsListener) {
      return
    }

    element._analyticsListener = event => {
      const clickedInputLabel =
        event.target.tagName === "LABEL" &&
        document.getElementById(event.target.getAttribute("for"))

      const componentName =
        vnode.context && vnode.context._isVue
          ? vnode.context.$options.name || vnode.context.$options._componentTag
          : "AnonymousComponent"

      if (clickedInputLabel) {
        // Clicks on labels trigger a second event for the input element
        // Skipping, to avoid duplicate reports
        return
      }
      if (typeof trackingData === "string") {
        AnalyticsService.sendEventWithName(`Click - ${trackingData}`, {
          element,
          componentName
        })
      } else {
        AnalyticsService.sendEvent([trackingData], { element, componentName })
      }
    }

    element.addEventListener(eventType, element._analyticsListener)
  },
  [process.env.VUE_APP_VERSION === "3" ? "unmounted" : "unbind"](
    element,
    { arg: event },
    vnode
  ) {
    element.removeEventListener(event, element._analyticsListener)
  }
}
