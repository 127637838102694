const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const textToSHA1HexDigest = async text => {
  // Preview validation logic documented in
  // https://www.storyblok.com/faq/how-to-verify-the-preview-query-parameters-of-the-visual-editor
  const msgUint8 = new TextEncoder().encode(text)
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(b => b.toString(16).padStart(2, "0")).join("")
}

const isUrlSignedByStoryblok = async params => {
  const validationString = `${params["_storyblok_tk[space_id]"]}:${process.env.VUE_APP_STORYBLOK_TOKEN}:${params["_storyblok_tk[timestamp]"]}`
  const validationToken = await textToSHA1HexDigest(validationString)
  const validToken =
    params["_storyblok_tk[token]"] == validationToken &&
    params["_storyblok_tk[timestamp]"] > Math.floor(Date.now() / 1000) - 3600
  return validToken
}

export const isInPreviewMode = () =>
  window.location.search.includes("_storyblok") ||
  process.env.NODE_ENV === "development"

export const isInStoryblokEditor = async () => {
  if (isInPreviewMode()) {
    const params = Object.fromEntries([...new URLSearchParams(location.search)])
    const hasStoryblokToken = Boolean(params["_storyblok_tk[token]"])
    // The crypto library used to verify Storyblok signature
    // is not available when page is loaded in an iframe
    if (hasStoryblokToken) {
      return isInIframe() || (await isUrlSignedByStoryblok(params))
    }
  }
  return false
}

export const importAsync = moduleImport => async () => await moduleImport()
