<template>
  <section
    v-track-click-group="linkClickTracking"
    class="article"
    :class="{
      'article--no-margin': noMargin,
      'article--half-margin': halfMargin,
      'article--highlight-text': highlightText,
      'article--white-text': whiteText,
      'article--white-text-link': whiteTextLink,
      'article--black-text-link': blackTextLink,
      'article--red-text': redText,
      'article--orange-text': orangeText,
      'article--no-margin-bottom': noMarginBottom,
      'article--green-list-marker': useGreenListMarker,
      'article--h4': styleVariant === 'h4',
      'article--small-text': styleVariant === 'small-text',
      'article--small-heading': smallHeading,
      [`article--line-height-${lineHeightVariant}`]: !!lineHeightVariant,
      'article--light-blue-link': styleVariant === 'light-blue-link'
    }"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="isHtmlGenerated" v-html="generatedHtml"></div>
    <div v-else><slot></slot></div>
  </section>
</template>

<script>
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.cjs"
import stubDirectives from "../mixins/stubDirectives"
import articleRelativeLinkHandlerMixin from "../mixins/articleRelativeLinkHandlerMixin"
import articleEventLinkHandlerMixin from "../mixins/articleEventLinkHandlerMixin"
import projectUrlResolverMixin from "../mixins/projectUrlResolverMixin"
import articleEmitLinkClickedHandlerMixin from "../mixins/articleEmitLinkClickedHandlerMixin"
import { deepClone } from "../helpers/objectHelper"

// Allows to apply custom classes set in Storyblok to headings
const headingSchema = function(node) {
  let attrs = {}
  if (node.content && node.content.length === 1 && node.content[0].marks) {
    if (
      node.content[0].marks.length === 1 &&
      node.content[0].marks[0].type === "styled"
    ) {
      attrs = node.content[0].marks[0].attrs
    }
  }
  return {
    tag: [
      {
        tag: `h${node.attrs.level}`,
        attrs: attrs
      }
    ]
  }
}
// Allows to apply custom classes set in Storyblok to paragraphs
const paragraphSchema = function(node) {
  let attrs = {}
  if (node.content && node.content.length === 1 && node.content[0].marks) {
    if (
      node.content[0].marks.length === 1 &&
      node.content[0].marks[0].type === "styled"
    ) {
      attrs = node.content[0].marks[0].attrs
    } else if (
      node.content[0].marks.length > 1 &&
      node.content[0].marks[0].type === "link" &&
      node.content[0].marks[1].type === "styled"
    ) {
      attrs = node.content[0].marks[1].attrs
    }
  }

  return {
    tag: [
      {
        tag: `p`,
        attrs: attrs
      }
    ]
  }
}
// Allows to apply custom classes set in Storyblok to paragraphs
const paragraphSchemaApplyClassToWrapperEl = function(node) {
  let attrs = {}
  const childClasses = node.content?.map(
    el => el?.marks?.find(mark => mark.type === "styled")?.attrs?.class
  )
  const allChildHaveSameClass = childClasses?.every(
    className => className === childClasses[0] && className
  )
  if (allChildHaveSameClass) attrs = { class: childClasses[0] }

  return {
    tag: [
      {
        tag: `p`,
        attrs
      }
    ]
  }
}

export default {
  mixins: [
    stubDirectives,
    articleRelativeLinkHandlerMixin,
    articleEventLinkHandlerMixin,
    projectUrlResolverMixin,
    articleEmitLinkClickedHandlerMixin
  ],
  props: {
    styleVariant: {
      type: String,
      default: null
    },
    textContent: {
      type: Object,
      default: () => {}
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    halfMargin: {
      type: Boolean,
      default: false
    },
    highlightText: {
      type: Boolean,
      default: false
    },
    whiteText: {
      type: Boolean,
      default: false
    },
    whiteTextLink: {
      type: Boolean,
      default: false
    },
    blackTextLink: {
      type: Boolean,
      default: false
    },
    redText: {
      type: Boolean,
      default: false
    },
    smallHeading: {
      type: Boolean,
      default: false
    },
    orangeText: {
      type: Boolean,
      default: false
    },
    linkClickTracking: {
      type: [Array, Object],
      default: null
    },
    variableSet: {
      type: Object,
      default: () => ({})
    },
    useGreenListMarker: {
      type: Boolean,
      default: null
    },
    useBlokSchema: {
      type: Boolean,
      default: false
    },
    noMarginBottom: {
      type: Boolean,
      default: false
    },
    lineHeightVariant: {
      type: String,
      validator(value) {
        return ["small", "medium", "large", ""].includes(value)
      }
    },
    /**
      Changelog:
      2:
        - When a <p> wraps children which all have same class, this <p> will also receive this class
          This is to mitigate issue when Storyblok editor often wraps elements in <p>, but does not allow to set class on it
        - Applies css classes on links generated by emitEvent blocks
    */
    version: {
      type: String,
      validator(value) {
        return ["", "1", "2"].includes(value)
      }
    }
  },
  computed: {
    versionNo() {
      return Number(this.version || "1")
    },
    v2Enabled() {
      return this.versionNo > 1
    },
    isHtmlGenerated() {
      return !!this.textContent
    },
    generatedHtml() {
      let html = ""

      if (this.isHtmlGenerated && this.textContent.content) {
        const resolver = new RichTextResolver()
        resolver.addNode(
          "paragraph",
          this.v2Enabled
            ? paragraphSchemaApplyClassToWrapperEl
            : paragraphSchema
        )
        resolver.addNode("heading", headingSchema)
        // TODO: we should not rely on variableSet for this condition
        if (Object.keys(this.variableSet).length || this.useBlokSchema) {
          resolver.addNode("blok", this.blokSchema)
        }
        html = resolver.render(deepClone(this.textContent))
      }
      return html
    }
  },
  watch: {
    textContent: {
      immediate: true,
      handler(document) {
        const documentReady = document && Object.keys(document).length

        if (documentReady) {
          this.$nextTick(() => {
            this.setupRelativeLinkListener()
            this.setupEventLinkListener()
            this.setupEmitLinkClickedListener()
          })
        }
      }
    }
  },
  [process.env.VUE_APP_VERSION === "3" ? "unmounted" : "destroyed"]() {
    this.detachRelativeLinkListeners()
    this.detachEventLinkListeners()
    this.detachEmitLinkClickedListeners()
  },
  methods: {
    blokSchema(node) {
      let tag = `p`
      let attrs = {}
      // if the condition fails, the blok wont be resolved, so the output is nothing
      if (
        node.attrs &&
        node.attrs.body &&
        node.attrs.body[0] &&
        node.attrs.body[0].variable
      ) {
        let selectedValue = node.attrs.body[0].variable
        let mappedValue = this.variableSet[selectedValue]

        // add styles
        let marks = []
        if (node.attrs.body[0].bold) marks.push({ type: "bold" })
        if (node.attrs.body[0].italic) marks.push({ type: "italic" })
        if (node.attrs.body[0].underline) marks.push({ type: "underline" })
        if (node.attrs.body[0].inline) tag = `span`
        if (node.attrs.body[0].heading)
          attrs.class = node.attrs.body[0].headingClass

        node.content = [{ text: mappedValue, type: "text", marks }]
        return {
          tag: [
            {
              tag: tag,
              attrs: attrs
            }
          ]
        }
      } else if (
        node.attrs &&
        node.attrs.body &&
        node.attrs.body[0] &&
        node.attrs.body[0].link
      ) {
        let linkData = {}
        if (this.variableSet && this.variableSet.token) {
          linkData = {
            token: this.variableSet.token,
            account_id: this.variableSet.account_id
          }
        }
        let queryParameters = []
        if (
          node.attrs.body[0].queryParameters &&
          node.attrs.body[0].queryParameters.length
        ) {
          queryParameters = node.attrs.body[0].queryParameters
        }
        let resolvedLink = this.resolveLink({
          link: node.attrs.body[0].link,
          linkType: node.attrs.body[0].component,
          linkData,
          queryParameters
        })
        let marks = []
        marks.push({
          type: "link",
          attrs: {
            href: resolvedLink,
            target: "_self"
          }
        })
        node.content = [{ text: node.attrs.body[0].name, type: "text", marks }]
        attrs.class = "display-inline"
        return {
          tag: [
            {
              tag: "p",
              attrs: attrs
            }
          ]
        }
      } else if (
        node.attrs &&
        node.attrs.body &&
        node.attrs.body[0] &&
        node.attrs.body[0].emitEvent
      ) {
        let marks = []
        marks.push({
          type: "link",
          attrs: {
            href: "javascript:;",
            "data-event": node.attrs.body[0].emitEvent
          }
        })
        node.content = [{ text: node.attrs.body[0].text, type: "text", marks }]
        attrs.class = "display-inline"
        if (this.v2Enabled && node.attrs.body[0].classes) {
          attrs.class = `${node.attrs.body[0].classes} display-inline`
        }

        return {
          tag: [
            {
              tag: "p",
              attrs: attrs
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../stylesheets/components/_list.scss";

.article {
  @extend .generic-list;

  &--h4 {
    line-height: $line-height-1;
    font-weight: $weight-medium;
    font-size: $font-size-6;
    @media (max-width: #{$screen-md-min - 1}) {
      font-size: $font-size-5;
    }
  }

  &--font-weight-medium {
    font-weight: $weight-medium;
  }

  &--small-heading {
    font-size: $size-3;
    @include sm {
      font-size: 4.3rem;
    }
  }

  &--title-size-2-responsive {
    font-size: $font-size-6;
  }
  &--title-size-2-responsive {
    @include lg {
      font-size: $font-size-8;
    }
  }

  &--text,
  &--small-text,
  &--text-size-2,
  &--text-size-2-responsive {
    font-size: $font-size-2;
  }
  &--text-size-2-responsive {
    @include lg {
      font-size: $font-size-3;
    }
  }

  &--extra-small-text,
  &--text-size-1,
  &--text-size-1-responsive {
    font-size: $font-size-1;
  }

  &--text-size-1-responsive {
    @include lg {
      font-size: $font-size-2;
    }
  }

  &--small-text,
  &--text-size-2,
  &--text-size-2-responsive {
    font-size: $font-size-2;
  }
  &--text-size-2-responsive {
    @include lg {
      font-size: $font-size-3;
    }
  }

  &--text-size-3,
  &--text-size-3-responsive {
    font-size: $font-size-3;
  }

  &--text-size-3-responsive {
    @include lg {
      font-size: $font-size-4;
    }
  }

  &--text-size-4,
  &--text-size-4-responsive {
    font-size: $font-size-4;
  }

  &--text-size-4-responsive {
    @include lg {
      font-size: $font-size-5;
    }
  }

  &--text-size-5 {
    font-size: $font-size-5;
  }

  &--text-size-6 {
    font-size: $font-size-6;
  }

  &--text-size-10,
  &--text-size-10-responsive {
    font-size: $font-size-10;
  }

  &--text-size-10-responsive {
    @include lg {
      font-size: 132px;
    }
  }

  &--line-height-small {
    line-height: $line-height-1;
  }

  &--line-height-medium {
    line-height: $line-height-2;
  }

  &--line-height-large {
    line-height: $line-height-3;
  }

  &--light-blue-link a {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $water-light;
    }
  }

  b {
    font-weight: $weight-medium;
  }

  p {
    margin-bottom: $spacing-6;
  }

  .display-inline {
    display: inline;
  }

  .no-wrap {
    white-space: nowrap;
  }

  &--no-margin p:last-of-type,
  &--no-margin > div > :last-child,
  .no-margin {
    margin-bottom: 0;
  }

  &--half-margin p:last-of-type,
  &--half-margin > div > :last-child,
  .half-margin {
    margin-bottom: $spacing-3;
  }

  &--no-margin-bottom p,
  .no-margin-bottom {
    margin-bottom: 0;
  }

  .letter-spacing {
    letter-spacing: -2px;
  }

  h5 {
    font-weight: $weight-medium;
    &.half-margin {
      margin-bottom: $spacing-2;
    }
  }

  hr {
    border-style: inset;
    border-width: 1px;
    border-color: $night;
    margin: $spacing-7 0;
  }

  blockquote {
    font-size: $size-5;
  }

  ol {
    counter-reset: item;
    margin-top: 0;
    margin-left: 0;

    ol {
      margin-left: $spacing-5;

      @include lg {
        margin-top: $spacing-2;
        margin-left: $spacing-7;
      }
    }

    li {
      list-style: none;
      margin-left: $spacing-2;

      p {
        display: inline-block;
      }
    }
    li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      margin-right: $spacing-2;
      letter-spacing: 3px;

      @include lg {
        margin-right: $spacing-4;
      }
    }
  }

  &--green-list-marker {
    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      margin-bottom: $spacing-3;

      &::before,
      span,
      p {
        display: inline;
        vertical-align: middle;
      }

      &::before {
        @include font-icon($icon-check);
        margin-right: $spacing-3;
        font-size: $size-6;
        color: $earth-dark;
      }
    }
  }
  &--highlight-text {
    background-color: $sun;
  }
  &--white-text {
    color: $white;
  }
  &--white-text-link {
    color: $white;
    text-decoration: underline;
  }
  &--black-text-link {
    color: $black;
    text-decoration: underline;
  }
  &--red-text {
    color: $red-400;
  }
  &--orange-text {
    color: $sun;
  }
}
</style>
