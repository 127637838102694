const isObject = item =>
  item !== null && !(item instanceof Array) && typeof item === "object"

function removeHiddenItems(list) {
  let hiddenRemoved = list.filter(dataItem => {
    return !dataItem.hidden || dataItem.hidden === undefined
  })

  return hiddenRemoved
}

function filterData(data) {
  for (let dataItem in data) {
    if (!data.hasOwnProperty(data[dataItem]) && Array.isArray(data[dataItem])) {
      data[dataItem] = removeHiddenItems(data[dataItem])
      data[dataItem].forEach(dataItem => {
        if (isObject(dataItem)) {
          dataItem = filterData(dataItem)
        }
      })
    } else if (typeof data[dataItem] === "object") {
      data[dataItem] = filterData(data[dataItem])
    }
  }
  return data
}

module.exports = function(data) {
  data = filterData(data)

  return data
}
