import { createRouter, createWebHistory } from "vue-router"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import store from "../store/store"
import authenticateGuard from "./navigation-guards/authenticateGuard"
import Home from "../views/Home.vue"
import NotFound from "../views/NotFound.vue"
import SmartMeterBooking from "../views/SmartMeterBooking.vue"
import ChooseAppointment from "../views/ChooseAppointment.vue"
import AtHome from "../views/AtHome.vue"
import EditContact from "../views/EditContact.vue"
import ConfirmDetails from "../views/ConfirmDetails.vue"
import BookingConfirmed from "../views/BookingConfirmed.vue"
import BookingCancelled from "../views/BookingCancelled.vue"
import CancelBooking from "../views/CancelBooking.vue"
import AppointmentDetails from "../views/AppointmentDetails.vue"
import RescheduleAppointment from "../views/RescheduleAppointment.vue"

const createBookingRoutes = (bookingType) => [
  {
    path: "book-appointment",
    name: `${bookingType}-book-appointment`,
    component: ChooseAppointment,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "contact-details",
    name: `${bookingType}-contact-details`,
    component: AtHome,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "confirm-details",
    name: `${bookingType}-confirm-details`,
    component: ConfirmDetails,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
]

const routes = [
  {
    path: "/",
    redirect: {
      name: "Home",
    },
  },
  {
    path: "/install",
    name: "Home",
    component: Home,
    meta: {
      isAccountIdRoute: true,
      appointmentType: "install",
    },
  },
  {
    path: "/smart-meter-appointment",
    redirect: {
      name: "install-book-appointment",
    },
  },
  {
    path: "/install",
    name: "install",
    component: SmartMeterBooking,
    children: createBookingRoutes("install"),
    meta: {
      appointmentType: "install",
    },
  },
  {
    path: "/repair",
    name: "repair",
    component: SmartMeterBooking,
    redirect: {
      name: "repair-book-appointment",
    },
    children: createBookingRoutes("repair"),
    meta: {
      appointmentType: "repair",
    },
  },
  {
    path: "/edit-contact",
    name: "edit-contact",
    component: EditContact,
    props: true,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "/booking-confirmed",
    name: "booking-confirmed",
    component: BookingConfirmed,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "/booking-cancelled",
    name: "booking-cancelled",
    component: BookingCancelled,
    meta: {
      authenticate: true,
    },
  },
  {
    path: "/cancel-booking",
    name: "cancel-booking",
    component: CancelBooking,
    props: true,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "/appointment-details",
    name: "appointment-details",
    component: AppointmentDetails,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "/reschedule-appointment",
    name: "reschedule-appointment",
    component: RescheduleAppointment,
    props: true,
    meta: {
      authenticate: true,
      isAccountIdRoute: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.isAccountIdRoute) {
    store.commit("UPDATE_LOADING", true)
    const isAuthenticated =
      await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()
    store.commit("SET_AUTHENTICATED", isAuthenticated)
    store.commit("SET_AUTH_ERROR", !isAuthenticated)

    const isStorePopulated = store.state.junifer && store.state.accounts
    if (isAuthenticated && !isStorePopulated) {
      const isInstall = to.meta.appointmentType === "install"

      await store.dispatch("getAccountId")
      await store.dispatch("fetchJuniferData")
      await store.dispatch("getAppointmentEligibility", isInstall)
    }
    store.commit("UPDATE_LOADING", false)
  }
  next()
})

router.beforeEach(authenticateGuard)

export default router
