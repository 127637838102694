<template>
  <div class="error">
    <div class="container">
      <i class="icon icon-logo-so-energy"></i>
      <h1 v-if="layoutSettings.header" class="error__heading h4">
        {{ layoutSettings.header }}
      </h1>
      <p v-if="layoutSettings.copy" class="error__text">
        {{ layoutSettings.copy }}
      </p>
      <AppButton
        v-if="button"
        :href="button.path"
        class="error__button"
        variant="secondary"
        @click.native="$emit('navigated-away')"
      >
        {{ button.label }}
      </AppButton>
      <AppButton
        v-else
        to="/"
        class="error__button"
        variant="secondary"
        @click.native="$emit('navigated-away')"
      >
        Go to Homepage
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "./AppButton"

export default {
  components: {
    AppButton
  },
  props: {
    variant: {
      type: String,
      validator: val =>
        ["generic", "notFoundError", "networkError"].includes(val),
      required: true
    },
    button: {
      type: Object,
      default: null
    }
  },
  computed: {
    layoutSettings() {
      return this.layoutConfigMap[this.variant]
    },
    layoutConfigMap() {
      return {
        generic: {
          header: "An error occurred"
        },
        notFoundError: {
          header: "The page you were looking for doesn’t exist.",
          copy: "You may have mistyped the address or the page may have moved."
        },
        networkError: {
          header: "Uh oh, looks like there’s no internet!",
          copy: "Please check your connection and try again."
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  background-color: $fire;
  padding: $spacing-9 $section-horizontal-padding 3rem;
  text-align: center;

  @include lg {
    padding-bottom: $spacing-9;
  }

  &__heading {
    text-transform: none;
  }

  &__text {
    max-width: 484px;
    margin: 0 auto $spacing-6;

    @include lg {
      margin: 0 auto $spacing-5;
    }
  }

  .icon-logo-so-energy {
    transform: rotate(180deg);
    font-size: 160px;
    margin-bottom: $spacing-8;

    @include lg {
      font-size: 180px;
    }
  }
}
</style>
