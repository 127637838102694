import AuthenticationService from "../../AuthenticationService"

export default async request => {
  const { skipAuthentication } = request

  if (skipAuthentication) {
    return request
  }

  const token = await AuthenticationService.getAccessTokenAndRenewIfNeeded()
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
}
