<template>
  <section class="smart-meter">
    <StepWizard
      v-model="currentStepNumber"
      :steps="steps"
      show-progress-bar
      @returned="$router.push({ path: '/' })"
      @completed="bookAppointment"
    />
  </section>
</template>

<script>
import StepWizard from "@soenergy/frontend-library/src/components/StepWizard.vue"

export default {
  components: {
    StepWizard,
  },
  data() {
    return {
      currentStepNumber: null,
    }
  },
  computed: {
    steps() {
      return [
        {
          routeName: `${this.appointmentType}-book-appointment`,
        },
        {
          routeName: `${this.appointmentType}-contact-details`,
        },
        {
          routeName: `${this.appointmentType}-confirm-details`,
        },
      ]
    },
    appointmentType() {
      return this.$route.meta.appointmentType
    },
  },
  methods: {
    bookAppointment() {
      this.$store.dispatch("bookAppointment", this.appointmentType).then(() => {
        if (this.$store.state.submitStatus === "SUCCESS") {
          this.$router.push({ path: "/booking-confirmed" })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.smart-meter {
  align-items: center;
  margin: auto;
  padding: 0 $space-4;

  @include md {
    width: $width-5;
  }
}
::v-deep .step-wizard__back-link {
  margin-bottom: $space-4;

  @include md {
    margin-bottom: $space-8;
  }
}
</style>
