<template>
  <section
    class="section"
    :class="[
      { 'section--no-padding-desktop': mediaFillContainer },
      { 'responsive-bg-image': !!responsiveBackgroundImage }
    ]"
    :style="sectionStyle"
    :role="altText && 'img'"
    :aria-label="altText"
  >
    <slot />
  </section>
</template>

<script>
const BREAKPOINTS = ["xs", "sm", "md", "lg", "xl", "xxl"]

export default {
  props: {
    fontColor: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    backgroundImage: {
      type: String,
      default: ""
    },
    isBackgroundCentred: {
      type: Boolean,
      default: false
    },
    responsiveBackgroundImage: {
      type: Object,
      default: null
    },
    deferMediaLoading: {
      type: Boolean,
      default: false
    },
    mediaFillContainer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canLoadBackground: !this.deferMediaLoading
    }
  },
  computed: {
    sectionStyle() {
      const style = {}

      if (this.fontColor) {
        style.color = this.fontColor
      }
      if (this.backgroundColor) {
        style.backgroundColor = this.backgroundColor
      }
      if (this.canLoadBackground) {
        if (this.responsiveBackgroundImage) {
          BREAKPOINTS.forEach((breakpoint, index) => {
            const { image, position } = this.getImageVariantForBreakpoint(index)
            style[`--bg-image-position-${breakpoint}`] = position
            style[`--bg-image-src-${breakpoint}`] = `url('${image.filename}')`
          })
        } else {
          if (this.backgroundImage) {
            style.backgroundImage = `url(${this.backgroundImage})`
          }
          if (this.isBackgroundCentred) {
            style.backgroundPositionY = "center"
          }
        }
      }
      return style
    },
    altText() {
      return this.responsiveBackgroundImage
        ? this.responsiveBackgroundImage.alt
        : null
    }
  },
  mounted() {
    this.canLoadBackground = true
  },
  methods: {
    getImageVariantForBreakpoint(index) {
      const variant = this.responsiveBackgroundImage[BREAKPOINTS[index]]
      if (variant && variant.length) {
        return variant[0]
      }
      // Find closest available fallback
      return index > 0 ? this.getImageVariantForBreakpoint(index - 1) : null
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  &--no-padding-desktop {
    @include md {
      padding: 0;
    }
  }
}
.responsive-bg-image {
  background-size: cover;

  @mixin responsive-image($breakpoint) {
    background-image: var(--bg-image-src-#{$breakpoint});
    background-position: var(--bg-image-position-#{$breakpoint});
  }

  @include responsive-image("xs");

  @include sm {
    @include responsive-image("sm");
  }
  @include md {
    @include responsive-image("md");
  }
  @include lg {
    @include responsive-image("lg");
  }
  @include xl {
    @include responsive-image("xl");
  }
  @include xxl {
    @include responsive-image("xxl");
  }
}
</style>
