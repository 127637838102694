<template>
  <div
    class="generic-image-layout"
    :class="imageLayoutClasses"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div class="container">
      <div class="columns">
        <section
          class="generic-image-layout__left-column column"
          :class="leftColumnClasses"
        >
          <div v-if="heading" class="generic-image-layout__heading-content">
            <i class="icon icon-check"></i>
            <h1>{{ heading }}</h1>
            <div class="generic-image-layout__heading-bg"></div>
          </div>
          <slot />
        </section>
        <section class="generic-image-layout__right-column column is-6-desktop">
          <div
            class="generic-image-layout__hero-img"
            :style="rightSectionStyle"
          ></div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: false,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: null
    },
    imageSrc: {
      type: String,
      required: true
    },
    mobileImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rightSectionStyle() {
      if (this.imageSrc) {
        return {
          backgroundImage: `url(${this.imageSrc})`
        }
      }
      return null
    },
    imageLayoutClasses() {
      return {
        "generic-image-layout": true,
        "generic-image-layout__mobile-image-visible": this.mobileImage
      }
    },
    leftColumnClasses() {
      return {
        "is-12-tablet is-6-desktop": !this.mobileImage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.generic-image-layout {
  overflow: hidden;

  .columns {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }

  &__left-column {
    padding-top: 0;

    @include lg {
      min-height: 630px;
      padding-right: 0;
    }
  }

  &__heading-content {
    text-align: center;
    position: relative;
    height: 150px;
    padding-top: $spacing-5;

    @include lg {
      text-align: left;
      padding-top: 3.5rem;
      height: 176px;
    }

    h1,
    .icon-check {
      position: relative;
      z-index: 1;

      @include lg {
        font-size: $size-2;
      }

      @include xl {
        font-size: $size-1;
      }
    }

    h1 {
      @include lg {
        display: inline-block;
      }
    }

    .icon-check {
      border: 2px solid black;
      border-radius: 50%;
      padding: 4px;
      font-size: 1.25rem;
      margin-bottom: $spacing-4;
      font-weight: $weight-bold;

      @include lg {
        font-size: $size-5;
        padding: 7px;
        margin-bottom: 0;
        margin-right: $spacing-5;
        vertical-align: text-bottom;
      }

      @include xl {
        margin-right: $spacing-7;
        padding: 12px;
      }
    }
  }

  &__heading-bg {
    background-color: $earth;
    height: 150px;
    width: 2000px;
    position: absolute;
    top: 0;
    right: 0;

    @include lg {
      height: 176px;
    }
  }

  &__right-column {
    position: relative;
    display: none;

    @include lg {
      display: block;
    }
  }

  &__hero-img {
    height: 100%;
    width: 600px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: top center;

    @include xl {
      width: 800px;
    }

    @include xxl {
      width: 900px;
    }

    @media (min-width: 1800px) {
      width: 50vw;
    }
  }

  &__mobile-image-visible {
    .generic-image-layout__left-column {
      order: 2;
      @include md {
        order: 1;
      }
    }
    .generic-image-layout__right-column {
      min-height: 400px;
      display: block;
      order: 1;
      @include md {
        order: 2;
      }

      .generic-image-layout__hero-img {
        width: 100%;

        @include md {
          width: 50vw;
        }
      }
    }
  }
}
</style>
