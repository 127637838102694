import Deferred from "../../helpers/Deferred"
import loadScriptFromUrl from "../../helpers/loadScriptFromUrl"
import { isInPreviewMode, isInStoryblokEditor, importAsync } from "./utils"
import ConvertBlockArraysToObjects from "../../services/StoryblokFilter/ConvertBlockArraysToObjects"
import applyStoryblokMappers from "../../services/StoryblokFilter/applyStoryblokMappers"

const loadStoryblokApi = importAsync(() =>
  import("../../services/StoryblokApi")
)

const loadStoryblokBridge = () =>
  loadScriptFromUrl(
    `https://app.storyblok.com/f/storyblok-latest.js?t=` +
      process.env.VUE_APP_STORYBLOK_TOKEN
  )

const cmsBlockToProps = blockArray => {
  const block = blockArray && blockArray[0]
  if (block) {
    const { _uid, component, ...props } = block
    return props
  }
  return null
}

const StoryblokHelper = function({ deferLoading }) {
  let storyblokApi = null
  const initialiser = new Deferred()
  const loadingInProgress = initialiser.promise

  this.loadDependencies = async () => {
    if (isInPreviewMode()) {
      ;[storyblokApi] = await Promise.all([
        loadStoryblokApi(),
        loadStoryblokBridge()
      ])
      initialiser.resolve()
    }
  }

  this.getContentUpdates = async (story, contentUpdatedCallback) => {
    if (isInPreviewMode()) {
      await loadingInProgress
      const response = await storyblokApi.fetchStory(story.full_slug, {
        version: "draft"
      })

      const content = response ?? {}
      const { content: updatedContent } = applyStoryblokMappers.applyMappers(
        content,
        story.cmsVersion
      )

      contentUpdatedCallback(updatedContent)

      storyblok.on(["input", "change"], ({ story: updatedStory }) => {
        if (story.full_slug === updatedStory.full_slug) {
          const mappedUpdatedContent = applyStoryblokMappers.applyMappers(
            updatedStory,
            story.cmsVersion
          )
          contentUpdatedCallback(mappedUpdatedContent)
        }
      })
    }
  }

  this.isInStoryblokEditor = isInStoryblokEditor

  // Fetching dependencies early, to reduce the content loading time in development
  if (!deferLoading) {
    this.loadDependencies()
  }
}
export { StoryblokHelper, cmsBlockToProps }
