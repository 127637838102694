<template>
  <div v-if="pageContent">
    <HeroBannerGeneral
      v-if="heroBanner"
      :responsive-background-image="heroBanner.responsiveBackgroundImage"
    />
    <div class="booking-cancelled">
      <h3 class="booking-cancelled__header">{{ pageContent.header }}</h3>
      <Article :text-content="cancelText" />
      <NavButton
        v-if="pageContent.button"
        v-bind="cmsBlockToProps(pageContent.button)"
      />
    </div>
  </div>
</template>

<script>
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import bookingCancelled from "soenergy-cms-loader!?path=smbp/booking-cancelled"
import HeroBannerGeneral from "@soenergy/frontend-library/src/components/HeroBannerGeneral.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import NavButton from "@soenergy/frontend-library/src/components/NavButton.vue"

export default {
  components: {
    HeroBannerGeneral,
    Article,
    NavButton,
  },
  mixins: [cmsPreviewMixin({ story: bookingCancelled })],
  computed: {
    heroBanner() {
      return this.pageContent.heroBanner[0]
    },
    cancelText() {
      return this.pageContent?.cancelText?.[0]?.textContent || null
    },
  },
}
</script>

<style lang="scss" scoped>
.booking-cancelled {
  margin: $space-8 $space-4;
  @include md {
    margin: $space-8 auto;
    width: $width-6;
  }

  &__header {
    margin-top: $space-8;
  }
}
</style>
