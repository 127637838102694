const renewLongLivedSessionAfterMs = 24 * 60 * 60 * 1000
const renewShortLivedSessionAfterPercent = 0.25
const interactionEvents = ["mousemove", "mousedown", "keydown", "touchmove"]

let earliestRenewalTimeInMs = null
let isInteractionListenerAttached = false
let currentSessionExpiryTime = null
let isCurrentSessionLongLived = null
let isRenewalInProgress = false
let renewSession = null

const calculateEarliestRenewalTime = () => {
  const now = Date.now()
  let earliestRenewalTime = null

  if (isCurrentSessionLongLived) {
    earliestRenewalTime = new Date(now + renewLongLivedSessionAfterMs).getTime()
  } else {
    const sessionDurationInMs = currentSessionExpiryTime.getTime() - now
    earliestRenewalTime =
      now + sessionDurationInMs * renewShortLivedSessionAfterPercent
  }
  return earliestRenewalTime
}

const renewSessionIfExpiringSoon = async () => {
  const now = Date.now()
  const isDueForRenewal = !isRenewalInProgress && earliestRenewalTimeInMs < now

  if (isDueForRenewal) {
    isRenewalInProgress = true
    try {
      await renewSession()
    } catch (error) {
      disableSessionExtensionOnUserInteraction()
      throw error
    } finally {
      earliestRenewalTimeInMs = calculateEarliestRenewalTime()
      isRenewalInProgress = false
    }
  }
}

const setupUserInteractionListeners = () =>
  interactionEvents.forEach(event =>
    document.addEventListener(event, renewSessionIfExpiringSoon)
  )

export const extendSessionOnUserInteraction = ({
  sessionExpiryTime,
  isSessionLongLived,
  renewSessionCallback
}) => {
  renewSession = renewSessionCallback
  currentSessionExpiryTime = sessionExpiryTime
  isCurrentSessionLongLived = isSessionLongLived
  earliestRenewalTimeInMs = calculateEarliestRenewalTime()

  if (!isInteractionListenerAttached) {
    setupUserInteractionListeners()
    isInteractionListenerAttached = true
  }
}

export const disableSessionExtensionOnUserInteraction = () => {
  interactionEvents.forEach(event =>
    document.removeEventListener(event, renewSessionIfExpiringSoon)
  )
  isInteractionListenerAttached = false
}
