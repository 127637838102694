<template>
  <div v-if="pageContent" class="edit-contact">
    <ProgressBar :steps="steps" :current-step-number="currentStep" />
    <div class="edit-contact__wrapper">
      <BackLink class="edit-contact__back-link" />
      <p class="edit-contact__title">
        Step {{ currentStep + 1 }}: {{ pageContent.stepTitle }}
      </p>
      <Article v-bind="cmsBlockToProps(pageContent.contactAtHomeContent)" />
      <InputField
        v-model="contact.firstName"
        :has-error="v$.contact.firstName.$error"
        :error-message="
          v$.contact.firstName.$error && pageContent.firstNameInputErrorMessage
        "
        name="firstName"
        label="First Name"
        @blur="v$.contact.firstName.$touch()"
      />
      <InputField
        v-model="contact.lastName"
        :has-error="v$.contact.lastName.$error"
        :error-message="
          v$.contact.lastName.$error && pageContent.lastNameInputErrorMessage
        "
        name="lastName"
        label="Last Name"
        @blur="v$.contact.lastName.$touch()"
      />
      <InputField
        v-model="contact.telephone"
        :has-error="v$.contact.telephone.$error"
        :error-message="
          v$.contact.telephone.$error && pageContent.invalidPhoneNumber
        "
        name="Phone"
        label="Phone Number"
        @blur="v$.contact.telephone.$touch()"
      />
      <InputField
        v-model="contact.email"
        :has-error="v$.contact.email.$error"
        :error-message="
          v$.contact.email.$error && pageContent.emailInvalidMessage
        "
        name="Email"
        label="Email"
        @blur="v$.contact.email.$touch()"
      />
      <InputField
        v-model="contact.confirmEmail"
        :has-error="v$.contact.confirmEmail.$error"
        :error-message="
          v$.contact.confirmEmail.$error &&
          pageContent.confirmEmailInvalidMessage
        "
        name="Confirm Email"
        label="Confirm Email"
        @blur="v$.contact.confirmEmail.$touch()"
      />
      <CheckBox
        v-if="contactAtHome === 'new-contact'"
        v-model="contact.age"
        class="edit-contact__checkbox"
        input-id="contact-age"
        is-small
      >
        {{ pageContent.confirmAge }}
      </CheckBox>
      <AppButton
        v-if="saveButtonProps"
        v-track="saveButtonProps.tracking"
        class="edit-contact__next-button"
        :disabled="!validated"
        :variant="saveButtonProps.variant"
        @click="saveContact()"
      >
        <span> {{ saveButtonProps.label }} </span>
      </AppButton>
    </div>
  </div>
</template>

<script>
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import editContactContent from "soenergy-cms-loader!?path=smbp/edit-contact"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import CheckBox from "@soenergy/frontend-library/src/components/Checkbox.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import { mapState, mapActions } from "vuex"
import useVuelidate from "@vuelidate/core"
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators"
import BackLink from "@soenergy/frontend-library/src/components/BackLink.vue"
import ProgressBar from "@soenergy/frontend-library/src/components/ProgressBar.vue"

function emailMatchValidator(value, siblings) {
  return siblings.email.toLowerCase() === value.toLowerCase()
}

export default {
  components: {
    Article,
    InputField,
    CheckBox,
    AppButton,
    BackLink,
    ProgressBar,
  },
  mixins: [cmsPreviewMixin({ story: editContactContent })],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      contact: {},
    }
  },
  computed: {
    ...mapState({
      form: (state) => state.homeDuringAppointment.form,
      contactAtHome: (state) => state.homeDuringAppointment.contactAtHome,
    }),
    saveButtonProps() {
      return this.pageContent.saveButton && this.pageContent.saveButton[0]
        ? this.pageContent.saveButton[0]
        : null
    },
    validated() {
      return !this.v$.$invalid && this.contact.age
    },
    steps() {
      return Number(this.$route.query.steps)
    },
    currentStep() {
      return Number(this.$route.query.current) - 1
    },
  },
  validations: {
    contact: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      telephone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(11),
      },
      email: {
        required,
        email,
      },
      confirmEmail: {
        required,
        email,
        emailMatchValidator,
      },
      age: {
        required,
      },
    },
  },
  mounted() {
    this.contact = this.form
  },
  methods: {
    ...mapActions({
      updateCurrentContact: "updateCurrentContact",
      setSelectedContact: "setSelectedContact",
    }),
    saveContact() {
      const contactDetails = {
        contactId: this.contactAtHome,
        form: this.contact,
      }

      this.updateCurrentContact(contactDetails)
      this.setSelectedContact(this.contactAtHome)
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-contact {
  align-items: center;
  margin: auto;

  @include md {
    width: $width-5;
  }

  &__wrapper {
    padding: $space-10 $space-4;
  }

  &__back-link {
    margin-top: 0;
    margin-bottom: $space-4;
    margin-right: $space-4;

    @include lg {
      margin-bottom: $space-10;
    }
  }
  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
    margin-bottom: $space-4;

    @include md {
      font-size: $font-size-6;
    }
  }

  &__checkbox {
    margin-top: $space-6;
  }

  &__next-button {
    margin: $space-6 0;
  }
}
</style>
