import { runCallbacksForCookieCategory } from "./CookiePreferences"

const runOnConsentChange = callback => {
  window.addEventListener("CookiebotOnConsentReady", callback, false)
  window.addEventListener("CookiebotOnAccept", callback, false)
  window.addEventListener("CookiebotOnDecline", callback, false)
}

export const COOKIE_CATEGORY = {
  Functional: "functional",
  Performance: "performance",
  Advertising: "advertising"
}

export const getAcceptedCategories = () => {
  const consent = window.Cookiebot?.consent

  // Normalises Cookiebot's category names
  return {
    [COOKIE_CATEGORY.Functional]: consent?.preferences ?? false,
    [COOKIE_CATEGORY.Performance]: consent?.statistics ?? false,
    [COOKIE_CATEGORY.Advertising]: consent?.marketing ?? false
  }
}

export const openPreferencesModal = () => {
  window.Cookiebot?.renew()
}

export const init = () => {
  runOnConsentChange(() => {
    const acceptedCategories = getAcceptedCategories()

    if (acceptedCategories[COOKIE_CATEGORY.Functional]) {
      runCallbacksForCookieCategory(COOKIE_CATEGORY.Functional)
    }
    if (acceptedCategories[COOKIE_CATEGORY.Performance]) {
      runCallbacksForCookieCategory(COOKIE_CATEGORY.Performance)
    }
    if (acceptedCategories[COOKIE_CATEGORY.Advertising]) {
      runCallbacksForCookieCategory(COOKIE_CATEGORY.Advertising)
    }
  })
}
