import BookingApi from "@/services/api/BookingApi"

export const untestedActions = {
  async fetchBookingData({ commit, rootState }) {
    commit("SET_BOOKING_API_STATUS", "LOADING")

    try {
      const bookings = await BookingApi.getBookings(rootState.customerId)
      commit("SET_BOOKING_DATA", bookings)
      commit("SET_BOOKING_API_STATUS", "SUCCESS")
    } catch (error) {
      commit("SET_BOOKING_API_STATUS", "ERROR")
      throw error
    }
  },
}

export const untestedMutations = {
  SET_BOOKING_DATA(state, bookingData) {
    state.bookings = bookingData
  },
  SET_BOOKING_API_STATUS(state, status) {
    state.bookingApiStatus = status
  },
}

export const untestedGetters = {
  latestBooking(state) {
    const filteredBookings = state.bookings.filter(
      (booking) => booking.createdDate
    )
    if (!filteredBookings.length) return []
    return filteredBookings.reduce((latestBooking, currentBooking) => {
      return currentBooking.createdDate.isAfter(latestBooking.createdDate)
        ? currentBooking
        : latestBooking
    })
  },
  hasBookings(state) {
    return state.bookings?.length > 0
  },
}
