import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ARK_API,
  requestInterceptors: [authenticate],
})

export default {
  getContacts(accountId) {
    return apiClient.get(`/api/v1/accounts/${accountId}/contacts`)
  },
}
