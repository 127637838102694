<template>
  <div class="cookie-banner">
    <div class="cookie-banner__section">
      <div class="cookie-banner__container container">
        <Article
          v-if="bannerText"
          class="cookie-banner__text"
          :text-content="bannerText"
          no-margin
        />

        <div :class="{ 'cookie-banner__button-flex': hasManageCookies }">
          <div class="cookie-banner__button-group-container">
            <AppButton
              class="cookie-banner__button"
              :variant="acceptAllButton.variant"
              @click="$emit('acceptAllCookies')"
            >
              {{ acceptAllButton.label }}
            </AppButton>
          </div>
          <div class="cookie-banner__button-group-container">
            <AppButton
              v-if="hasManageCookies"
              class="cookie-banner__button"
              :variant="manageCookiesButton.variant"
              @click="$emit('openManageCookiesModal')"
            >
              {{ manageCookiesButton.label }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "../AppButton"
import Article from "../Article"

export default {
  name: "CookieBanner",
  components: {
    AppButton,
    Article
  },
  props: {
    hasManageCookies: {
      type: Boolean,
      default: false
    },
    bannerText: {
      type: Object,
      default: () => {}
    },
    acceptAllButton: {
      type: Object,
      default: () => {}
    },
    manageCookiesButton: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $day-dark;
  color: $night;
  z-index: zindex(modal);
  box-shadow: 0px -3px 6px 0px rgba($night, 0.2);

  &__section {
    padding: $spacing-4;
    @include lg {
      padding: $spacing-5 0;
    }
  }

  &__text {
    margin-bottom: $spacing-4;
    font-size: $size-7;
    max-width: 850px;

    @include lg {
      margin-bottom: 0;
    }
  }

  &__button-group-container {
    @include lg {
      margin-left: $spacing-4;
    }

    &:first-of-type {
      margin-bottom: $spacing-2;

      @include lg {
        margin-bottom: 0;
        margin-left: $spacing-6;
      }

      @include xl {
        margin-left: 3.5rem;
      }
    }
  }

  &__button {
    width: 100%;
  }

  &__container,
  &__button-flex {
    @include lg {
      display: flex;
    }
  }

  &__button-flex {
    max-width: 342px;
    margin: auto;

    @include lg {
      max-width: unset;
    }
  }

  &__container {
    @include lg {
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
