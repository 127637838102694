<template>
  <AppLink
    :class="[backLinkClass, fontColor]"
    :link="link"
    :href="link ? link : 'javascript:;'"
    @click.native="backTo"
    @keydown.enter="backTo"
    @keydown.space.prevent="backTo"
  >
    <i class="icon icon-arrow-left back-button__icon"></i>
    <span class="back-button__label">{{ buttonLabel }}</span>
  </AppLink>
</template>

<script>
import AppLink from "./AppLink.vue"
export default {
  components: {
    AppLink
  },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    fontColor: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    disableRouterBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonLabel: this.label || "Back"
    }
  },
  computed: {
    backLinkClass() {
      return {
        "back-button": true,
        "is-small": this.small
      }
    }
  },
  methods: {
    backTo(e) {
      this.$emit("goBackTo")
      if (!this.link && !this.disableRouterBack) {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  display: flex;
  align-items: center;
  margin-top: $space-4;
  border: none;
  background: transparent;
  width: fit-content;
  color: $black;
  font-size: $font-size-4;
  text-decoration: none;

  &__icon {
    font-weight: $weight-medium;
  }
  &__label {
    padding-left: $space-1;
    font-weight: $weight-medium;
    padding-bottom: 2px;
  }

  &:hover {
    color: $blue-600;
  }

  &:focus {
    outline: 0;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 2px $yellow-400;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }

  &.is-small {
    font-size: $font-size-2;
  }
}

.day {
  color: $day;
}
</style>
