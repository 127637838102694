import isVue3 from "../helpers/isVue3"

const stubDirectiveIfNotRegistered = (vm, directive) => {
  let globalRegister
  let localRegister

  if (isVue3()) {
    globalRegister = vm.$.appContext.directives
    localRegister =
      vm.$.root && vm.$.root.appContext && vm.$.root.appContext.directives
  } else {
    globalRegister = vm.$options && vm.$options.directives
    localRegister = vm.$root.options && vm.$root.options.directives
  }

  const registeredGlobally = globalRegister && directive in globalRegister
  const registeredLocally = localRegister && directive in localRegister

  if (!registeredGlobally && !registeredLocally) {
    // Registers a stub directive locally
    globalRegister[directive] = { bind: () => {} }
  }
}

export default {
  created() {
    stubDirectiveIfNotRegistered(this, "track")
    stubDirectiveIfNotRegistered(this, "track-click-group")
    stubDirectiveIfNotRegistered(this, "editable")
  }
}
