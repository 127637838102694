import { PROJECTS, PROJECT_URLS } from "../config/projectRouting"
import { isAbsoluteUrl, removeTrailingSlash } from "../helpers/urlHelper"

export const LINK_TO_PROJECT_MAP = {
  NavItemWebsite: PROJECTS.Website,
  NavItemAccount: PROJECTS.Account,
  NavItemHelp: PROJECTS.Help,
  NavItemAccountApp: PROJECTS.AccountApp,
  NavItemGengame: PROJECTS.Gengame,
  NavItemWhd: PROJECTS.Whd,
  NavItemSmbp: PROJECTS.Smbp,
  NavItemSolar: PROJECTS.Solar,
  NavItemEv: PROJECTS.Ev
}

const getTargetProjectByLinkType = linkType => LINK_TO_PROJECT_MAP[linkType]

export default {
  inject: {
    currentProject: {
      default: PROJECTS.Website
    }
  },
  methods: {
    resolveLink({
      link,
      linkType,
      linkData,
      targetProject: providedTargetProject,
      queryParameters,
      includeBaseUrl
    }) {
      const targetProject =
        providedTargetProject || getTargetProjectByLinkType(linkType)
      let resolvedLink = link

      if (
        link &&
        targetProject &&
        !isAbsoluteUrl(link) &&
        targetProject !== this.currentProject
      ) {
        resolvedLink = PROJECT_URLS[targetProject] + link
      }
      if (
        (targetProject === PROJECTS.Smbp || targetProject === PROJECTS.Whd) &&
        linkData &&
        Object.keys(linkData).length
      ) {
        let url = new URL(resolvedLink)
        for (const param in linkData) {
          url.searchParams.set(param, linkData[param])
        }
        resolvedLink = url.href
      }
      if (queryParameters && queryParameters.length) {
        let url = new URL(resolvedLink)

        queryParameters.forEach(param => {
          url.searchParams.set(param.key, param.value)
        })

        resolvedLink = url.href
      }
      if (includeBaseUrl) {
        resolvedLink = this.includeBaseUrlIfNeeded({
          resolvedLink,
          targetProject
        })
      }
      return resolvedLink
    },
    includeBaseUrlIfNeeded({ resolvedLink, targetProject }) {
      const baseUrl = process.env.BASE_URL
      const baseUrlNormalised = baseUrl ? removeTrailingSlash(baseUrl) : ""

      if (
        !isAbsoluteUrl(resolvedLink) &&
        targetProject === this.currentProject &&
        !resolvedLink.startsWith(baseUrlNormalised)
      ) {
        resolvedLink = baseUrlNormalised + resolvedLink
      }
      return resolvedLink
    },
    browsingContext({ link, linkType }) {
      const websiteUrl = PROJECT_URLS[PROJECTS.Website]
      const pointsToWebsiteUrl = link?.startsWith(websiteUrl)
      if (
        isAbsoluteUrl(link) &&
        !getTargetProjectByLinkType(linkType) &&
        !pointsToWebsiteUrl
      ) {
        return "_blank"
      }
      return "_self"
    }
  }
}
