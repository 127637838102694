export const PROJECTS = {
  Website: "fe-website",
  Account: "account",
  Help: "help",
  AccountApp: "fe-myaccount",
  Gengame: "fe-gengame",
  Whd: "fe-whd",
  Smbp: "fe-smbp",
  Solar: "fe-solar",
  Ev: "fe-ev"
}

export const PROJECT_URLS = {
  [PROJECTS.Website]: process.env.VUE_APP_WEBSITE_URL,
  [PROJECTS.Account]: process.env.VUE_APP_ACCOUNT_SITE_URL,
  [PROJECTS.Help]: process.env.VUE_APP_HELP_SITE_URL,
  [PROJECTS.AccountApp]: process.env.VUE_APP_ACCOUNT_APP_URL,
  [PROJECTS.Gengame]: process.env.VUE_APP_GENGAME_SITE_URL,
  [PROJECTS.Whd]: process.env.VUE_APP_WHD_SITE_URL,
  [PROJECTS.Smbp]: process.env.VUE_APP_SMBP_SITE_URL,
  [PROJECTS.Solar]: process.env.VUE_APP_SOLAR_SITE_URL,
  [PROJECTS.Ev]: process.env.VUE_APP_EV_SITE_URL
}
