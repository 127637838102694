import { sessionStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import {
  untestedMutations,
  untestedActions,
  untestedGetters,
} from "@/store/untested/modules/homeDuringAppointment"

export const state = {
  personAtHome: sessionStore.getItem("personAtHome") || null,
  form: {
    firstName: sessionStore.getItem("firstName") || "",
    lastName: sessionStore.getItem("lastName") || "",
    telephone: sessionStore.getItem("telephone") || "",
    email: sessionStore.getItem("email") || "",
    confirmEmail: sessionStore.getItem("confirmEmail") || "",
    age: sessionStore.getItem("age") === "true" || false,
  },
  contacts: JSON.parse(sessionStore.getItem("contacts")) || [],
  contactAtHome: sessionStore.getItem("contactAtHome") || null,
  medicalEquipment: sessionStore.getItem("medicalEquipment") || "false",
}

export const actions = {
  ...untestedActions,
  // Tested actions below
}

export const mutations = {
  ...untestedMutations,
  // Tested mutations below
}

export const getters = {
  ...untestedGetters,
  // Tested getters below
}

export default {
  state,
  mutations,
  actions,
  getters,
}
