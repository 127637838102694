<template>
  <div v-if="pageContent && pageContent.body" data-testid="homepage">
    <ContentBlockList :blocks="pageContent.body" />
  </div>
</template>

<script>
import { mapState } from "vuex"
import store from "@/store/store"
import ContentBlockList from "@soenergy/frontend-library/src/components/ContentBlockList"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import homeNewContent from "soenergy-cms-loader!?path=smbp/home-new"
import homeMigrationContent from "soenergy-cms-loader!?path=smbp/home-maintenance"
import smbpSettings from "soenergy-cms-loader!?path=smbp/smbp-settings&force_prerender=true"
import { getCookie } from "@soenergy/frontend-library/src/services/Cookies.js"
import bookingHelpersMixin from "@/mixins/bookingHelpersMixin"

export default {
  components: {
    ContentBlockList,
  },
  mixins: [
    cmsPreviewMixin({
      story: homeMigrationContent,
      name: "migrationPageContent",
    }),
    cmsPreviewMixin({ story: homeNewContent, name: "homePageContent" }),
    bookingHelpersMixin,
  ],
  async beforeRouteEnter(to, from, next) {
    if (store.state.juniferAccountId) {
      await store.dispatch("bookingDetails/fetchBookingData")
      if (store.getters["bookingDetails/hasBookings"]) {
        next({ name: "appointment-details" })
      }
    }
    next()
  },
  computed: {
    ...mapState({
      juniferAccountId: (state) => state.juniferAccountId,
    }),
    pageContent() {
      return this.isUserAllowedToAccess()
        ? this.homePageContent
        : this.migrationPageContent
    },
  },
  methods: {
    isUserAllowedToAccess() {
      const isMaintenanceEnabled =
        smbpSettings.content.isMaintenanceEnabled.includes(
          process.env.TARGET_ENV
        )
      const isFriendlyUser =
        getCookie("bypass_smbp_maintenance_page") === "HS@(d3&+^kw9"

      return !isMaintenanceEnabled || isFriendlyUser
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.banner__copy) {
  font-size: $font-size-3;

  @include lg {
    font-size: $font-size-5;
  }
}
</style>
