<template>
  <LoadingOverlay :is-active="bookingApiStatus === 'LOADING'" />
  <div v-if="pageContent && bookingApiStatus === 'SUCCESS'">
    <HeroBannerGeneral
      v-if="heroBanner"
      :responsive-background-image="heroBanner.responsiveBackgroundImage"
    />
    <section class="cancel-booking">
      <Article v-bind="cmsBlockToProps(pageContent.header)" />
      <div class="cancel-booking__details">
        <p class="cancel-booking__title">Booking Details</p>
        <table class="cancel-booking__table">
          <tr>
            <td class="cancel-booking__text">Booking reference</td>
            <td ref="booking-reference" class="cancel-booking__content">
              {{ booking.jobNumber }}
            </td>
          </tr>
          <tr>
            <td class="cancel-booking__text">Address</td>
            <td ref="address" class="cancel-booking__content">
              {{ booking.fullAddress }}
            </td>
          </tr>
          <tr>
            <td class="cancel-booking__text">Date</td>
            <td ref="date" class="cancel-booking__content">
              {{ booking.appointmentDateFormatted }}
            </td>
          </tr>
          <tr>
            <td class="cancel-booking__text">Time</td>
            <td ref="time" class="cancel-booking__content">
              {{ booking.appointmentTimeSlot }}
            </td>
          </tr>
        </table>
      </div>
      <div class="cancel-booking__nav-buttons">
        <NavButton v-bind="cmsBlockToProps(pageContent.navButton)" />
        <AppButton
          v-bind="cmsBlockToProps(pageContent.editBookingButton)"
          :to="{
            name: 'reschedule-appointment',
            query: { jobNumber: jobNumber },
          }"
        />
      </div>
      <hr class="cancel-booking__horizontal-rule" />
      <div v-if="reasons.length">
        <Article v-bind="cmsBlockToProps(pageContent.cancelAppointmentText)" />
        <Checkbox
          v-for="(reason, id) in reasons"
          :key="id"
          v-model="reasons[id].value"
          :label="reason.label"
          :input-id="id"
          class="cancel-booking__reason"
        />
        <textarea
          v-if="reasons[reasons.length - 1].value"
          v-model="otherCancelReason"
          placeholder="Please specify reason"
          class="cancel-booking__other-reasons"
        />
        <AppButton
          v-bind="cmsBlockToProps(pageContent.cancelButton)"
          ref="submit-button"
          :disabled="!hasReasonSelected"
          class="cancel-booking__button"
          :loading="status.loading"
          @click="cancelBooking"
        />
        <FeedbackDialog
          v-if="status.error"
          class="cancel-booking__error-message"
          variant="negative"
          :message="pageContent.errorMessage"
        />
      </div>
    </section>
  </div>
</template>

<script>
import HeroBannerGeneral from "@soenergy/frontend-library/src/components/HeroBannerGeneral.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import cancelBooking from "soenergy-cms-loader!?path=smbp/cancel-booking"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import NavButton from "@soenergy/frontend-library/src/components/NavButton.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox.vue"
import GatewayPortal from "../services/GatewayPortal"
import { mapState } from "vuex"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"

export default {
  components: {
    HeroBannerGeneral,
    Article,
    NavButton,
    Checkbox,
    AppButton,
    FeedbackDialog,
    LoadingOverlay,
  },
  mixins: [cmsPreviewMixin({ story: cancelBooking })],
  data() {
    return {
      reasons: [],
      otherCancelReason: "",
      status: {
        loading: false,
        error: false,
      },
    }
  },
  computed: {
    ...mapState({
      customerId: (state) => state.customerId,
      bookingApiStatus: (state) => state.bookingDetails.bookingApiStatus,
      bookings: (state) => state.bookingDetails.bookings,
    }),
    jobNumber() {
      return this.$route.query.jobNumber
    },
    booking() {
      return this.bookings.find(
        (booking) => booking.jobNumber == this.jobNumber
      )
    },
    heroBanner() {
      return this.pageContent.heroBanner[0]
    },
    selectedReasons() {
      return this.reasons.filter((reason) => reason.value)
    },
    hasReasonSelected() {
      return this.reasons.some((reason) => reason.value)
    },
  },
  watch: {
    pageContent: {
      handler(content) {
        if (content?.cancellationReasons) {
          this.reasons = content.cancellationReasons.map((cancelReason) => {
            return {
              label: cancelReason.label,
              reason: cancelReason.value,
              value: false,
            }
          })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("bookingDetails/fetchBookingData")
  },
  methods: {
    cancelBooking() {
      this.status.error = false
      this.status.loading = true

      const formattedReasons = this.selectedReasons.reduce(
        (allReasons, currentReason) => {
          if (this.otherCancelReason && currentReason.reason == "Other") {
            currentReason.reason += `: ${this.otherCancelReason}`
          }
          allReasons.push(currentReason.reason)
          return allReasons
        },
        []
      )

      GatewayPortal.cancelBooking(this.customerId, this.jobNumber, {
        reasons: formattedReasons,
      })
        .then(() => this.$router.push({ path: "/booking-cancelled" }))
        .catch((err) => {
          this.status.error = true
          this.status.loading = false
          throw err
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel-booking {
  margin: $space-8 $space-4;

  @include md {
    margin: $space-8 auto;
    width: $width-6;
  }

  &__details {
    margin: $space-8 0;
  }

  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
  }

  &__table {
    width: 100%;
    border-spacing: 0 $space-2;
    border-collapse: unset;
  }

  &__text {
    font-weight: $weight-medium;
    padding-right: $space-3;
  }

  &__content {
    padding-left: $space-4;
  }

  &__nav-buttons {
    display: flex;
    gap: $space-4;

    @include md {
      gap: $space-8;
    }
  }

  &__horizontal-rule {
    border: solid 1px $grey-500;
  }

  &__reason {
    margin: $space-3 0;
  }
  &__other-reasons {
    width: 100%;
    min-height: $space-16;
    font-size: $font-size-3;
    padding: $space-2;
  }

  &__button {
    margin-top: $space-4;
  }

  &__error-message {
    margin: $space-6 0;
  }
}
</style>
