<template>
  <div id="app">
    <AppHeader :hide-menu="true" :deployment-url="vercelUrl" />
    <router-view />

    <LoginModal :show="showAuthError" />

    <LoadingOverlay :is-active="loading" transition="fade-out" />
    <template
      v-if="
        cookieBannerContent &&
        cookieModalContent &&
        !isThirdPartyCookieConsentEnabled
      "
    >
      <CookieManager
        :banner-text="cookieBannerContent.bannerText[0].textContent"
        :accept-all-button="cookieBannerContent.buttons[0]"
        :manage-cookies-button="cookieBannerContent.buttons[1]"
        :has-manage-cookies="true"
        :modal-title="cookieModalContent.modalTitle"
        :modal-sub-title="cookieModalContent.modalSubTitle"
        :accept-button="cookieModalContent.acceptButton"
        :save-button="cookieModalContent.saveButton"
        :modal-description="cookieModalContent.modalDescription[0].textContent"
        :necessary-description="
          cookieModalContent.necessaryDescription[0].textContent
        "
        :performance-description="
          cookieModalContent.performanceDescription[0].textContent
        "
        :functional-description="
          cookieModalContent.functionalDescription[0].textContent
        "
        :advertising-description="
          cookieModalContent.advertisingDescription[0].textContent
        "
      >
      </CookieManager>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import AppHeader from "@soenergy/frontend-library/src/components/AppHeader"
import CookieManager from "@soenergy/frontend-library/src/components/CookieBanner/CookieManager"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import LoginModal from "@/components/LoginModal.vue"
import cookieModalContent from "soenergy-cms-loader!?path=common/cookie-modal"
import cookieBannerContent from "soenergy-cms-loader!?path=common/cookie-banner"

export default {
  components: {
    AppHeader,
    CookieManager,
    LoginModal,
    LoadingOverlay,
  },
  mixins: [
    cmsPreviewMixin({
      story: cookieBannerContent,
      name: "cookieBannerContent",
    }),
    cmsPreviewMixin({ story: cookieModalContent, name: "cookieModalContent" }),
  ],
  provide() {
    return {
      currentProject: PROJECTS.Smbp,
    }
  },
  data() {
    return {
      isThirdPartyCookieConsentEnabled:
        process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true",
      vercelUrl: process.env.VERCEL_URL,
    }
  },
  computed: {
    ...mapState({
      showAuthError: (state) => state.showAuthError,
      loading: (state) => state.loading,
    }),
  },
}
</script>
