import { StoryblokHelper, cmsBlockToProps } from "./storyblokHelper"
const { StoryblokVue, apiPlugin } = require("@storyblok/vue")

export default {
  install(Vue, config = {}) {
    Vue.use(StoryblokVue, {
      accessToken: process.env.VUE_APP_STORYBLOK_TOKEN,
      use: [apiPlugin]
    })
    Vue.config.globalProperties.$storyblokHelper = new StoryblokHelper(config)
    Vue.mixin({
      methods: {
        cmsBlockToProps
      }
    })
  }
}
