<template>
  <Calendar
    variant="primary"
    show-dates
    :stored-date="selectedDate"
    :available-dates="days"
    @selected-date="selectDate"
  />
  <div v-if="selectedDate">
    <p class="selected-appointment-date">Selected date: {{ formatDate }}</p>
    <section>
      <p class="available-times-label">Available Times</p>
      <SegmentedGroup v-model="selectedTimeSlotModel" wrap>
        <SegmentedButton
          v-for="(slot, index) in selection.slots"
          :key="index"
          :label="formatSlot(slot)"
          :value="slot"
          variant="primary"
          wrap
        />
      </SegmentedGroup>
      <FeedbackDialog class="appointment-dialog" variant="info" full-width>
        <Article
          ref="activeAccountText"
          :text-content="appointmentInfoContent"
          no-margin
        />
      </FeedbackDialog>
    </section>
  </div>
</template>

<script>
import dayjs from "dayjs"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import SegmentedButton from "@soenergy/frontend-library/src/components/SegmentedButton/SegmentedButton.vue"
import SegmentedGroup from "@soenergy/frontend-library/src/components/SegmentedButton/SegmentedGroup.vue"
import Calendar from "@/components/Calendar.vue"

export default {
  components: {
    Calendar,
    SegmentedGroup,
    SegmentedButton,
    FeedbackDialog,
    Article,
  },
  model: {
    prop: "selectedAppointmentSlot",
    event: "selected-appointment-slot",
  },
  props: {
    selectedAppointmentSlot: {
      type: Object,
      default: null,
    },
    days: {
      type: Array,
      default: () => [],
    },
    availableSlots: {
      type: Array,
      default: () => [],
    },
    appointmentInfoContent: {
      type: String,
      default: null,
    },
  },
  emits: ["selected-appointment-slot"],
  data() {
    return {
      selectedDate: null,
      selectedTimeSlot: null,
    }
  },
  computed: {
    formatDate() {
      if (!this.selectedDate) return null
      return dayjs(this.selectedDate).format("DD MMM YYYY")
    },
    selection() {
      if (!this.selectedDate) return null
      return (
        this.availableSlots.find(
          (day) => day.date === dayjs(this.selectedDate).format("YYYY-MM-DD")
        ) || []
      )
    },
    selectedTimeSlotModel: {
      get() {
        return this.selectedTimeSlot
      },
      set(value) {
        this.setSelectedAppointment(value)
      },
    },
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    formatSlot(slot) {
      return `${slot.startTime.slice(0, 5)} - ${slot.endTime.slice(0, 5)}`
    },
    setInitialData() {
      if (!this.selectedAppointmentSlot) return

      const date = this.availableSlots.find((slot) => {
        return dayjs(slot.date).isSame(
          dayjs(this.selectedAppointmentSlot.date),
          "day"
        )
      })
      let timeSlot

      if (this.selectedAppointmentSlot.time) {
        timeSlot = date?.slots?.find((slot) => {
          const { id, startTime, endTime } = this.selectedAppointmentSlot.time
          return (
            id === slot.id &&
            startTime === slot.startTime &&
            endTime === slot.endTime
          )
        })
      }

      if (date && timeSlot) {
        this.selectedDate = this.selectedAppointmentSlot.date
        this.selectedTimeSlot = this.selectedAppointmentSlot.time
      } else {
        this.selectedDate = null
        this.selectedTimeSlot = null
      }
    },
    selectDate(date) {
      this.selectedDate = date
      this.setSelectedAppointment(null)
    },
    setSelectedAppointment(value) {
      this.selectedTimeSlot = value
      this.$emit("selected-appointment-slot", {
        date: this.selectedDate,
        time: value,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.selected-appointment-date {
  font-size: $font-size-3;
  font-weight: $weight-medium;
  margin: $space-4 0 $space-8;
}

.available-times-label {
  font-size: $font-size-4;
  font-weight: $weight-medium;
  margin-bottom: $space-4;
}

.appointment-dialog {
  margin-top: $space-4;
}

::v-deep .segmented-group__form {
  gap: $space-3;

  @include md {
    gap: $space-4;
  }
}

::v-deep .segmented-button {
  &__label {
    font-size: $font-size-2;
    padding: $space-2 $space-3;

    @include md {
      padding: $space-2 $space-4;
    }
  }
}
</style>
