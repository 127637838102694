import { getDomain, setCookie, deleteCookie } from "../Cookies.js"
/*
  Since the refresh token is stored in a HttpOnly cookie
  it can't be accessed directly and there is no way to determine
  if it's possible to refresh the token.

  This module is used to add a secondary regular cookie
  which is added / removed alongside the refresh token,
  (e.g. during login and logout) 
*/

const COOKIE_NAME = "website_refresh_token_available"

export const markRefreshTokenAsAvailable = ({ renewTokenExpiryDate }) =>
  setCookie({
    value: "true",
    expiryDate: renewTokenExpiryDate,
    name: COOKIE_NAME,
    domain: getDomain()
  })

export const markRefreshTokenAsUnavailable = () => deleteCookie(COOKIE_NAME)
export const isRefreshTokenAvailable = () =>
  document.cookie.includes(`${COOKIE_NAME}=`)
