<template>
  <LoadingOverlay :is-active="bookingApiStatus === 'LOADING'" />
  <GenericImageLayout
    v-if="pageContent && bookingApiStatus === 'SUCCESS'"
    :image-src="pageContent.heroImage.filename"
    :background-color="pageContent.backgroundColour"
    class="appointment-booked"
    mobile-image
  >
    <div class="appointment-details">
      <h1 class="appointment-details__heading h4">
        {{ pageContent.title }}
      </h1>

      <div
        v-for="booking in bookings"
        :key="booking.jobNumber"
        class="appointment-details__wrapper"
      >
        <Article
          v-if="booking.cancellationDate"
          :text-content="pageContent.text"
          :variable-set="{
            [JOB_CANCELLATION_DATE]: booking.cancellationDate,
          }"
          class="appointment-details__text"
          no-margin
        >
        </Article>
        <dl class="appointment-details__list">
          <dt>Booking reference</dt>
          <dd ref="booking-reference">{{ booking.jobNumber }}</dd>
          <dt>Address</dt>
          <dd ref="address">{{ booking.fullAddress }}</dd>
          <dt>Date</dt>
          <dd ref="appointment-date">{{ booking.appointmentDateFormatted }}</dd>
          <dt>Time</dt>
          <dd ref="appointment-time">{{ booking.appointmentTimeSlot }}</dd>
        </dl>
        <div class="appointment-details__button-container">
          <AppButton
            ref="reschedule-appointment-button"
            :to="{
              name: 'reschedule-appointment',
              query: { jobNumber: booking.jobNumber },
            }"
            variant="invertedPrimary"
          >
            Reschedule
          </AppButton>
        </div>
        <RouterLink
          v-if="booking.isEligibleForCancellation"
          :to="{
            name: 'cancel-booking',
            query: { jobNumber: booking.jobNumber },
          }"
          class="appointment-details__link"
        >
          Need to cancel your booking?
        </RouterLink>
      </div>
    </div>
  </GenericImageLayout>
</template>

<script>
import { mapState } from "vuex"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import GenericImageLayout from "@soenergy/frontend-library/src/components/GenericImageLayout"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import appointmentDetails from "soenergy-cms-loader!?path=smbp/appointment-details"
import { JOB_CANCELLATION_DATE } from "@/variable_keys/smbp_variables.js"

export default {
  components: {
    GenericImageLayout,
    Article,
    LoadingOverlay,
    AppButton,
  },
  mixins: [cmsPreviewMixin({ story: appointmentDetails })],
  data() {
    return {
      JOB_CANCELLATION_DATE,
    }
  },
  computed: {
    ...mapState({
      bookingApiStatus: (state) => state.bookingDetails.bookingApiStatus,
      bookings: (state) => state.bookingDetails.bookings,
    }),
  },
  mounted() {
    this.$store.dispatch("bookingDetails/fetchBookingData")
  },
}
</script>

<style lang="scss" scoped>
.appointment-details {
  padding: $space-8 $space-4;

  @include lg {
    padding: $space-13 $space-13 $space-13 $space-0;
  }

  @include xl {
    padding: $space-13 $space-16 $space-13 $space-0;
  }

  &__heading {
    text-transform: none;
  }

  &__wrapper:not(:first-of-type) {
    margin-top: $space-10;
  }

  &__text {
    margin-bottom: $space-10;
    font-size: $font-size-3;

    @include lg {
      font-size: $font-size-4;
    }
  }

  &__list {
    font-size: $font-size-3;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: $space-3;
    grid-column-gap: $space-6;

    dt {
      grid-column-start: 1;
      font-weight: $weight-medium;
    }

    dd {
      grid-column-start: 2;
    }
  }

  &__button-container {
    margin-top: $space-10;

    &:not(:last-child) {
      margin-right: $space-10;
    }
  }

  &__link {
    font-size: $font-size-3;
  }
}
</style>
