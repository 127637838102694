import GlobalClickManager from "./globalClickManager"

export default {
  [process.env.VUE_APP_VERSION === "3" ? "beforeMount" : "bind"](
    element,
    { value: groupData }
  ) {
    const invalidGroupData =
      !groupData || (!groupData.length && !groupData.area && !groupData.name)

    if (invalidGroupData) {
      return
    }

    GlobalClickManager.addClickGroup(
      element,
      Array.isArray(groupData) ? groupData[0] : groupData
    )
  },
  [process.env.VUE_APP_VERSION === "3" ? "unmounted" : "unbind"](element) {
    GlobalClickManager.removeClickGroup(element)
  }
}
