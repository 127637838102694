<template>
  <div
    class="staging-banner__container"
    :class="{ 'red-branding': newBranding, 'yellow-branding': !newBranding }"
    role="alert"
    aria-live="polite"
  >
    <p>
      <b>Staging preview</b>
      <span class="staging-banner--info"
        >: Some changes may be unfinished. Please use the
        <a href="https://www.sandbox.soenergy.co/">sandbox environment</a>.
      </span>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true"
    }
  }
}
</script>

<style lang="scss" scoped>
.staging-banner {
  &__container {
    height: 40px;
    width: 100%;
    padding: 8px;
    text-align: center;
    color: black;
    font-size: $size-7;
    overflow: hidden;

    & a {
      color: inherit;
    }
  }

  &--info {
    display: none;
  }

  @include sm {
    &--info {
      display: inline;
    }
  }
}

.red-branding {
  background-color: $red-500;
}

.yellow-branding {
  background-color: $yellow-300;
}
</style>
