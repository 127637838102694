export default ({ story, name = "pageContent" }) => ({
  data() {
    return {
      [name]: story.content
    }
  },
  async created() {
    this.setRouteEventsDefaults(story.content)
    this.$storyblokHelper.getContentUpdates(story, updatedContent => {
      this[name] = updatedContent
      this.setRouteEventsDefaults(updatedContent)
    })
  },
  methods: {
    setRouteEventsDefaults(content) {
      if (this.$analytics && content) {
        this.$analytics.setRouteEventsDefaults(
          this.$route.path,
          content.analyticsEventDefaults
        )
      }
    }
  }
})
