<template>
  <section class="step-wizard">
    <ProgressBar
      v-if="showProgressBar"
      class="step-wizard__progress-indicator"
      :steps="steps"
      :current-step-number="currentStepNumber || 0"
      :aria-label="progressBarAriaLabel"
    />
    <div
      class="step-wizard__wrapper"
      :class="{ 'step-wizard__wrapper--inlined-controls': inlineControls }"
    >
      <BackLink
        class="step-wizard__back-link"
        :class="{
          'step-wizard__back-link--hidden':
            showCloseButton && !currentStepNumber && !showBackButtonOnFirstStep
        }"
        disable-router-back
        @goBackTo="goToPreviousStep"
      />
      <component
        :is="routingComponent"
        :current-step-number="currentStepNumber + 1"
        :step-count="steps.length"
        @go-back="goToPreviousStep"
        @go-forward="goToNextStep"
        @go-specific="goToSpecificStep"
      />
      <button
        v-if="showCloseButton"
        class="step-wizard__close-button"
        aria-label="close"
        @click="$emit('closed')"
      >
        <i class="step-wizard__close-button-icon icon icon-close"></i>
      </button>
    </div>
  </section>
</template>

<script>
import ProgressBar from "./ProgressBar"
import BackLink from "./BackLink"
import { findNextStep } from "../helpers/stepHelper"

export default {
  components: {
    ProgressBar,
    BackLink
  },
  model: {
    prop: "currentStepNumber",
    event: "change"
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    },
    showProgressBar: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    showBackButtonOnFirstStep: {
      type: Boolean,
      default: false
    },
    inlineControls: {
      type: Boolean,
      default: false
    },
    entryRouteName: {
      type: String,
      default: ""
    }
  },
  data: () => ({ initialRouteHandled: false }),
  computed: {
    isStepGiven() {
      return !!this.currentStepNumber || this.currentStepNumber === 0
    },
    currentStep() {
      return this.steps[this.currentStepNumber]
    },
    progressBarAriaLabel() {
      const stepNumber = this.currentStepNumber + 1
      return `Step ${stepNumber} out of ${this.steps.length}`
    },
    routingComponent() {
      const isNuxt = "$nuxt" in this
      return isNuxt ? "nuxt-child" : "router-view"
    }
  },
  watch: {
    currentStepNumber: {
      handler(number) {
        if (
          this.isStepGiven &&
          this.steps[number].routeName !== this.$route.name
        ) {
          this.goToStepNumber(number)
        }
      },
      immediate: true
    },
    $route: {
      handler(newRoute) {
        if (!this.initialRouteHandled && this.isStepGiven) return
        this.initialRouteHandled = true

        const stepMatchingRoute = this.getStepIndexByRouteName(newRoute.name)
        if (stepMatchingRoute > -1) {
          this.$emit("change", stepMatchingRoute)
        }
      },
      immediate: true
    }
  },
  methods: {
    goToPreviousStep() {
      const stepNumber = findNextStep(this.currentStepNumber, false, this.steps)
      if (stepNumber !== null) {
        this.$router.back()
      } else {
        this.$emit("returned")
      }
    },
    goToNextStep(args) {
      const stepNumber = findNextStep(this.currentStepNumber, true, this.steps)
      if (stepNumber) {
        this.goToStepNumber(stepNumber)
        this.$emit("go-forward", args)
      } else {
        this.$emit("completed", args)
      }
    },
    getStepIndexByRouteName(routeName) {
      return this.steps.findIndex(step => step.routeName === routeName)
    },
    goToSpecificStep(routeName) {
      const stepIndexMatchingRoute = this.getStepIndexByRouteName(routeName)
      this.goToStepNumber(stepIndexMatchingRoute)
    },
    goToStepNumber(number) {
      this.$router.push({
        name: this.steps[number].routeName
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.step-wizard {
  &__wrapper {
    position: relative;
    padding-top: $spacing-7;

    &--inlined-controls {
      @include lg {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    @include md {
      padding-top: $space-10;
    }
  }

  &__back-link {
    margin-top: 0;
    margin-bottom: $space-9;
    margin-right: $space-4;

    &--hidden {
      visibility: hidden;
    }

    @include lg {
      margin-bottom: $space-10;
    }
  }

  &__title {
    margin-bottom: $space-8;

    @include md {
      margin-bottom: $space-10;
    }
  }

  &__close-button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    width: $space-6;
    height: $space-6;
    padding: 0;
    padding-top: $spacing-7;
    background-color: transparent;
    cursor: pointer;
    font-size: $font-size-2;
    border-radius: 4px;
    color: inherit;

    @include lg {
      position: static;
      padding-top: 0;
    }
  }

  &__close-button-icon {
    font-size: $font-size-5;
    font-weight: $weight-bold;
  }
}
</style>
