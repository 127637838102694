import axios from "axios"
import axiosBetterStacktrace from "axios-better-stacktrace"

// Axios 0.27 introduced a regression where thrown errors
// do not include stack traces. The change below adds
// a stack trace if it is missing
// https://github.com/axios/axios/discussions/4741
// https://github.com/axios/axios/issues/4713
const addErrorStackTrace = error => {
  if (
    error.isAxiosError &&
    !error.stack &&
    error.config &&
    error.config.topmostError
  ) {
    error.stack = error.config.topmostError.stack
    delete error.config.topmostError
  }

  throw error
}

export default ({
  requestInterceptors,
  responseErrorInterceptors,
  ...config
} = {}) => {
  const defaultConfig = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    }
  }

  const client = axios.create({
    ...defaultConfig,
    ...config
  })

  client.interceptors.response.use(response => response, addErrorStackTrace)
  axiosBetterStacktrace(client)
  if (requestInterceptors) {
    requestInterceptors.forEach(handler =>
      client.interceptors.request.use(handler)
    )
  }

  if (responseErrorInterceptors) {
    responseErrorInterceptors.forEach(handler =>
      client.interceptors.response.use(request => request, handler)
    )
  }

  return client
}
