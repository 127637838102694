<template>
  <div
    class="input-field form-group"
    :class="{
      'input-field--bold-share-link': styleVariant === 'share-link',
      'form-group--inline-label': inlineLabel,
      ...$attrs.class
    }"
  >
    <div
      class="form-group__input-group"
      :class="{
        'form-group__full-width': fullWidth
      }"
    >
      <div class="form-group__input-box">
        <span
          v-if="hasPrefix"
          class="form-group__prefix"
          :style="prefixSymbol"
        ></span>
        <component
          :is="multiline ? `textarea` : `input`"
          :id="name"
          v-bind="$attrs"
          :aria-invalid="hasError || null"
          :aria-describedby="describedBy || null"
          :autocapitalize="autocapitalize"
          :autocorrect="autocorrect"
          :autocomplete="autocomplete"
          :name="name"
          :value.prop="value"
          :class="getInputClass"
          v-on="inputListeners"
        />
        <label
          class="form-group__label"
          :class="{
            'form-group__label--disabled': $attrs.disabled,
            'form-group__label--floating': forceLabelUp,
            'form-group__label--prefixed': hasPrefix
          }"
          :for="name"
        >
          {{ label }}
        </label>
      </div>
      <span
        v-if="inlineLabel"
        class="form-group__inline-label"
        :class="{ 'form-group__inline-label--disabled': $attrs.disabled }"
      >
        {{ inlineLabel }}
      </span>
    </div>
    <p v-if="hint" class="form-group__hint">{{ hint }}</p>
    <div
      v-if="errorMessage || $slots.errorMessage"
      :id="feedbackId"
      class="form-group__invalid-feedback"
      role="alert"
    >
      <slot name="errorMessage">{{ errorMessage }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    multiline: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    autocapitalize: {
      type: String,
      default: "off"
    },
    autocorrect: {
      type: String,
      default: "off"
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    inlineLabel: {
      type: String,
      default: ""
    },
    forceLabelUp: {
      type: Boolean,
      default: false
    },
    placeholderVisible: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    styleVariant: {
      type: String,
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getInputClass() {
      return {
        "form-group__input": true,
        "form-group__full-width": this.fullWidth,
        "form-group__input--error": this.hasError,
        "form-group__input--filled": Boolean(this.value),
        "form-group__input--visible-placeholder": this.placeholderVisible,
        "form-group__prefix-padding": this.hasPrefix,
        "form-group__text-area-text": this.multiline
      }
    },
    hasPrefix() {
      return this.prefix !== ""
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit("input", event.target.value)
      }
    },
    feedbackId() {
      return `${this.name}-feedback`
    },
    describedBy() {
      const receivedValue = this.$attrs["aria-describedby"]
      if (receivedValue) {
        return receivedValue
      }
      if (this.errorMessage) {
        return this.feedbackId
      }
      return null
    },
    prefixSymbol() {
      return {
        "--prefix": JSON.stringify(this.prefix)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../stylesheets/components/_form.scss";

.input-field {
  position: relative;
  &--bold-share-link {
    label {
      font-size: $size-6;
      font-weight: $weight-medium;

      @include lg {
        font-size: $size-5;
      }
    }
    .form-group__input-group {
      @include lg {
        max-width: 512px;
      }
    }
    .form-group__input {
      @include lg {
        height: $size-2;
        font-size: $size-5;
        max-width: 512px;
        font-weight: $weight-medium;
      }
    }
  }
}
.form-group__prefix:before {
  content: var(--prefix);
}

.form-group__text-area-text {
  font-size: $font-size-3;
}

.form-group__full-width {
  max-width: 100%;
  width: 100%;
}
</style>
