<template>
  <Modal
    v-if="modalSettings"
    class="eligibility-modal"
    :is-modal-open="show"
    :show-close-icon="false"
  >
    <template #header>
      <h4 class="eligibility-modal__title">{{ modalSettings.title }}</h4>
    </template>
    <template #body>
      <Article v-if="textContent" :text-content="textContent" />
    </template>
    <template #footer>
      <NavButton
        v-if="modalSettings.button"
        class="eligibility-modal__nav-button"
        v-bind="cmsBlockToProps(modalSettings.button)"
        aria-label="Redirect"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import Article from "@soenergy/frontend-library/src/components/Article"
import Mixpanel from "@soenergy/frontend-library/src/services/Mixpanel"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import smbpModals from "soenergy-cms-loader!?path=smbp/smbp-eligibility-modals"
import NavButton from "@soenergy/frontend-library/src/components/NavButton.vue"

export default {
  components: {
    Modal,
    Article,
    NavButton,
  },
  mixins: [cmsPreviewMixin({ story: smbpModals })],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: null,
    },
  },
  emits: ["close"],
  computed: {
    modalSettings() {
      if (this.pageContent?.smbpModals) {
        const modalContent = this.pageContent.smbpModals.find(
          (modal) => modal.name === this.variation
        )
        return modalContent
      }
      return null
    },
    textContent() {
      return this.modalSettings.bodyText?.[0]?.textContent || null
    },
  },
  mounted() {
    Mixpanel.sendEvent(`Show SMBPModal -  ${this.variation}`)
  },
}
</script>

<style lang="scss" scoped>
.eligibility-modal {
  &__nav-button {
    width: 100%;
  }
}
</style>
