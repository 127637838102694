export default {
  methods: {
    preventBodyScroll(preventScroll) {
      const documentElement = document.documentElement

      if (preventScroll) {
        documentElement.classList.add("no-scroll")
      } else {
        documentElement.classList.remove("no-scroll")
      }
    }
  },
  [process.env.VUE_APP_VERSION === "3" ? "unmounted" : "destroyed"]() {
    this.preventBodyScroll(false)
  }
}
