import GatewayPortal from "@/services/GatewayPortal"
import Booking from "@/services/Booking"

export default {
  async getBookings(customerId) {
    const rawBookings = await GatewayPortal.getBookings(customerId)
    return rawBookings.data.bookings?.map(
      (rawBooking) => new Booking(rawBooking)
    )
  },
}
