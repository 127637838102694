import Bugsnag from "@bugsnag/js"
import BugsnagPluginVue from "@bugsnag/plugin-vue"
import { reportErrorToDataDog, logInfoToDataDog } from "./datadog"

const errorBlacklist = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  "_avast_submit"
]

const checkIfMicrosoftCrawlerError = event => {
  // Filters out errors thrown by Microsoft crawlers (https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552)
  if (!event) return

  const thrownString =
    typeof event.originalError === "string"
      ? event.originalError
      : typeof event === "string"
      ? event
      : null

  if (
    thrownString &&
    thrownString.startsWith("Object Not Found Matching Id:")
  ) {
    return true
  }
}

const safeJsonParse = string => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return {
      details: string
    }
  }
}

const handleServerError = event => {
  const originalError = event && event.originalError
  const httpStatus = originalError.response.status
  const endpoint = originalError.config.url
    .split("?")[0] // removes query params
    .replace(/\/(\d+?)(\/|$)/g, "/<id>/") // replaces dynamic segments
  const method = originalError.config.method.toUpperCase()

  event.errors[0].errorClass = "EndpointError"
  event.context = `${method} ${endpoint}: ${httpStatus} [${event.context}]`
  event.groupingHash = `endpoint-error_${method}_${endpoint}_${httpStatus}`

  if (httpStatus === 502) {
    event.context = "502"
    event.groupingHash = `endpoint-error_502`
  }
  const response = originalError.response && originalError.response.data
  const request = originalError.config && originalError.config.data
  if (response) {
    event.addMetadata("server response data", safeJsonParse(response))
  }
  if (request) {
    event.addMetadata("request data", safeJsonParse(request))
  }
}

const isErrorBlacklisted = error => {
  return errorBlacklist.find(blacklisted => {
    return error?.message?.includes(blacklisted)
  })
}

const processError = event => {
  if (checkIfMicrosoftCrawlerError(event)) return false
  const error = event.originalError

  if (!error) return true
  if (error.message === "Network Error") {
    event.groupingHash = `endpoint-error_network-error`
    event.context = "Network Error"
  }

  // Groups errors notifying about browser trying to fetch scripts
  // from previous releases which are no longer available
  if (
    error instanceof SyntaxError &&
    (error.message.includes(`Unexpected token '<'`) ||
      error.message.includes(`expected expression, got '<'`))
  ) {
    event.groupingHash = `syntax-error_script-load-error`
  }
  if (error.name === "ChunkLoadError") {
    event.groupingHash = `chunk-loading-error`
  }

  // Checks if error was processed by axios-better-stacktrace
  if (error.isAxiosError && error.response) {
    handleServerError(event)
  }
}

const shouldDiscardError = event => {
  const error = event.originalError

  if (
    error.stack &&
    (error.stack instanceof String || typeof error.stack === "string")
  ) {
    const stackList = error.stack.split("\n")

    return (
      error.stack.includes(`freshchat.com/js/widget`) ||
      stackList[stackList.length - 1]?.includes(`www.googletagmanager.com`)
    )
  }

  return isErrorBlacklisted(error)
}

export default {
  init: ({
    apiKey,
    redactedKeys,
    Vue,
    app,
    onError,
    appVersion = process.env.PROJECT_VERSION,
    releaseStage = process.env.TARGET_ENV,
    ...config
  }) => {
    if (!apiKey || !(app || Vue))
      throw new Error("Required parameter not provided")
    Bugsnag.start({
      apiKey,
      plugins: [new BugsnagPluginVue()],
      appVersion,
      releaseStage,
      enabledReleaseStages: [
        "staging",
        "release",
        "production",
        "prestaging",
        "pre-production",
        "sandbox"
      ],
      redactedKeys: redactedKeys || [
        "password",
        "checkPassword",
        "bank_account_number",
        "accountNumber",
        "sortCode",
        "sort_code",
        "token",
        "cardNumber",
        "card_number",
        "expiry",
        "code",
        "cardHolderName",
        "securityCode"
      ],
      onError: event => {
        processError(event)

        if (onError) {
          onError(event)
        }

        if (shouldDiscardError(event)) {
          return false
        }
      },
      ...config
    })
    const plugin = Bugsnag.getPlugin("vue")
    if (app) {
      app.use(plugin)
    } else if (Vue) {
      plugin.installVueErrorHandler(Vue)
    }
  },
  notify: (...args) => Bugsnag.notify(...args),
  /**
   * Reports error to Datadog and Bugsnag
   * While this functions accepts 'severity' parameter used by Bugsnag to deescalate errors, it's only added
   * for backwards-compatibility and in future non-critical logs, should be tracked in a different way.
   * @param {error} error - error that is to be logged
   * @param {object} param - object with custom fields of choice
   * @param {string} severity - {'error' | 'warning' | 'info'}. Deprecated - only applicable for Bugsnag. The default value is error.
   */
  report: (error, params = {}, { severity, context, groupingHash } = {}) => {
    reportErrorToDataDog(error, params)
    Bugsnag.notify(error, event => {
      if (severity) event.severity = severity
      if (context) event.context = context
      if (groupingHash) event.groupingHash = groupingHash
      Object.keys(params).forEach(paramKey =>
        event.addMetadata(paramKey, params[paramKey])
      )
    })
  },
  /**
   * Logs information to Datadog
   * @param {string} description - description of information that is to be logged
   * @param {object} metadata - object with custom fields of choice
   */
  log: (description, metadata) => {
    logInfoToDataDog(description, metadata)
  },
  client: Bugsnag
}
