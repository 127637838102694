export const appointmentTypeMapping = {
  "meter-investigation": "FAULTS",
  "meter-reboot": "FAULTS",
  "meter-power-cycle": "FAULTS",
  "recommission-dual": "COMMISSION_JOIN",
  "recommission-elec": "COMMISSION_JOIN",
  "recommission-gas": "COMMISSION_JOIN",
  "meter-exchange-dual": "METER_EXCHANGE",
  "meter-exchange-elec": "METER_EXCHANGE",
  "meter-exchange-gas": "METER_EXCHANGE",
  install: "METER_EXCHANGE",
  "install-elec": "METER_EXCHANGE",
  "install-gas": "METER_EXCHANGE",
} as const

export const installEligibilityReasons = {
  ELIGIBLE_ELEC_EXCHANGE: "ELIGIBLE_ELEC_EXCHANGE",
  ELIGIBLE_GAS_EXCHANGE: "ELIGIBLE_GAS_EXCHANGE",
  ELIGIBLE_DUAL_EXCHANGE: "ELIGIBLE_DUAL_EXCHANGE",
  INELIGIBLE_ALREADY_SMART: "INELIGIBLE_ALREADY_SMART",
  INELIGIBLE_SWITCHING_AWAY: "INELIGIBLE_SWITCHING_AWAY",
  INELIGIBLE_TOO_COMPLEX: "INELIGIBLE_TOO_COMPLEX",
} as const
