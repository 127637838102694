<template>
  <div v-if="pageContent" class="at-home">
    <div class="at-home__wrapper">
      <p class="at-home__title">
        Step {{ currentStepNumber }}: {{ pageContent.stepTitle }}
      </p>
      <Article v-bind="cmsBlockToProps(pageContent.atHomeContent)" />
      <div class="at-home__contact">
        <RadioButtonCard
          v-for="contact in formattedContacts"
          :key="contact.id"
          v-model="contactAtHome"
          :label="contact.firstName + ' ' + contact.lastName"
          :value="contact.id"
          no-text-dropdown
          is-content-left
          smaller-padding
          background-hover
          variant="primary"
        >
          <p class="at-home__phone">{{ contact.telephone }}</p>
          <p class="at-home__email">{{ contact.email }}</p>
          <button class="at-home__button" @click="editDetails(contact.id)">
            <span class="at-home__contact-content">
              <span class="at-home__contact-text">Edit</span
              ><i class="icon icon-pen at-home__contact-icon"></i>
            </span>
          </button>
        </RadioButtonCard>
        <button
          v-if="noAddedContact"
          class="at-home__add-contact at-home__button"
          @click="addNewContact"
        >
          {{ pageContent.addNewContactText }}
        </button>
        <CheckBox
          v-model="medicalEquipment"
          input-id="medical-equipment"
          class="at-home__checkbox"
          true-value="true"
          false-value="false"
        >
          <Article v-bind="cmsBlockToProps(pageContent.medicalEquipmentText)" />
        </CheckBox>
        <AppButton
          v-if="nextButtonProps"
          v-track="nextButtonProps.tracking"
          class="at-home__next-button"
          :disabled="!contactAtHome"
          @click="nextStep"
        >
          <span> {{ nextButtonProps.label }} </span>
          <span class="at-home__icon icon icon-arrow-right" />
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import RadioButtonCard from "@soenergy/frontend-library/src/components/RadioButtonCard.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import atHomeContent from "soenergy-cms-loader!?path=smbp/at-home"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import CheckBox from "@soenergy/frontend-library/src/components/Checkbox.vue"

export default {
  components: {
    RadioButtonCard,
    Article,
    AppButton,
    CheckBox,
  },
  mixins: [cmsPreviewMixin({ story: atHomeContent })],
  props: {
    currentStepNumber: {
      type: Number,
      default: null,
    },
    stepCount: {
      type: Number,
      default: null,
    },
  },
  emits: ["go-forward"],
  computed: {
    ...mapGetters([
      "accountNumber",
      "formattedContacts",
      "noAddedContact",
      "completeContactInfo",
    ]),
    contactAtHome: {
      get() {
        return this.$store.state.homeDuringAppointment.contactAtHome
      },
      set(id) {
        this.$store.commit("SET_CONTACT_AT_HOME", id)
      },
    },
    medicalEquipment: {
      get() {
        return this.$store.state.homeDuringAppointment.medicalEquipment
      },
      set(value) {
        this.$store.commit("SET_MEDICAL_EQUIPMENT", value)
      },
    },
    nextButtonProps() {
      return this.pageContent.nextStepButton?.[0] ?? null
    },
    validated() {
      return this.contactAtHome
    },
  },
  watch: {
    contactAtHome(contactId) {
      this.setSelectedContact(contactId)
    },
  },
  mounted() {
    this.checkContactDetails()
  },
  methods: {
    ...mapActions({
      getContacts: "getContacts",
      setSelectedContact: "setSelectedContact",
      checkContactDetails: "checkContactDetails",
    }),
    async editDetails(contactId) {
      this.contactAtHome = contactId
      this.goToEditPage()
    },
    nextStep() {
      if (this.completeContactInfo) {
        this.$emit("go-forward")
        return
      }
      this.goToEditPage()
    },
    addNewContact() {
      this.editDetails("new-contact")
    },
    goToEditPage() {
      this.$router.push({
        name: "edit-contact",
        query: {
          current: this.currentStepNumber,
          steps: this.stepCount,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.at-home {
  font-size: $font-size-4;
  &__wrapper {
    margin-bottom: $space-12;
  }

  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
    margin-bottom: $space-4;

    @include md {
      font-size: $font-size-6;
    }
  }

  &__add-contact {
    color: $water;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: $font-size-4;
  }

  &__contact-icon {
    margin-bottom: $space-1;
  }

  &__contact-content {
    display: flex;
    color: $water;
    gap: $space-3;
    align-items: center;
  }

  &__button {
    background: none;
    border: none;
    margin-top: $space-2;
    padding: 0;
    font-size: $font-size-4;
    cursor: pointer;
    outline: inherit;
    width: fit-content;
  }
  &__next-button {
    position: fixed;
    bottom: $space-4;
    left: 50%;
    transform: translateX(-50%);
    z-index: zindex(sticky);
    width: 90%;

    @include md {
      width: 100%;
      position: static;
      transform: translateX(0);
      margin-bottom: $space-4;
    }
  }

  &__checkbox {
    margin-top: $space-4;
  }
}

::v-deep .checkbox__input:checked + .checkbox__label {
  font-weight: $weight-normal;
}
</style>
