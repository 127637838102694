export const findNextStep = (currentNumber, goNext, steps) => {
  const increment = goNext ? 1 : -1
  do {
    currentNumber += increment
    if (currentNumber < 0 || currentNumber > steps.length - 1) {
      return null
    }
  } while (steps[currentNumber].skip)

  return currentNumber
}
