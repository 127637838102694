import HttpClient from "../http/HttpClient"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL
})
export default {
  renewToken(data) {
    return apiClient.post("api/v1/auth/refresh", data, {
      withCredentials: true
    })
  },
  login(credentials) {
    return apiClient.post("api/v1/auth/login", credentials, {
      withCredentials: true
    })
  },
  logout() {
    return apiClient.post("users/sign_out", null, {
      withCredentials: true
    })
  },
  jwtLogout({ token }) {
    return apiClient.post("api/v1/auth/logout", null, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}
