import findLinkInAncestorElements from "../../helpers/findLinkInAncestorElements"
import { PROJECT_URLS } from "../../config/projectRouting"
import { isExternalLink } from "./utils"
import isVue3 from "../../helpers/isVue3"

const allowedOrigins = Object.values(PROJECT_URLS)
let clickGroups = []

const findElementGroup = element =>
  clickGroups.find(({ element: groupContainer }) =>
    groupContainer.contains(element)
  )

const isInternalLink = element =>
  !isExternalLink(element) ||
  allowedOrigins.find(origin => origin === element.origin)

const getPageName = ({ pageNames, router, link }) => {
  let pageName = null

  if (pageNames) {
    pageName = pageNames[link.pathname]
  } else if (router) {
    pageName = isVue3()
      ? router.resolve(link.pathname).name
      : router.resolve(link.pathname).resolved.name
  }

  return pageName
}

export default {
  attachListener({
    pageNames,
    router,
    clickEventHandler,
    clickContainers = [document]
  }) {
    const clickCallback = event => {
      const link = findLinkInAncestorElements(event.target)

      const elementIsNotUnusedLink = !link || link._analyticsListener
      if (elementIsNotUnusedLink) {
        return
      }

      const clickGroup = findElementGroup(link)
      const clickGroupData = clickGroup && clickGroup.groupData
      let eventName = null

      const pageName = getPageName({
        pageNames: pageNames,
        router: router,
        link: link
      })

      if (pageName && isInternalLink(link)) {
        eventName = `Click on ${pageName} link`
      } else if (link.href) {
        eventName = `Click on ${link.href} link`
      }

      if (eventName) {
        clickEventHandler(
          {
            ...clickGroupData,
            name: eventName
          },
          link
        )
      }
    }

    clickContainers.forEach(container =>
      container.addEventListener("click", clickCallback)
    )
  },
  addClickGroup(element, groupData) {
    clickGroups.push({ element, groupData })
  },
  removeClickGroup(elementToRemove) {
    clickGroups = clickGroups.filter(
      ({ element }) => element !== elementToRemove
    )
  }
}
