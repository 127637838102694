<template>
  <div>
    <div
      class="checkbox"
      :class="{ 'checkbox--error': hasError, 'checkbox--card': checkboxCard }"
    >
      <input
        :id="inputId"
        v-model="binding"
        :aria-describedby="errorMessage ? `${inputId}-feedback` : null"
        :aria-invalid="hasError || null"
        class="checkbox__input"
        :disabled="disabled || null"
        :required="required"
        :value="value"
        :true-value="trueValue"
        :false-value="falseValue"
        type="checkbox"
      />
      <label
        class="size-normal checkbox__label"
        :class="{ 'checkbox__label--small': isSmall }"
        :for="inputId"
      >
        <slot>{{ label }}</slot>
      </label>
    </div>
    <div
      v-if="errorMessage"
      :id="`${inputId}-feedback`"
      class="form-group__invalid-feedback"
      :class="{ 'form-group__checkbox-card': checkboxCard }"
      role="alert"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
/**
 * - Please ensure to provide label for the checkbox either by passing a `label` prop or as a slot
 * - To toggle custom values, instead of the default `true` / `false`, specify `trueValue` and `falseValue` properties
 * - The component can be used in two modes:
 *  - Single checkbox: To toggle a primitive value (for example `true` => `false`)
 *  - Group of checkboxes sharing common `v-model`: To add or remove an item from an array value (for example `['apple']` => `['apple', 'orange']`)
 **/

export default {
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    /**
     * Prop used by the v-model binding
     */
    modelValue: {
      type: [Array, Boolean, Number, String],
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    inputId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Values to use for the model, instead of the default `true` / `false`
     * Relevant when checkbox is not part of a group (`modelValue` is a primitive value)
     */
    trueValue: {
      type: [Boolean, Number, String],
      default: true
    },
    falseValue: {
      type: [Boolean, Number, String],
      default: false
    },
    /**
     * Value to add or remove from the model array
     * Relevant only when checkbox is part of a group (`modelValue` is an array)
     */
    value: {
      type: [Boolean, Number, String, Object],
      default: ""
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    checkboxCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    binding: {
      get() {
        return this.modelValue
      },
      set(isChecked) {
        this.$emit("change", isChecked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../stylesheets/components/_form.scss";

$checkbox-size: $size-6;
$expanded-touch-area: $spacing-4;

.checkbox {
  display: flex;
  position: relative;
  padding-left: $checkbox-size;
  align-items: center;

  &__label {
    display: inline-block;
    cursor: pointer;
    touch-action: manipulation;
    padding-left: $spacing-2;

    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      border: 2px solid $night-light;
      border-radius: 4px;
      background: transparent;

      @include lg {
        top: $spacing-1;
      }
    }

    &:after {
      @include font-icon($icon-check);
      display: inline-block;
      position: absolute;
      left: 0.25rem;
      top: 0.25rem;
      color: $earth-dark;
      font-size: 1rem;
      opacity: 0;

      @include lg {
        top: calc(#{$spacing-1} + 0.25rem);
      }
    }

    &--small {
      font-size: 1rem;

      @include lg {
        line-height: $space-8;
      }
    }
  }

  &__input {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: $checkbox-size + $expanded-touch-area;
    height: $checkbox-size + $expanded-touch-area;
    top: calc(-#{$expanded-touch-area} / 2);
    left: calc(-#{$expanded-touch-area} / 2);
    margin: 0;
    opacity: 0;
  }

  &__input:focus + &__label:before {
    outline: none;
    box-shadow: 0px 0px 0px 4px $focus;
    border-color: $night;
  }

  &--error &__label:before {
    box-shadow: 0px 0px 0px 4px $fire-light;
    border-color: $fire-dark;
  }

  &__input:checked + &__label {
    font-weight: $weight-medium;

    &:before {
      outline: none;
      border-color: $night;
    }
    &:after {
      opacity: 1;
    }
  }

  &__input:disabled + &__label {
    color: $disabled;
    font-weight: $weight-normal;

    &:before {
      background-color: $day-dark;
      border-color: $disabled;
    }
    &:after {
      color: $disabled;
    }
  }

  &--card {
    padding: 0;

    .checkbox__label {
      border: 1px solid $grey-600;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      padding: $space-5 $space-13 $space-6 $space-5;
      width: 100%;
      display: inline-block;
      font-size: $font-size-3;
      line-height: 28px;
      background: $white;

      &:before {
        top: calc(50% - #{calc($checkbox-size/2)});
        left: calc(100% - #{$space-5} - #{$checkbox-size});
        border: 1px solid $black;

        @include lg {
          top: calc(50% - #{calc($checkbox-size/2)});
        }
      }

      &:after {
        content: "";
        display: block;
        width: $size-7;
        height: $size-7;
        background: $green-400;
        border-radius: 2px;
        top: calc(50% - #{calc($checkbox-size/2)} + 4px);
        left: calc(100% - #{$space-5} - #{$checkbox-size} + 4px);

        @include lg {
          top: calc(50% - #{calc($checkbox-size/2)} + 4px);
        }
      }
    }
    // checkbox card checked
    .checkbox__input:checked + .checkbox__label:before {
      border-color: $black;
    }
    .checkbox__input:checked + .checkbox__label {
      border: 1px solid $grey-800;
      color: $black;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
    // checkbox card focus
    .checkbox__input:focus + .checkbox__label:before {
      outline: none;
      box-shadow: none;
      border-color: $grey-600;
    }
    .checkbox__input:focus + .checkbox__label {
      border: 3px solid $sun;
      padding: 18px 62px 22px 18px; // padding is reduced because of larger border
      box-shadow: none;
    }
    // checkbox card disabled
    .checkbox__input:disabled + .checkbox__label {
      color: $grey-600;
      border: 1px solid $grey-600;
      background: $grey-200;
      box-shadow: none;
      cursor: default;
      touch-action: none;

      &:before {
        background: $grey-200;
        border: 1px solid $grey-600;
      }
      &:after {
        color: $grey-600;
      }
    }
  }
}
// checkbox card error
.checkbox--card.checkbox--error .checkbox__label:before {
  box-shadow: none;
  border-color: $grey-700;
}
.checkbox--card.checkbox--error .checkbox__label,
.checkbox--card.checkbox--error .checkbox__input:checked + .checkbox__label {
  border: 2px solid $red-700;
  padding: 19px 63px 23px 19px; // padding is reduced because of larger border
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
.form-group__checkbox-card {
  font-size: $font-size-2;
  line-height: 24px;
  color: $red-600;
  font-weight: $weight-medium;
  position: relative;
  margin-top: $space-4;
  padding-left: $space-8;

  &:before {
    @include font-icon($icon-alert);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: $font-size-5;
    font-weight: $weight-bold;
  }
}
</style>
