<template>
  <div class="segmented-button" :class="buttonClasses">
    <input
      :id="inputIdLocal"
      v-model="binding"
      v-track="{ tracking, label }"
      :value="value"
      :disabled="disabled || null"
      class="segmented-button__input"
      type="radio"
      :name="name"
      :aria-invalid="hasError || null"
    />
    <label class="segmented-button__label" :for="inputIdLocal">
      <slot>{{ label }}</slot>
    </label>
  </div>
</template>

<script>
import { uidMixin } from "../../mixins/uidMixin"
export default {
  inject: {
    segmentedGroup: {
      default: null
    }
  },
  mixins: [uidMixin],
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    /* Currently selected value sent by v-model */
    modelValue: {
      type: [String, Object, Boolean],
      default: ""
    },
    /* Value when checked */
    value: {
      type: [String, Object, Boolean],
      default: ""
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    label: {
      type: String,
      default: null,
      required: true
    },
    inputId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    tracking: {
      type: [Object, Array],
      default: null
    },
    variant: {
      type: String,
      default: null
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    binding: {
      get() {
        return this.selectedGroupValue
      },
      set(value) {
        this.inGroup
          ? this.segmentedGroup.checked(value)
          : this.$emit("change", value)
      }
    },
    buttonClasses() {
      return {
        "segmented-button--small": this.small,
        "segmented-button--wrap": this.wrap,
        primary: this.variant === "primary"
      }
    },
    inGroup() {
      return Boolean(this.segmentedGroup)
    },
    selectedGroupValue() {
      return this.inGroup ? this.segmentedGroup.selectedValue : this.modelValue
    }
  }
}
</script>

<style lang="scss" scoped>
$radio-size: $size-6;
$expanded-touch-area: $spacing-4;

.segmented-button {
  width: 100%;
  &__input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }
  &__input:focus + &__label {
    box-shadow: 0px 0px 0px $space-1 $focus !important;
    position: relative;
    z-index: 100;
  }
  &__input:checked + &__label {
    color: $day;
    background-color: $black;

    .segmented-button__text {
      &:before {
        opacity: 1;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    justify-content: center;

    padding: $space-2 $space-0;
    border-radius: 6px;
    border: 1px solid $black;

    color: $black;
    background-color: $day;

    font-size: $font-size-1;
    font-weight: $weight-medium;
  }

  &--small {
    width: fit-content;
    .segmented-button__label {
      padding: $space-1 $space-3;
    }
  }

  &--wrap {
    width: fit-content;
    .segmented-button__label {
      padding: $space-2 $space-2;
    }
  }
  &.primary {
    .segmented-button__input:checked + .segmented-button__label {
      background-color: $green-400;
      color: $black;
      border: 1px solid transparent;
    }
  }
}
</style>
