<template>
  <component
    :is="wrapperTag"
    :class="['radio-button-card', radioButtonClass]"
    :[ifAriaHaspopup]="true"
  >
    <input
      v-if="selectable && !hasSelectButton"
      v-track="{ tracking, label }"
      class="radio-button-card__input"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', $event.target.value)"
    />
    <div
      class="radio-button-card__panel"
      :class="[
        {
          'radio-button-card__smaller-padding': smallerPadding
        },
        { 'radio-button-card__background-hover': backgroundHover },
        { 'radio-button-card__panel--box-shadow': borderShadow }
      ]"
    >
      <div
        v-if="displayBadge && badgeContent.length"
        class="radio-button-card__badge"
      >
        {{ badgeContent }}
      </div>
      <div
        class="radio-button-card__title"
        :class="{ 'radio-button-card__title-dual': dualHeading }"
      >
        <div>
          <p
            class="radio-button-card__heading"
            :class="{
              'radio-button-card__heading--radio':
                selectable && !hasSelectButton,
              'radio-button-card--flex-left': isContentLeft
            }"
          >
            {{ label }}
          </p>
          <slot name="subheading" />
        </div>
        <template v-if="dualHeading">
          <slot name="right-side-heading" />
        </template>
      </div>
      <div class="radio-button-card__body">
        <slot />

        <div
          v-if="hasDropdown && showDropdown"
          class="radio-button-card__dropdown"
        >
          <slot name="dropdown" />
        </div>

        <div
          v-if="hasDropdown && !noTextDropdown"
          class="radio-button-card__links-container"
        >
          <a
            class="radio-button-card__link"
            href="#"
            @click.prevent="showDropdown = !showDropdown"
          >
            <template v-if="!showDropdown">
              More details
            </template>
            <template v-else>
              Less details
            </template>
            <i
              class="radio-button-card__icon icon"
              :class="showDropdown ? 'icon-chevron-up' : 'icon-chevron-down'"
            ></i>
          </a>

          <div class="radio-button-card__link">
            <slot name="view-more-link" />
          </div>
        </div>

        <div
          v-if="selectable && hasSelectButton"
          class="radio-button-card__button"
          :class="{
            'radio-button-card__button--with-dropdown': noTextDropdown
          }"
        >
          <AppButton
            v-track="{ tracking, label }"
            @click="$emit('change', value)"
          >
            {{ selectButtonText }}
          </AppButton>
          <button
            v-if="noTextDropdown"
            class="radio-button-card__button-link"
            href="#"
            @click.prevent="showDropdown = !showDropdown"
          >
            <i
              class="radio-button-card__button-link-icon icon"
              :class="showDropdown ? 'icon-chevron-up' : 'icon-chevron-down'"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import AppButton from "./AppButton"

export default {
  components: {
    AppButton
  },
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: true
    },
    isContentLeft: {
      type: Boolean,
      default: false
    },
    smallerPadding: {
      type: Boolean,
      default: false
    },
    displayBadge: {
      type: Boolean,
      default: false
    },
    backgroundHover: {
      type: Boolean,
      default: false
    },
    badgeContent: {
      type: String,
      default: ""
    },
    dropdownContent: {
      type: Object,
      default: () => {}
    },
    tracking: {
      type: [Object, Array],
      default: null
    },
    selectable: {
      type: Boolean,
      default: true
    },
    hasSelectButton: {
      type: Boolean,
      default: false
    },
    selectButtonText: {
      type: String,
      default: "Choose"
    },
    dualHeading: {
      type: Boolean,
      default: false
    },
    noTextDropdown: {
      type: Boolean,
      default: false
    },
    borderShadow: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false
    }
  },
  computed: {
    wrapperTag() {
      return this.hasSelectButton ? "div" : "label"
    },
    isChecked() {
      return this.modelValue == this.value
    },
    hasDropdown() {
      return this.$slots.dropdown || this.dropdownContent
    },
    ifAriaHaspopup() {
      return this.hasDropdown ? "aria-haspopup" : null
    },
    radioButtonClass() {
      return {
        "radio-button-card--primary": this.variant === "primary",
        "radio-button-card--error": this.hasError
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$radio-size: $size-6;

.radio-button-card {
  display: flex;
  height: 100%;

  &__input {
    opacity: 0;
    z-index: 1;
    position: absolute;
    cursor: pointer;
  }

  &__input:checked + &__panel {
    background-color: $day-dark;

    &:after {
      opacity: 1;
    }
  }

  &__input:checked + &__panel &__heading--radio:after {
    opacity: 1;
  }

  &__input:focus + &__panel {
    outline: none;
    box-shadow: 0px 0px 0px 4px $focus;
    border-color: $night;
  }

  &__panel {
    border: 1px solid $night;
    border-radius: 4px;
    padding: $spacing-6 $spacing-8;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: max-content;
    position: relative;

    &--box-shadow {
      border: 1px solid $grey-400 !important;
      box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
    }
  }

  &__background-hover:hover {
    background: $day-dark;
    cursor: pointer;
  }

  &__badge {
    background: $yellow-300;
    position: absolute;
    top: -$space-3;
    left: -0.3rem;
    border-radius: $space-6;
    padding: 0.125rem $space-3 $space-1 $space-3;
    font-size: $font-size-2;
    font-weight: $weight-medium;
  }

  &__title {
    &-dual {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $space-3;

      @include md {
        flex-direction: row;
      }
      .radio-button-card__heading {
        max-width: $width-4;
      }

      + .radio-button-card__body > .radio-button-card__links-container {
        margin-top: $spacing-4;
        padding-top: $spacing-4;
        border: none;
      }
    }
  }

  &__heading {
    position: relative;
    font-size: $size-6;
    line-height: $line-height-2;
    font-weight: $weight-medium;

    @include lg {
      font-size: $size-5;
    }

    &--radio {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-#{$radio-size + $spacing-3});
        width: $radio-size;
        height: $radio-size;
        border: 2px solid $night-light;
        border-radius: 50%;
        background: $day;

        @include lg {
          top: $spacing-1;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: $size-7;
        height: $size-7;
        left: calc(-#{$radio-size + $spacing-3});
        top: $spacing-1;
        margin-left: $spacing-1;
        background: $night;
        border-radius: 50%;
        opacity: 0;

        @include lg {
          top: $spacing-2;
        }
      }
    }
  }

  &__body {
    margin-top: $spacing-2;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__smaller-padding {
    padding: $spacing-7 $spacing-6 $spacing-6 $spacing-5;
  }

  &__icon {
    vertical-align: sub;
    margin-left: $spacing-1;
  }

  &__link {
    flex: auto;
    font-size: $size-7;
    &:nth-child(2) {
      text-align: right;
    }
  }

  &__links-container {
    margin-top: $spacing-4;
    padding-top: $spacing-4;
    border-top: 1px solid $day-dark;
    display: flex;

    @include lg {
      padding-top: $spacing-6;
      margin-top: $spacing-6;
    }
  }

  &__button {
    margin-top: $space-7;

    &--with-dropdown {
      display: flex;
      justify-content: space-between;

      .radio-button-card__button-link {
        border: none;
        padding: none;
        background: none;
        cursor: pointer;

        &-icon {
          color: $black;
          font-size: $font-size-5;
          font-weight: $weight-bold;
          margin-top: $space-4;
        }
      }
    }
  }

  &--primary {
    .radio-button-card__heading--radio:after {
      background: $green-400;
    }

    .radio-button-card__heading--radio:before {
      border: 1px solid $grey-700;
    }

    .radio-button-card__input:checked + .radio-button-card__panel {
      background-color: transparent;
    }

    .radio-button-card__input:checked + .radio-button-card__panel:after {
      opacity: 1;
    }

    .radio-button-card__background-hover {
      background: transparent;
      cursor: pointer;
    }
  }

  &--error &__panel,
  &--error &__input:focus + &__panel,
  &--error &__heading--radio:before {
    border-color: $danger-02;
  }
}

.radio-button-card--flex-left.radio-button-card__heading--radio {
  &:before {
    right: calc(-#{$spacing-3 + $spacing-2});
    left: unset;
  }
  &:after {
    right: -$spacing-4;
    left: unset;
  }
}
</style>
