export default {
  hasPendingRenewal(user, accountNumber) {
    if (!user.renewals || !user.renewals.length) return false
    return user.renewals.some(
      renewal =>
        renewal.status === "Pending" && accountNumber === renewal.account_number
    )
  },
  hasAgreementPendingRenewal(user, accountNumber, agreementId) {
    if (!user.renewals || !user.renewals.length) return false
    return user.renewals.some(renewal => {
      const agreementMatch = renewal.agreement_ids.includes(agreementId)
      return (
        renewal.status === "Pending" &&
        accountNumber === renewal.account_number &&
        agreementMatch
      )
    })
  }
}
