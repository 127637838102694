/*
  Class facilitating awaiting promise completion
  separately from promise creation

  Example usage:

  const initialiser = new Deferred()
  const player = null

  play () {
    await initialiser.promise()
    player.play()
  }
  async loadPlayer () {
    player = await downloadPlayer()
    initialiser.resolve()
  }

  play()
  loadPlayer()
*/

export default function() {
  const self = this
  this.promise = new Promise((resolve, reject) => {
    self.reject = reject
    self.resolve = resolve
  })
}
