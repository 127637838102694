<template>
  <component :is="type" v-bind="props">
    <slot />
  </component>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    link: {
      type: String,
      default: null
    },
    routingComponent: {
      type: Object,
      default: null
    },
    preserveQueryParams: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type() {
      if (this.routingComponent) {
        return this.routingComponent
      }
      if (this.isAnchor) return "a"
      if (this.link && "$nuxt" in this) return "nuxt-link"
      if (this.link && this.$router) return "router-link"
      return "a"
    },
    isExternal() {
      return this.link ? this.link.startsWith("http") : null
    },
    isMail() {
      return this.link ? this.link.startsWith("mailto") : null
    },
    isTel() {
      return this.link ? this.link.startsWith("tel") : null
    },
    isIdAnchor() {
      return this.link ? this.link.startsWith("#") : null
    },
    isRouted() {
      return ("$nuxt" in this || this.$router) && !this.isAnchor
    },
    isAnchor() {
      return this.isExternal || this.isMail || this.isTel || this.isIdAnchor
    },
    targetPath() {
      if (this.preserveQueryParams && process.browser) {
        return this.link + window.location.search
      }
      return this.link
    },
    props() {
      return {
        ...this.$attrs,
        [this.isRouted ? "to" : "href"]: this.targetPath,
        noopener: this.isExternal || null,
        target: this.$attrs.target || (this.isExternal ? "_blank" : null)
      }
    }
  }
}
</script>
