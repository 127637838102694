import Bugsnag from "@bugsnag/js"
import Deferred from "../helpers/Deferred"
const UaParser = require("ua-parser-js")

// Fetching Mixpanel asynchronously to reduce load time
const fetchMixpanelLibrary = async () => {
  return (await import("mixpanel-browser")).default
}

const initialiser = new Deferred()

// Proxies methods to Mixpanel clients
// While ensuring the client is not accessed
// before it has been initialised
export const mapMixpanelMethods = fields =>
  fields.reduce((methods, method) => {
    methods[method] = async function(...args) {
      await this.loadingInProgress
      return this.client[method](...args)
    }
    return methods
  }, {})

// Snippet extracted from https://help.mixpanel.com/hc/en-us/articles/360001337103#collecting-last-touch-utms)
function getQueryParam(url, param) {
  // Expects a raw URL
  param = param.replace(/[[]/, "[").replace(/[]]/, "]")
  const regexS = "[?&]" + param + "=([^&#]*)",
    regex = new RegExp(regexS),
    results = regex.exec(url)
  if (
    results === null ||
    (results && typeof results[1] !== "string" && results[1].length)
  ) {
    return ""
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, " ")
  }
}

function getCampaignParams() {
  if (typeof process !== "undefined" && process.server) return
  const campaign_keywords = "utm_source utm_medium utm_campaign utm_content utm_term".split(
      " "
    ),
    params = {},
    first_params = {}
  let index
  let kw = ""
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(window.document.URL, campaign_keywords[index])
    if (kw.length) {
      params[campaign_keywords[index] + " [last touch]"] = kw
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(window.document.URL, campaign_keywords[index])
    if (kw.length) {
      first_params[campaign_keywords[index] + " [first touch]"] = kw
    }
  }
  return {
    firstTouchParams: first_params,
    lastTouchParams: params
  }
}
function registerCampaignParams(
  mixpanelClient,
  { firstTouchParams, lastTouchParams }
) {
  if (Object.keys(lastTouchParams).length) {
    mixpanelClient.register(lastTouchParams)
  }
  if (Object.keys(firstTouchParams).length) {
    mixpanelClient.register_once(firstTouchParams)
  }
}

function getDeviceType() {
  if (!navigator.userAgent) return "unknown"
  const UserAgentInstance = new UaParser(navigator.userAgent)
  return UserAgentInstance.getDevice().type || "desktop"
}

export default {
  client: null,
  config: {},
  loadingInProgress: initialiser.promise,
  async init(token, { mixpanelConfig, projectName, disableQueueing } = {}) {
    if (!projectName) throw new Error("Please provide projectName parameter")
    const campaignParams = getCampaignParams()
    this.config.disableQueueing = disableQueueing

    this.client = await fetchMixpanelLibrary()
    await this.client.init(token, {
      api_host: "https://api-eu.mixpanel.com",
      ip: false,
      ...mixpanelConfig
    })
    initialiser.resolve()
    try {
      registerCampaignParams(this.client, campaignParams)
    } catch (error) {
      console.error(error)
      Bugsnag.notify(error)
    }
    this.client.register({
      Project: projectName.replace(/^fe-/, ""),
      "Device Type": getDeviceType()
    })
  },
  async sendEvent(name, properties, { isExternalLink, isExitPageEvent } = {}) {
    if (!name) {
      return
    }
    await this.track(
      name,
      { ...properties },
      {
        transport: isExternalLink || isExitPageEvent ? "sendBeacon" : "XHR",
        send_immediately: isExitPageEvent ? true : this.config.disableQueueing
      }
    )
  },
  ...mapMixpanelMethods(["register", "unregister", "track", "time_event"])
}
