<template>
  <component
    :is="type"
    v-track="{ tracking, label: buttonLabel }"
    v-bind="props"
    :to="to"
    :class="buttonClass"
    :disabled="disabled || null"
    v-on="$listeners"
  >
    <slot>
      {{ label }}
    </slot>
  </component>
</template>

<script>
import stubDirectives from "../mixins/stubDirectives"

export default {
  emits: ["click"],
  mixins: [stubDirectives],
  props: {
    variant: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    task: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    href: {
      type: String,
      default: null
    },
    hrefId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    tracking: {
      type: [Object, Array],
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true"
    }
  },
  computed: {
    buttonLabel() {
      return this.$slots.default &&
        this.$slots.default[0] &&
        this.$slots.default[0].text
        ? this.$slots.default[0].text.trim()
        : this.label
    },
    buttonClass() {
      return {
        button: true,
        // "new-branding": this.newBranding,
        "new-branding": true,
        "is-inverted-primary": this.variant === "invertedPrimary",
        "is-secondary": this.variant === "secondary",
        "is-bold-secondary": this.variant === "boldSecondary",
        "is-transparent-secondary": this.variant === "transparentSecondary",
        "is-white": this.variant === "whiteNoBorder",
        "is-inverted-secondary": this.variant === "invertedSecondary",
        "is-tertiary": this.variant === "tertiary",
        "is-xs": this.size === "xs",
        "is-small": this.size === "small",
        "is-large": this.size === "large",
        "is-modal": this.task === "modal",
        "is-modal-right": this.task === "modal-right",
        "is-modal-left": this.task === "modal-left",
        "is-searchbar": this.task === "searchbar",
        "is-loading": this.loading,
        "full-width": this.fullWidth
      }
    },
    type() {
      if (this.href || this.hrefId) return "a"
      if (this.to && "$nuxt" in this) return "nuxt-link"
      if (this.to && this.$router) return "router-link"
      return "button"
    },
    props() {
      if (this.href) {
        return {
          href: this.href
        }
      }
      if (this.hrefId) {
        return {
          href:
            "javascript:window.scroll({ top: (document.getElementById('" +
            this.hrefId +
            "').offsetTop - 80), behavior: 'smooth' });"
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.button {
  @include so-energy-button;

  &.new-branding {
    &:focus-visible {
      box-shadow: 0 0 0 3px $blue-300;
    }

    &.is-inverted-primary {
      &:hover {
        border-color: $new-design-secondary-500;
        background-color: $new-design-secondary-500;
        color: $green-400;
      }
    }

    &.is-secondary {
      &:hover {
        background-color: $new-design-secondary-200;
        color: $black;
      }

      &:active {
        background-color: $black;
        color: $white;
      }

      &:focus-visible {
        border-color: $black;
      }
    }

    &.is-transparent-secondary,
    &.is-white {
      &:hover {
        background-color: $new-design-secondary-200;
        border-color: $new-design-secondary-200;
        color: $black;
      }

      &:active {
        background-color: $green-400;
        border-color: $green-400;
        color: $black;
      }
    }

    &.is-tertiary {
      color: $green-900;

      &:hover {
        background-color: $new-design-secondary-200;
        border-color: $new-design-secondary-200;
        color: $black;
      }
    }

    &.is-white,
    &.is-tertiary {
      &:focus-visible {
        border-color: $blue-300;
        box-shadow: none;
      }
    }
  }
}
</style>
