<template>
  <Modal
    :is-modal-open="isModalOpen"
    :show-close-icon="true"
    is-full-screen
    is-autofocus-disabled
    class="cookie-modal"
    :has-footer="false"
    @closeModal="handleCloseModal"
  >
    <template #header>
      <h2 class="cookie-modal__title">
        {{ modalTitle }}
      </h2>
    </template>
    <template #body>
      <div class="cookie-modal__body">
        <Article
          class="cookie-modal__description"
          :text-content="modalDescription"
        />

        <div class="cookie-modal__btn-wrapper">
          <AppButton
            ref="acceptAllButton"
            class="cookie-modal__button"
            variant="invertedPrimary"
            @click="submitPreferences(true)"
          >
            {{ acceptButton }}
          </AppButton>
          <AppButton
            ref="saveButton"
            class="cookie-modal__button"
            variant="secondary"
            @click="submitPreferences(false)"
          >
            {{ saveButton }}
          </AppButton>
        </div>

        <h4 class="cookie-modal__title">{{ modalSubTitle }}</h4>
        <div v-for="(item, index) in selectorData" :key="index">
          <div class="cookie-modal__selector">
            <label :for="item.id">{{ item.label }}</label>
            <ToggleButton
              v-show="!item.disabled"
              :id="item.id"
              :value="item.value"
              :off-label="''"
              :on-label="''"
              :disabled="item.disabled"
              @change="item.value = $event"
            />
          </div>
          <Article :text-content="item.description" />
        </div>

        <div class="cookie-modal__btn-wrapper">
          <AppButton
            class="cookie-modal__button"
            variant="invertedPrimary"
            @click="submitPreferences(true)"
          >
            {{ acceptButton }}
          </AppButton>
          <AppButton
            class="cookie-modal__button"
            variant="secondary"
            @click="submitPreferences(false)"
          >
            {{ saveButton }}
          </AppButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../Modal"
import AppButton from "../AppButton"
import ToggleButton from "../ToggleButton"
import Article from "../Article"

export default {
  name: `CookieModal`,
  components: {
    Modal,
    AppButton,
    ToggleButton,
    Article
  },
  props: {
    selectedCookies: {
      type: Array,
      default: () => []
    },
    isModalOpen: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: "Cookie preferences"
    },
    modalSubTitle: {
      type: String,
      default: "How do we categorise cookies?"
    },
    acceptButton: {
      type: String,
      default: "Accept All"
    },
    saveButton: {
      type: String,
      default: "Save My Preferences"
    },
    modalDescription: {
      type: Object,
      default: () => ({})
    },
    necessaryDescription: {
      type: Object,
      default: () => ({})
    },
    performanceDescription: {
      type: Object,
      default: () => ({})
    },
    functionalDescription: {
      type: Object,
      default: () => ({})
    },
    advertisingDescription: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectorData: [
        {
          label: "Strictly necessary cookies (always on)",
          id: "necessary",
          value: true,
          disabled: true,
          description: this.necessaryDescription
        },
        {
          label: "Performance cookies",
          id: "performance",
          value: false,
          disabled: false,
          description: this.performanceDescription
        },
        {
          label: "Functional cookies",
          id: "functional",
          value: false,
          disabled: false,
          description: this.functionalDescription
        },
        {
          label: "Advertising cookies",
          id: "advertising",
          value: false,
          disabled: false,
          description: this.advertisingDescription
        }
      ]
    }
  },
  watch: {
    selectedCookies: {
      handler(selectedCookies) {
        if (selectedCookies.length) {
          this.selectorData.map(item => {
            let matchingData = selectedCookies.find(data => item.id === data.id)
            if (matchingData) {
              item.value = matchingData.value
            }
            return item
          })
        }
      },
      deep: true
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal")
    },
    submitPreferences(isAcceptAll) {
      let selectorValues = this.selectorData.map(data => {
        return {
          id: data.id,
          value: isAcceptAll ? true : data.value
        }
      })
      this.$emit("saveCookieChoice", selectorValues)
    }
  }
}
</script>

<style lang="scss">
.cookie-modal {
  .modal__header {
    margin: 2rem;
  }

  &__body {
    font-size: $size-7;

    p {
      margin-bottom: $spacing-5;
    }
  }
  &__title {
    margin-bottom: 0;
    font-size: $size-6;

    @include lg {
      font-size: $size-5;
    }
  }
  &__selector {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-6;
    padding-top: $spacing-2;

    label {
      font-weight: $weight-medium;
      font-size: 1.25rem;
    }
  }
  &__description {
    margin-bottom: $spacing-6;
  }
  &__btn-wrapper {
    margin-bottom: $spacing-6;

    .cookie-modal__button:first-child {
      margin-right: $spacing-3;
    }
  }
}
</style>
