import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export const untestedMutations = {
  SET_SELECTED_APPOINTMENT_SLOT(state, selectedAppointmentSlot) {
    state.selectedAppointmentSlot = selectedAppointmentSlot
  },
  RESET_CALENDER(state) {
    state.selectedAppointmentSlot = null
  },
}

export const untestedGetters = {
  selectedAppointmentDate: (state) => {
    if (!state.selectedAppointmentSlot?.date) return null
    return dayjs(state.selectedAppointmentSlot.date).format("D MMM YYYY")
  },
  selectedAppointmentTime(state) {
    const { startTime, endTime } = state.selectedAppointmentSlot?.time
    if (!startTime || !endTime) return null
    return `${startTime.substring(0, 5)} - ${endTime.substring(0, 5)}`
  },
  startDateTime(state) {
    const date = state.selectedAppointmentSlot?.date
    const startTime = state.selectedAppointmentSlot?.time?.startTime
    return dayjs(`${dayjs(date).format("YYYY-MM-DD")}${startTime}`).format(
      "YYYY-MM-DDTHH:mm:ss"
    )
  },
  endDateTime(state) {
    const date = state.selectedAppointmentSlot?.date
    const endTime = state.selectedAppointmentSlot?.time?.endTime
    return dayjs(`${dayjs(date).format("YYYY-MM-DD")}${endTime}`).format(
      "YYYY-MM-DDTHH:mm:ss"
    )
  },
}
