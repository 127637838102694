import Deferred from "../../helpers/Deferred"
import AuthenticationApiService from "./AuthenticationApiService"
import storeModule from "./storeModule"
import {
  isRefreshTokenAvailable,
  markRefreshTokenAsAvailable,
  markRefreshTokenAsUnavailable
} from "./refreshToken"
import { getDomain, setCookie, getCookie, deleteCookie } from "../Cookies"
import {
  extendSessionOnUserInteraction,
  disableSessionExtensionOnUserInteraction
} from "./extendSessionOnUserInteraction"

let store = null
let moduleName = null
let tokenRenewalProgress = null
let onSessionExpiredCallback = null
let sessionTimeout = null

/**
 * Creates a plugin instance and AuthenticationService methods into Vue prototype
 * Registers Vuex module maintaining the authentication state (e.g. including token details)
 *
 * @param {object} store - Vuex store instance
 * @param {object} storeModuleName - Custom name of the injecte dmodule
 */
const init = ({
  store: applicationStore,
  onSessionExpired,
  storeModuleName = "authentication"
}) => {
  if (!applicationStore) {
    throw new Error("Please provide Vuex store in `store` parameter")
  }
  applicationStore.registerModule("authentication", storeModule)
  store = applicationStore
  moduleName = storeModuleName
  onSessionExpiredCallback = onSessionExpired
}

const setTokenFromResponse = response => {
  store.commit(`${moduleName}/SET_AUTHENTICATION_DATA`, {
    token: response.data.token,
    expiryDate: response.data.exp,
    email: response.data.email
  })
  const renewTokenExpiryDate = new Date(response.data.refresh_token_exp)
  markRefreshTokenAsAvailable({ renewTokenExpiryDate })
}

const getAccessToken = () => store.state[moduleName].accessToken
const isAuthenticated = () => {
  const expiryDate = store.state[moduleName].accessTokenExpiryDate
  return expiryDate ? new Date(expiryDate).getTime() >= Date.now() : false
}

const logout = async (isJwtLogoutEnabled = true) => {
  deleteCookie("myaccount_remember-me_long_lived_refresh_token")
  clearTimeout(sessionTimeout)
  if (isJwtLogoutEnabled) {
    const token = await getAccessTokenAndRenewIfNeeded({
      ignoreRefreshCookie: true
    })
    await AuthenticationApiService.jwtLogout({ token })
  } else {
    await AuthenticationApiService.logout()
  }
  store.commit(`${moduleName}/CLEAR_AUTHENTICATION_DATA`)
  markRefreshTokenAsUnavailable()
  disableSessionExtensionOnUserInteraction()
}

const renewToken = async () => {
  const isSessionLongLived =
    getCookie("myaccount_remember-me_long_lived_refresh_token") === "true"
  const response = await AuthenticationApiService.renewToken({
    short_lived_refresh_token: String(!isSessionLongLived)
  })
  setTokenFromResponse(response)

  const sessionExpiryTime =
    response && response.data && new Date(response.data.refresh_token_exp)

  if (sessionExpiryTime) {
    logoutAt(sessionExpiryTime)
    extendSessionOnUserInteraction({
      renewSessionCallback: renewToken,
      sessionExpiryTime,
      isSessionLongLived
    })
  }
  return response
}

const login = async credentials => {
  const response = await AuthenticationApiService.login(credentials)
  const isSessionLongLived = credentials.short_lived_refresh_token === "false"
  const sessionExpiryTime =
    response && response.data && new Date(response.data.refresh_token_exp)

  setTokenFromResponse(response)
  setLoginCookie(sessionExpiryTime, isSessionLongLived)
  if (sessionExpiryTime) {
    logoutAt(sessionExpiryTime)
    extendSessionOnUserInteraction({
      renewSessionCallback: renewToken,
      sessionExpiryTime,
      isSessionLongLived
    })
  }
  return response
}

const logoutAt = refreshTokenExp => {
  const today = new Date()
  const logoutExpiry = refreshTokenExp.getTime() - today.getTime()
  clearTimeout(sessionTimeout)
  sessionTimeout = setTimeout(
    onSessionExpiredCallback,
    logoutExpiry < 0x7fffffff ? logoutExpiry : 0x7fffffff // setTimeout is limited by 32 bit integer
  )
}

const setLoginCookie = (sessionExpiryTime, isSessionLongLived) => {
  if (isSessionLongLived) {
    setCookie({
      name: "myaccount_remember-me_long_lived_refresh_token",
      value: "true",
      domain: getDomain(),
      expiryDate: sessionExpiryTime
    })
  }
}

const obtainAccessToken = async ({ ignoreRefreshCookie } = {}) => {
  if (tokenRenewalProgress) {
    await tokenRenewalProgress.promise
  }

  if (
    isAuthenticated() ||
    (!ignoreRefreshCookie && !isRefreshTokenAvailable())
  ) {
    return
  }

  tokenRenewalProgress = new Deferred()
  try {
    await renewToken()
  } finally {
    tokenRenewalProgress.resolve()
    tokenRenewalProgress = null
  }
}

const getAccessTokenAndRenewIfNeeded = async ({ ignoreRefreshCookie } = {}) => {
  try {
    await obtainAccessToken({ ignoreRefreshCookie })
  } catch (error) {
    markRefreshTokenAsUnavailable()
    return false
  }
  return getAccessToken()
}

const checkIfAuthenticatedAndFetchTokenIfNeeded = async () => {
  try {
    await obtainAccessToken()
  } catch (error) {
    markRefreshTokenAsUnavailable()
    return false
  }
  return isAuthenticated()
}

export default {
  init,
  login,
  setLoginCookie,
  logout,
  isAuthenticated,
  checkIfAuthenticatedAndFetchTokenIfNeeded,
  getAccessTokenAndRenewIfNeeded,
  getAccessToken,
  obtainAccessToken
}
