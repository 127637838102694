import { checkCookieIsAllowed } from "./Cookies"
const cookieCategoryCallbacks = {}

export const runCallbacksForCookieCategory = category => {
  const callbacks = cookieCategoryCallbacks[category]
  if (callbacks) {
    callbacks.forEach(callback => callback())
    delete cookieCategoryCallbacks[category]
  }
}

export const setCookiePreference = cookiePreferences => {
  const acceptedCategories = cookiePreferences
    .filter(type => !!type.value)
    .map(type => type.id)

  acceptedCategories.forEach(category => {
    runCallbacksForCookieCategory(category)
  })
}

export const runOnceCookieIsAllowed = (category, callback) => {
  if (checkCookieIsAllowed(category)) {
    callback()
  } else {
    if (!cookieCategoryCallbacks[category]) {
      cookieCategoryCallbacks[category] = []
    }
    cookieCategoryCallbacks[category].push(callback)
  }
}
