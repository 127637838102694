<template>
  <transition :name="transition">
    <div
      v-if="isActive"
      class="loading-overlay"
      :class="{
        'loading-overlay--opaque': !transparent
      }"
    >
      <Spinner
        role="progressbar"
        aria-valuetext="Loading"
        aria-label="Please wait"
        aria-busy="true"
        aria-live="assertive"
      />
    </div>
  </transition>
</template>

<script>
import Spinner from "./Spinner"
import preventScrollMixin from "./../mixins/preventScrollMixin"

export default {
  name: "LoadingOverlay",
  components: {
    Spinner
  },
  mixins: [preventScrollMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      validator: val => ["fade-out", "fade-in-out"].includes(val),
      default: "fade-in-out"
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isActive(value) {
      this.preventBodyScroll(value)
    },
  }
}
</script>

<style scoped lang="scss">
.loading-overlay {
  @include overlay;
  z-index: zindex(overlay);
  will-change: opacity;
}

.fade-in-out-enter-active,
.fade-in-out-leave-active,
.fade-out-leave-active {
  transition: opacity 0.2s ease-in 0s;
}

.fade-in-out-enter,
.fade-in-out-leave-to,
.fade-out-leave-to {
  opacity: 0;
}
</style>
