<template>
  <div :class="groupClasses">
    <div class="segmented-group__form" :class="formClasses">
      <SegmentedButton
        v-for="(option, key) in options"
        :key="key"
        v-bind="option"
        :name="name"
        :has-error="hasError"
        :small="small"
        :wrap="wrap"
        :variant="variant"
      />
      <slot />
    </div>
    <div v-if="errorMessage" class="segmented-group__invalid-feedback">
      <i class="icon icon-alert segmented-group__invalid-feedback--icon"></i>
      <div :id="feedbackId" role="alert">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import SegmentedButton from "./SegmentedButton.vue"

export default {
  components: {
    SegmentedButton
  },
  provide() {
    return {
      segmentedGroup: this
    }
  },
  model: {
    prop: "selectedValue",
    event: "checked"
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    selectedValue: {
      type: [String, Object, Boolean],
      default: ""
    },
    options: {
      type: Array,
      default: null
    },
    errorMessage: {
      type: String,
      default: ""
    },
    hasError: {
      type: Boolean,
      default: false
    },
    noGap: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ""
    }
  },
  computed: {
    feedbackId() {
      return `${this.name}-feedback`
    },
    formClasses() {
      return {
        "no-gap": this.noGap,
        "no-gap__error": this.noGap && this.hasError,
        "segmented-group__form--wrap": this.wrap
      }
    },
    groupClasses() {
      return { "segmented-group__error": this.hasError }
    }
  },
  methods: {
    checked(checked) {
      this.$emit("checked", checked)
    }
  }
}
</script>

<style scoped lang="scss">
.segmented-group {
  &__form {
    width: 100%;
    display: flex;
    gap: $space-2;
    font-size: $size-7;

    @include md {
      gap: $space-4;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &__invalid-feedback {
    display: flex;
    color: $danger-02;
    font-size: $size-7;
    align-items: center;
    margin-top: $space-2;

    &--icon {
      margin-right: $space-2;
      font-weight: $weight-bold;
    }
  }

  &__error {
    ::v-deep .segmented-button {
      .segmented-button__label {
        border: 2px solid $danger-02;
      }
    }
  }
}

.no-gap {
  gap: $space-0;
  @include md {
    gap: $space-0;
  }
  ::v-deep .segmented-button {
    .segmented-button__label {
      border-radius: 0px;
      border-right: 0px;
    }
    &:first-of-type {
      .segmented-button__label {
        border-radius: 6px 0px 0px 6px;
        border-right: 0px;
      }
    }
    &:last-of-type {
      .segmented-button__label {
        border-radius: 0px 6px 6px 0px;
        border-right: 1px solid $black;
      }
    }
  }
  &__error {
    ::v-deep .segmented-button {
      .segmented-button__label {
        border: 2px solid $danger-02;
        border-right: 1px;
      }
      &:first-of-type {
        .segmented-button__label {
          border: 2px solid $danger-02;
          border-right: 1px;
        }
      }
      &:last-of-type {
        .segmented-button__label {
          border: 2px solid $danger-02;
        }
      }
    }
  }
}
</style>
