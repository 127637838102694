export default {
  ImageGrid: () =>
    import("@soenergy/frontend-library/src/components/ImageGrid"),
  UspBlock: () => import("@soenergy/frontend-library/src/components/UspBlock"),
  IllustratedSteps: () =>
    import("@soenergy/frontend-library/src/components/IllustratedSteps"),
  AccordionBlock: () =>
    import("@soenergy/frontend-library/src/components/AccordionBlock"),
  TwoColumnBlock: () =>
    import("@soenergy/frontend-library/src/components/TwoColumnBlock"),
  TwoColumnHero: () =>
    import("@soenergy/frontend-library/src/components/TwoColumnHero"),
  Carousel: () => import("@soenergy/frontend-library/src/components/Carousel"),
  UspBlockDetailed: () =>
    import("@soenergy/frontend-library/src/components/UspBlockDetailed"),
}
