import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs"

import {
  canInitializeDatadog,
  commonDatadogParams,
  setRequestMetadata
} from "../helpers/datadogHelper"

export const initDatadogPlugin = () => {
  return {
    install(app) {
      if (canInitializeDatadog()) {
        datadogRum.init({
          applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
          ...commonDatadogParams,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "mask-user-input",
          allowedTracingUrls: [
            {
              match: /https:\/\/.*\.staging.soenergy\.co\/.*/,
              propagatorTypes: ["tracecontext"]
            },
            {
              match: /https:\/\/.*\.pre-production\.soenergy\.co\/.*/,
              propagatorTypes: ["tracecontext"]
            },
            {
              match: /https:\/\/.*\.preview\.soenergy\.co\/.*/,
              propagatorTypes: ["tracecontext"]
            },
            {
              match: /https:\/\/.*\.so\.energy\/.*/,
              propagatorTypes: ["tracecontext"]
            }
          ],
          beforeSend: (event, context) => {
            const error = context?.error
            if (error?.isAxiosError) {
              setRequestMetadata(event, error)
            }
            return true
          }
        })

        const originalErrorHandler = app.config.errorHandler
        app.config.errorHandler = function(error, vm, info) {
          if (typeof originalErrorHandler === "function") {
            originalErrorHandler.call(this, error, vm, info)
          }

          datadogRum.addError(error, {
            error: { kind: error.name, stack: error.stack },
            context: { info }
          })
        }
        datadogLogs.init({
          ...commonDatadogParams,
          forwardErrorsToLogs: false
        })
      }
    }
  }
}

export const reportErrorToDataDog = (error, metadata) => {
  if (canInitializeDatadog()) {
    datadogRum.addError(error, metadata)
  }
}

export const recordEventToDatadog = (eventName, metadata) => {
  if (canInitializeDatadog()) {
    datadogRum.addAction(eventName, metadata)
  }
}

export const logInfoToDataDog = (log, metadata) => {
  if (canInitializeDatadog()) {
    datadogLogs.logger.log(log, metadata)
  }
}

export const identifyUser = user => {
  if (user) {
    datadogRum.setUser(user)
  }
}

export const clearUser = () => {
  datadogRum.clearUser()
}
