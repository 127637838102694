<template>
  <label class="toggle-button" :for="id" :aria-label="ariaLabel">
    <div class="toggle-button__input-wrapper">
      <input
        :id="id"
        v-track="{ tracking, ariaLabel }"
        type="checkbox"
        :value="value"
        :checked="value"
        :disabled="disabled || null"
        @change="updateInput"
        @click="removeFocus($event)"
      />
      <span
        :for="id"
        class="toggle-button__label"
        :class="{ 'toggle-button__label--rebranded': newBranding }"
      >
        <span
          ref="onLabel"
          class="toggle-button__switch-label toggle-button__switch-label--on"
        >
          {{ rightLabel ? "" : onLabel }}
        </span>
        <span
          ref="offLabel"
          class="toggle-button__switch-label toggle-button__switch-label--off"
        >
          {{ rightLabel ? "" : offLabel }}
        </span>
        <span class="toggle-button__slider"></span>
      </span>
    </div>
    <span ref="rightLabel" class="toggle-button__right-label" :for="id">
      {{ rightLabel }}
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    offLabel: {
      type: String,
      default: "Off"
    },
    onLabel: {
      type: String,
      default: "On"
    },
    rightLabel: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tracking: {
      type: [Object, Array],
      default: null
    }
  },
  data: () => ({
    newBranding: process.env.VUE_APP_NEW_BRANDING === "true"
  }),
  computed: {
    ariaLabel() {
      return this.rightLabel ?? this.label
    }
  },
  methods: {
    updateInput(event) {
      this.$emit("change", event.target.checked)
    },
    removeFocus(event) {
      if (event.clientX || event.clientY) {
        event.target.blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-button {
  display: flex;
  align-items: center;
  gap: $space-3;

  &__input-wrapper {
    position: relative;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    width: 59px;
    min-width: 59px;
    height: 27px;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
  }

  &__label {
    display: flex;
    border-radius: 16px;
    background-color: $night-light-2;
    border: 4px solid $night-light-2;
    user-select: none;
    position: relative;
    height: 27px;
    width: 100%;
    cursor: pointer;
  }

  & input:checked + .toggle-button__label {
    background-color: $earth-dark;
    border: 4px solid $earth-dark;

    .toggle-button__slider {
      right: 0px;
    }
    .toggle-button__switch-label--on {
      opacity: 100;
    }
    .toggle-button__switch-label--off {
      opacity: 0;
    }
  }

  & input:checked + .toggle-button__label--rebranded {
    background-color: $green-900;
    border: 4px solid $green-900;
  }
  & input:disabled + .toggle-button__label {
    background-color: $disabled;
    border: 4px solid $disabled;
    cursor: not-allowed;
  }

  & input:focus + .toggle-button__label {
    box-shadow: 0 0 0 4px $focus;
  }

  &__slider {
    position: absolute;
    width: 19px;
    height: 19px;
    background: $day;
    display: block;
    border-radius: 9px;
    right: calc(50% + 7px);
    transition: right 0.3s;
  }

  &__right-label {
    font-size: $font-size-2;
  }

  &__switch-label {
    color: $day;
    letter-spacing: 0px;
    display: inline-block;
    flex: 0 0 50%;
    text-align: center;
    line-height: 19px;
    font-size: $font-size-2;
    font-weight: $weight-medium;
    transition: opacity 0.3s;

    &--on {
      opacity: 0;
      text-align: right;
    }
    &--off {
      opacity: 100;
      text-align: left;
    }
  }
}
</style>
