<template>
  <AppButton
    v-bind="$attrs"
    :to="to"
    :href="
      resolveLink({
        link: route,
        linkType,
        linkData,
        targetProject,
        queryParameters,
        includeBaseUrl: isLinkedToMarketingSite
      })
    "
    :target="browsingContext({ link: route, linkType })"
    v-on="$listeners"
  >
    <slot />
  </AppButton>
</template>

<script>
import projectUrlResolverMixin from "../mixins/projectUrlResolverMixin"
import AppButton from "./AppButton"

export default {
  emits: ["click"],
  components: {
    AppButton
  },
  mixins: [projectUrlResolverMixin],
  inheritAttrs: false,
  props: {
    link: {
      type: Array,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    targetProject: {
      type: String,
      default: null
    },
    linkData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLinkedToMarketingSite:
        process.env.VUE_APP_LINKED_TO_MARKETING_SITE === "true"
    }
  },
  computed: {
    route() {
      return this.link?.[0]?.link ?? this.to
    },
    linkType() {
      return this.link?.[0]?.component
    },
    queryParameters() {
      return this.link?.[0]?.queryParameters ?? []
    }
  }
}
</script>
