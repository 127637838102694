<template>
  <div
    class="progress-container"
    :aria-valuenow="currentStepNumber"
    :aria-valuemin="0"
    :aria-valuemax="steps.length"
    :aria-label="ariaLabel"
    role="progressbar"
  >
    <ol class="progress-bar">
      <li
        v-for="(stage, index) in steps"
        :key="index"
        :class="[
          'progress-bar__item',
          {
            'progress-bar__item--past': index <= currentStepNumber
          },
          {
            'progress-bar__item--present': index === currentStepNumber
          }
        ]"
      ></li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStepNumber: {
      type: Number,
      required: true
    },
    ariaLabel: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.progress-bar {
  display: flex;
  position: fixed;
  top: auto;
  left: 0;
  width: 100%;
  z-index: zindex(sticky);
  height: $space-2;
  width: 100%;
  background-color: $blue-100;
  list-style-type: none;

  &__item {
    position: relative;
    flex: 1;
    height: 100%;
    border: 0;
    background-color: transparent;

    &--past {
      background-color: $water;
    }

    &--present {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      &:last-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
