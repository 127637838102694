import findLinkInAncestorElements from "../helpers/findLinkInAncestorElements"

export default {
  methods: {
    setupRelativeLinkListener() {
      this.detachRelativeLinkListeners()
      this.attachRelativeLinkListeners()
    },
    attachRelativeLinkListeners() {
      const links = this.getRelativeLinkElements()
      if (!links) return

      const goToLinkDestination = event => {
        event.preventDefault()
        const element = findLinkInAncestorElements(event.target)
        const relativePath = element.pathname
        const resolvedPath = this.$router.resolve(relativePath)

        const routeExistsInProject =
          (resolvedPath &&
            resolvedPath.resolved &&
            resolvedPath.resolved.name) ||
          (resolvedPath && resolvedPath.name)

        if (routeExistsInProject) {
          this.$router.push(relativePath)
        } else {
          window.open(element.href, element.target)
        }
      }

      this.linkClickEventDetachFunctions = links.map(link => {
        link.addEventListener("click", goToLinkDestination)
        return () => link.removeEventListener("click", goToLinkDestination)
      })
    },
    detachRelativeLinkListeners() {
      if (this.linkClickEventDetachFunctions) {
        this.linkClickEventDetachFunctions.forEach(detachListener =>
          detachListener()
        )
        this.linkClickEventDetachFunctions = null
      }
    },
    getRelativeLinkElements() {
      if (!this.$el) return
      return Array.from(
        this.$el.querySelectorAll(`a[linktype="story"]:not([target="_blank"])`)
      )
    }
  }
}
