<template>
  <div class="spinner">
    <div ref="soeAnimation" class="spinner__animation"></div>
    <p v-if="spinnerText" class="spinner__loading">
      {{ spinnerText }}
    </p>
  </div>
</template>

<script>
import LottieWeb from "../services/LottieWeb.js"
import loadingIcon from "../assets/loadingSpinner.json"

export default {
  props: {
    spinnerText: {
      default: "",
      type: String
    }
  },
  async mounted() {
    const lottie = await LottieWeb.getLottieLight()
    this.anim = lottie.loadAnimation({
      container: this.$refs.soeAnimation,
      animType: "svg",
      loop: true,
      autoplay: false,
      animationData: loadingIcon
    })
    this.anim.setSubframe(false)
    this.anim.play()
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  &__animation {
    height: 160px;
    width: 160px;
    margin: $spacing-6 auto 0;
  }

  &__loading {
    text-align: center;
    color: $night-light;
    font-size: $size-7;
    font-weight: $weight-medium;
  }
}
</style>
