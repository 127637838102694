<template>
  <component :is="tag">
    <template v-if="wrapperElementTag">
      <Component
        :is="wrapperElementTag"
        v-for="({ _uid, component, ...props }, index) in visibleBlocks"
        :key="_uid"
        v-bind="wrapperElementAttrs[index]"
      >
        <Component
          :is="component"
          v-editable="props"
          v-bind="props"
          :user-signed-in="userSignedIn"
          v-on="$listeners"
        />
      </Component>
    </template>
    <template v-else>
      <template v-for="{ _uid, component, ...props } in visibleBlocks">
        <LazyHydrate
          v-if="!isVue3 && props.lazyHydrate"
          :key="_uid + '-lazy'"
          :when-visible="
            props.lazyHydrateOffset
              ? { rootMargin: props.lazyHydrateOffset }
              : true
          "
        >
          <Component
            :is="component"
            :key="_uid"
            v-editable="props"
            v-bind="props"
            :user-signed-in="userSignedIn"
            v-on="$listeners"
          />
        </LazyHydrate>
        <Component
          :is="component"
          v-else
          :key="_uid"
          v-editable="props"
          v-bind="props"
          :user-signed-in="userSignedIn"
          v-on="$listeners"
        />
      </template>
    </template>
  </component>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration"
import components from "@soenergy-aliases/project-content-blocks"
import isVue3 from "../helpers/isVue3"

export default {
  components: {
    LazyHydrate,
    ...components
  },
  inheritAttrs: false,
  props: {
    blocks: {
      required: true,
      type: Array
    },
    tag: {
      type: String,
      default: "div"
    },
    wrapperElementTag: {
      type: String,
      default: null
    },
    wrapperElementAttrs: {
      type: Array,
      default: () => []
    },
    lazyHydrate: {
      type: Boolean,
      default: true
    },
    userSignedIn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVue3() {
      return isVue3()
    },
    existingBlocks() {
      return this.blocks.filter(block => block.component in components)
    },
    visibleBlocks() {
      return this.existingBlocks.map((block, index) => {
        const shouldLazyHydrate =
          block.lazyHydrate || (this.lazyHydrate && index > 1)

        return {
          ...block,
          lazyLoad: block.lazyLoad || null,
          lazyHydrate: shouldLazyHydrate || null,
          deferMediaLoading: block.deferMediaLoading || shouldLazyHydrate
        }
      })
    }
  },
  updated() {
    this.$emit("updated")
  }
}
</script>
