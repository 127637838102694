<template>
  <AppSection
    class="general-hero section--medium-mobile"
    :class="{
      'no-responsive-background': !responsiveBackgroundImage,
      'general-hero--small': isSmall
    }"
    :font-color="fontColor"
    :background-color="backgroundColor"
    :background-image="backgroundImage"
    :is-background-centred="isBackgroundCentred"
    :responsive-background-image="
      responsiveBackgroundImage ? responsiveBackgroundImage[0] : null
    "
  >
    <div class="container">
      <h1 class="general-hero__heading" :class="headingClasses">
        {{ heading }}
      </h1>
      <p
        v-if="subheading"
        class="general-hero__subheading"
        :class="subheadingClasses"
      >
        {{ subheading }}
      </p>
    </div>
  </AppSection>
</template>

<script>
import AppSection from "./AppSection"
export default {
  components: {
    AppSection
  },
  props: {
    heading: {
      type: String,
      default: ""
    },
    isBackgroundCentred: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isHeadingLowerCase: {
      type: Boolean,
      default: false
    },
    subheading: {
      type: String,
      default: ""
    },
    fontColor: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    backgroundImage: {
      type: String,
      default: ""
    },
    responsiveBackgroundImage: {
      type: Array,
      default: null
    },
    headingColumns: {
      type: String,
      default: ""
    },
    subheadingColumns: {
      type: String,
      default: ""
    }
  },
  computed: {
    headingClasses() {
      const classes = []
      if (this.isHeadingLowerCase) {
        classes.push("general-hero__heading-lowercase")
      }
      if (this.headingColumns) {
        classes.push("column")
        classes.push(this.headingColumns)
      } else {
        classes.push("condensed-normal")
      }
      return classes
    },
    subheadingClasses() {
      const classes = []
      if (this.subheadingColumns) {
        classes.push("column")
        classes.push(this.subheadingColumns)
      } else {
        classes.push("condensed-normal")
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.general-hero {
  background-color: $sun;
  text-align: center;
  background-size: cover;

  @include md {
    padding: $spacing-10 $spacing-4;
  }
  @include lg {
    padding: $spacing-11 $spacing-4;
  }
  &__heading-lowercase {
    text-transform: unset;
  }
  &__heading,
  &__subheading {
    padding: 0;
    margin: auto;
  }
  &__subheading {
    font-size: $size-6;
    margin-top: $size-5;
  }

  &--small {
    padding: $space-12;
    @include md {
      padding: $space-14;
    }
  }
}

.no-responsive-background {
  @media (max-width: #{$screen-md-min}) {
    background-image: unset !important;
  }
}
</style>
